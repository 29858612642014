import { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  InputAdornment,
  TextField,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useDebouncedEffect } from "dashboard/useDebouncedEffect";
import TimeSlider from "components/TimeSlider";
import { HubScenarioControls } from "types/hub-scenario-parameters";

type AdvancedSettingsDialogProps = {
  openAdvancedSettingsDialog: boolean;
  annualAveragePerCharger: number;
  toggleAdvancedSettingsModal: () => void;
  setAnnualAveragePerCharger: (value: number) => void;
  scenarioControls: HubScenarioControls;
  setScenarioControls: (value: HubScenarioControls) => void;
};
export default function AdvancedSettingsDialog({
  openAdvancedSettingsDialog,
  annualAveragePerCharger,
  toggleAdvancedSettingsModal,
  setAnnualAveragePerCharger,
  scenarioControls,
  setScenarioControls,
}: AdvancedSettingsDialogProps) {
  const [annualAverage, setAnnualAverage] = useState<number>(
    annualAveragePerCharger
  );

  useDebouncedEffect(
    () => setAnnualAveragePerCharger(annualAverage),
    [annualAverage],
    500
  );

  return (
    <Dialog
      open={openAdvancedSettingsDialog}
      onClose={() => toggleAdvancedSettingsModal()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={false}
    >
      <IconButton
        aria-label="close"
        onClick={() => toggleAdvancedSettingsModal()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <GridCloseIcon />
      </IconButton>
      <DialogContent sx={{ padding: 5, paddingBottom: 3 }}>
        <Box>
          <Typography
            sx={{ flex: "1 1 100%", marginBottom: 3 }}
            variant="h2"
            id="tableTitle"
            component="div"
          >
            Advanced Settings
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: 3,
            }}
          >
            <Typography
              sx={{ marginRight: 3 }}
              variant="h3"
              id="tableTitle"
              component="span"
            >
              Average Annual Maintenance Cost Per Charger
            </Typography>
            <TextField
              sx={{ m: 1, width: "130px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              type="number"
              value={annualAverage}
              inputProps={{ min: 0 }}
              onChange={(e) => setAnnualAverage(Number(e.target.value))}
            />
          </Box>
          <Box>
            <Typography
              sx={{ marginRight: 3 }}
              variant="h3"
              id="tableTitle"
              component="span"
            >
              Truck Operating Hours
            </Typography>
            <TimeSlider
              duplicateMidnight
              value={scenarioControls.truckOperatingHours}
              onChange={(e, value) =>
                setScenarioControls({
                  ...scenarioControls,
                  truckOperatingHours: value as number[],
                })
              }
              valueLabelDisplay="auto"
              step={1}
            />
          </Box>
          <Box>
            <Typography
              sx={{ marginRight: 3 }}
              variant="h3"
              id="tableTitle"
              component="span"
            >
              Trailer Operating Hours
            </Typography>
            <TimeSlider
              duplicateMidnight
              value={scenarioControls.trailerOperatingHours}
              onChange={(e, value) =>
                setScenarioControls({
                  ...scenarioControls,
                  trailerOperatingHours: value as number[],
                })
              }
              valueLabelDisplay="auto"
              step={1}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
