import { FeatureCollection } from "geojson";
import { getDepotCategoryName } from "utils/depot-category";
import { downloadFile } from "utils/file";

export type Depots = {
  geometry: {
    type: string;
    coordinates: number[];
  };
  properties: {
    id: number;
    owner: string;
    business_name: string;
    subnetwork: string;
    address: string;
    situs_num: number;
    street1: string;
    street2: string;
    city: string;
    state: string;
    zipcode: string;
    google_places_list: string;
    datasource: string;
  };
};

const mapDataSourceToCategory = (datasource: string) => {
  const lowerDatasource = datasource.toLowerCase();
  return getDepotCategoryName(lowerDatasource);
};

export type downloadDepotsProps = {
  type: string;
  features: Depots[];
};

const checkForNull = (data: any) => {
  if (data == null) {
    return "";
  }

  if (typeof data === "string") {
    data = data.replace(/,/g, " "); // Replace commas with spaces
    data = data.replace(/\s+/g, " "); // Replace multiple white spaces with a single space
  }

  return data;
};

const convertToCSV = (data: any): string => {
  const {
    properties: {
      id,
      owner,
      business_name,
      subnetwork,
      address,
      street1,
      street2,
      city,
      state,
      zipcode,
      google_places_list,
      datasource,
    },
    geometry: { coordinates },
  } = data;

  const location = [...coordinates].reverse().toString();

  return `ET-${checkForNull(id)},${checkForNull(owner)},${checkForNull(
    business_name
  )},${checkForNull(subnetwork)},${checkForNull(address)},${checkForNull(
    street1
  )},${checkForNull(street2)},${checkForNull(city)},${checkForNull(
    state
  )},${checkForNull(zipcode)},${checkForNull(location)},${checkForNull(
    google_places_list?.split(",")?.[0]
  )},${checkForNull(mapDataSourceToCategory(datasource))}\n`;
};

export const downloadDepots = async (
  data: FeatureCollection | undefined
): Promise<boolean> => {
  try {
    const header =
      "Depot ID,Owner,Business Name,Feeder Line,Address,Street1,Street2,City,State,Zipcode,Location,Google Place ID,Depot Category\n";
    const csvData = header + (data && data.features.map(convertToCSV).join(""));
    downloadFile(csvData, "depots.csv");
    return true;
  } catch (error) {
    console.error("Error during download:", error);
    return false;
  }
};
