import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { range } from "utils/array";
import HelpTooltip from "components/HelpTooltip";
import ChargerAccessControl from "dashboard/controls/ChargerAccessControl";
import ChargerLevelControl from "dashboard/controls/ChargerLevelControl";
import YearControl from "../controls/YearControl";
import AccessControl from "../AccessControl";
import UtilizationControl from "../controls/UtilizationControl";

type ChargerCoverageControlsDialogProps = {
  open: boolean;
  onClose: () => void;
  props: any;
  utilization: number;
  selectedChargerLevels: any;
  includePrivateChargers: any;
  includeExclusiveNetworks: any;
  handleUtilizationChange: any;
  handleSelectedChargerLevelsChange: any;
  handleIncludePrivateChargersChange: any;
  handleIncludeExclusiveNetworksChange: any;
};

export default function ChargerCoverageControlsDialog({
  open,
  onClose,
  props,
  utilization,
  selectedChargerLevels,
  includePrivateChargers,
  includeExclusiveNetworks,
  handleUtilizationChange,
  handleSelectedChargerLevelsChange,
  handleIncludePrivateChargersChange,
  handleIncludeExclusiveNetworksChange,
}: ChargerCoverageControlsDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        <GridCloseIcon />
      </IconButton>
      <DialogContent sx={{ padding: 5, paddingBottom: 3 }}>
        <Box className={"charger-controls"}>
          <Stack spacing={1}>
            <HelpTooltip
              title={
                "Select which chargers contribute to the charger coverage calculation. Utilization is assumed to be 10% of 24 hours."
              }
            >
              <Typography variant="h3">Charger Coverage Controls</Typography>
            </HelpTooltip>
            <YearControl
              value={props.year}
              years={range(2020, 2051)}
              onChange={props.onYearChange}
            />
            <ChargerLevelControl
              selectedChargerLevels={selectedChargerLevels}
              onChange={handleSelectedChargerLevelsChange}
            />
            <ChargerAccessControl
              includePrivateChargers={includePrivateChargers}
              onIncludePrivateChargersChange={
                handleIncludePrivateChargersChange
              }
              includeExclusiveNetworks={includeExclusiveNetworks}
              onIncludeExclusiveNetworksChange={
                handleIncludeExclusiveNetworksChange
              }
            />
            <AccessControl permission="read:coverage_utilization_control">
              <UtilizationControl
                utilization={utilization}
                onChange={handleUtilizationChange}
              />
            </AccessControl>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
