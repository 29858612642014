import { useEffect, useState } from "react";
import { OrganizationPreference } from "types/organization";
import { useAccessToken } from "utils/get-access-token";

type UseOrganizationPreferenceData = {
  organizationPreferenceData: OrganizationPreference | undefined;
  loadingOrganizationPreferenceData: boolean;
  errorLoadingFOrganizationPreferenceData: Error | null;
  refetch: () => void;
};
export function useOrganizationPreferenceData(): UseOrganizationPreferenceData {
  const { getToken } = useAccessToken();
  const [organizationPreference, setOrganizationPreference] =
    useState<OrganizationPreference>();
  const [
    loadingOrganizationPreferenceData,
    setLoadingOrganizationPreferenceData,
  ] = useState<boolean>(false);
  const [
    errorLoadingFOrganizationPreferenceData,
    setErrorLoadingFOrganizationPreferenceData,
  ] = useState<Error | null>(null);

  const fetchData = async () => {
    const apiToken = await getToken();
    setLoadingOrganizationPreferenceData(true);
    setErrorLoadingFOrganizationPreferenceData(null);

    try {
      const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/organization/preferences`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const result: OrganizationPreference = await response.json();
        setOrganizationPreference(result);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorLoadingFOrganizationPreferenceData(error);
      }
    }

    setLoadingOrganizationPreferenceData(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const refetch = () => {
    fetchData();
  };

  return {
    organizationPreferenceData: organizationPreference,
    loadingOrganizationPreferenceData,
    errorLoadingFOrganizationPreferenceData,
    refetch,
  };
}
