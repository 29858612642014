import { useEffect, useState } from "react";
import { useAccessToken } from "utils/get-access-token";

export function useChargerInstallationCosts(chargeRateKw: number | null) {
  const [installationCost, setInstallationCost] = useState<number | null>(null);
  const [loadingInstallationCost, setLoadingInstallationCost] = useState(false);
  const [errorLoadingInstallationCost, setErrorLoadingInstallationCost] =
    useState<Error | null>(null);

  const { getToken } = useAccessToken();

  const fetchData = async (chargeRateKw: number) => {
    setLoadingInstallationCost(true);
    setErrorLoadingInstallationCost(null);

    try {
      const apiToken = await getToken();

      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/chargers/installation-costs?chargeRateKw=${chargeRateKw}`,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );

      if (response.ok) {
        const result: number = await response.json();
        setInstallationCost(result);
      } else {
        setInstallationCost(null);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorLoadingInstallationCost(error);
      }
    }

    setLoadingInstallationCost(false);
  };

  useEffect(() => {
    if (chargeRateKw) {
      fetchData(chargeRateKw);
    }
    // eslint-disable-next-line
  }, [chargeRateKw]);

  return {
    calculatedInstallationCost: installationCost,
    loadingInstallationCost,
    errorLoadingInstallationCost,
  };
}
