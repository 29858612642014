import {
  Divider,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ListItemText,
  List,
  ListItem,
  Stack,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import { downloadUtilityRate } from "dashboard/terminal/download";
import { UtilityRateStructure } from "types/terminal-scenario";
import { generate24HourLabels } from "utils/time";

export type UtilityInformationProp = {
  utilityInfo: UtilityRateStructure | undefined;
};
const UtilityInformation = ({ utilityInfo }: UtilityInformationProp) => {
  return (
    <Box
      sx={{
        border: 0.5,
        borderColor: "silver",
        padding: "16px",
        borderRadius: 5,
        marginBottom: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="controlTitle" sx={{ fontWeight: 600 }}>
          Utility Rate Components
        </Typography>
      </Box>
      <Divider sx={{ marginTop: 2 }} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                key={0}
                sx={{
                  paddingX: "4px",
                  fontSize: "10px",
                }}
              >
                {"Utility Rate"}
              </TableCell>
              {generate24HourLabels().map((column, index) => (
                <TableCell
                  key={index + 1}
                  sx={{
                    paddingX: "2px",
                    fontSize: "10px",
                    minWidth: "40px",
                  }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {utilityInfo?.generationEnergyChargeKwh &&
              utilityInfo?.generationEnergyChargeKwh.length > 0 && (
                <TableRow>
                  <TableCell
                    sx={{
                      paddingX: "4px",
                      paddingY: "10px",
                      fontSize: "10px",
                    }}
                    key={0}
                    align="left"
                  >
                    {"Energy Generation ($/kWh)"}
                  </TableCell>
                  {utilityInfo.generationEnergyChargeKwh.map((value, index) => (
                    <TableCell
                      sx={{
                        paddingX: "2px",
                        fontSize: "10px",
                        paddingY: "10px",
                      }}
                      key={index + 1}
                    >
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              )}
            {utilityInfo?.transmissionEnergyChargeKwh &&
              utilityInfo?.transmissionEnergyChargeKwh.length > 0 && (
                <TableRow>
                  <TableCell
                    sx={{
                      paddingX: "4px",
                      paddingY: "10px",
                      fontSize: "10px",
                    }}
                    key={0}
                    align="left"
                  >
                    {"Energy Transmission ($/kWh)"}
                  </TableCell>
                  {utilityInfo.transmissionEnergyChargeKwh.map(
                    (value, index) => (
                      <TableCell
                        sx={{
                          paddingX: "2px",
                          fontSize: "10px",
                          paddingY: "10px",
                        }}
                        key={index + 1}
                      >
                        {value}
                      </TableCell>
                    )
                  )}
                </TableRow>
              )}
            {utilityInfo?.distributionEnergyChargeKwh &&
              utilityInfo?.distributionEnergyChargeKwh.length > 0 && (
                <TableRow>
                  <TableCell
                    sx={{
                      paddingX: "4px",
                      paddingY: "10px",
                      fontSize: "10px",
                    }}
                    key={0}
                    align="left"
                  >
                    {"Energy Distribution ($/kWh)"}
                  </TableCell>
                  {utilityInfo.distributionEnergyChargeKwh.map(
                    (value, index) => (
                      <TableCell
                        sx={{
                          paddingX: "2px",
                          fontSize: "10px",
                          paddingY: "10px",
                        }}
                        key={index + 1}
                      >
                        {value}
                      </TableCell>
                    )
                  )}
                </TableRow>
              )}
            {utilityInfo?.othersEnergyChargeKwh &&
              utilityInfo?.othersEnergyChargeKwh.length > 0 && (
                <TableRow>
                  <TableCell
                    sx={{
                      paddingX: "2px",
                      fontSize: "10px",
                      paddingY: "10px",
                    }}
                    key={0}
                    align="left"
                  >
                    {"Energy Others ($/kWh)"}
                  </TableCell>
                  {utilityInfo?.othersEnergyChargeKwh.map((value, index) => (
                    <TableCell
                      sx={{
                        paddingX: "2px",
                        paddingY: "10px",
                        fontSize: "10px",
                      }}
                      key={index + 1}
                    >
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              )}
            {utilityInfo?.generationDemandChargeKw &&
              utilityInfo?.generationDemandChargeKw.length > 0 && (
                <TableRow>
                  <TableCell
                    sx={{
                      paddingX: "2px",
                      paddingY: "10px",
                      fontSize: "10px",
                    }}
                    key={0}
                    align="left"
                  >
                    {"Demand Generation ($/kW)"}
                  </TableCell>
                  {utilityInfo?.generationDemandChargeKw.map((value, index) => (
                    <TableCell
                      sx={{
                        paddingX: "2px",
                        fontSize: "10px",
                        paddingY: "10px",
                      }}
                      key={index + 1}
                    >
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              )}
            {utilityInfo?.transmissionDemandChargeKw &&
              utilityInfo?.transmissionDemandChargeKw.length > 0 && (
                <TableRow>
                  <TableCell
                    sx={{ paddingX: "2px", fontSize: "10px" }}
                    key={0}
                    align="left"
                  >
                    {"Demand Transmission ($/kW)"}
                  </TableCell>
                  {utilityInfo.transmissionDemandChargeKw.map(
                    (value, index) => (
                      <TableCell
                        sx={{
                          paddingX: "2px",
                          fontSize: "10px",
                          paddingY: "10px",
                        }}
                        key={index + 1}
                      >
                        {value}
                      </TableCell>
                    )
                  )}
                </TableRow>
              )}
            {utilityInfo?.distributionDemandChargeKw &&
              utilityInfo?.distributionDemandChargeKw.length > 0 && (
                <TableRow>
                  <TableCell
                    sx={{
                      paddingX: "2px",
                      fontSize: "10px",
                      paddingY: "10px",
                    }}
                    key={0}
                    align="left"
                  >
                    {"Demand Distribution ($/kW)"}
                  </TableCell>
                  {utilityInfo?.distributionDemandChargeKw.map(
                    (value, index) => (
                      <TableCell
                        sx={{
                          paddingX: "2px",
                          paddingY: "10px",
                          fontSize: "10px",
                        }}
                        key={index + 1}
                      >
                        {value}
                      </TableCell>
                    )
                  )}
                </TableRow>
              )}
            {utilityInfo?.othersDemandChargeKwh &&
              utilityInfo?.othersDemandChargeKwh.length > 0 && (
                <TableRow>
                  <TableCell
                    sx={{
                      paddingX: "2px",
                      fontSize: "10px",
                      paddingY: "10px",
                    }}
                    key={0}
                    align="left"
                  >
                    {"Demand Others ($/kW)"}
                  </TableCell>
                  {utilityInfo?.othersDemandChargeKwh.map((value, index) => (
                    <TableCell
                      sx={{
                        paddingX: "2px",
                        paddingY: "10px",
                        fontSize: "10px",
                      }}
                      key={index + 1}
                    >
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              )}
          </TableBody>
        </Table>
        <Divider component="br" />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                key={0}
                sx={{
                  paddingX: "4px",
                  fontSize: "10px",
                }}
              >
                {"PPA Rate"}
              </TableCell>
              {generate24HourLabels().map((column, index) => (
                <TableCell
                  key={index + 1}
                  sx={{
                    paddingX: "2px",
                    fontSize: "10px",
                    minWidth: "40px",
                  }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {utilityInfo?.ppaGenerationEnergyChargeKwh &&
              utilityInfo?.ppaGenerationEnergyChargeKwh.length > 0 && (
                <TableRow>
                  <TableCell
                    sx={{
                      paddingX: "4px",
                      paddingY: "10px",
                      fontSize: "10px",
                    }}
                    key={0}
                    align="left"
                  >
                    {"Energy Generation ($/kWh)"}
                  </TableCell>
                  {utilityInfo.ppaGenerationEnergyChargeKwh.map(
                    (value, index) => (
                      <TableCell
                        sx={{
                          paddingX: "2px",
                          fontSize: "10px",
                          paddingY: "10px",
                        }}
                        key={index + 1}
                      >
                        {value}
                      </TableCell>
                    )
                  )}
                </TableRow>
              )}
            {utilityInfo?.ppaTransmissionEnergyChargeKwh &&
              utilityInfo?.ppaTransmissionEnergyChargeKwh.length > 0 && (
                <TableRow>
                  <TableCell
                    sx={{
                      paddingX: "4px",
                      paddingY: "10px",
                      fontSize: "10px",
                    }}
                    key={0}
                    align="left"
                  >
                    {"Energy Transmission ($/kWh)"}
                  </TableCell>
                  {utilityInfo.ppaTransmissionEnergyChargeKwh.map(
                    (value, index) => (
                      <TableCell
                        sx={{
                          paddingX: "2px",
                          fontSize: "10px",
                          paddingY: "10px",
                        }}
                        key={index + 1}
                      >
                        {value}
                      </TableCell>
                    )
                  )}
                </TableRow>
              )}
            {utilityInfo?.ppaDistributionEnergyChargeKwh &&
              utilityInfo?.ppaDistributionEnergyChargeKwh.length > 0 && (
                <TableRow>
                  <TableCell
                    sx={{
                      paddingX: "4px",
                      paddingY: "10px",
                      fontSize: "10px",
                    }}
                    key={0}
                    align="left"
                  >
                    {"Energy Distribution ($/kWh)"}
                  </TableCell>
                  {utilityInfo.ppaDistributionEnergyChargeKwh.map(
                    (value, index) => (
                      <TableCell
                        sx={{
                          paddingX: "2px",
                          fontSize: "10px",
                          paddingY: "10px",
                        }}
                        key={index + 1}
                      >
                        {value}
                      </TableCell>
                    )
                  )}
                </TableRow>
              )}
            {utilityInfo?.ppaOthersEnergyChargeKwh &&
              utilityInfo?.ppaOthersEnergyChargeKwh.length > 0 && (
                <TableRow>
                  <TableCell
                    sx={{
                      paddingX: "2px",
                      fontSize: "10px",
                      paddingY: "10px",
                    }}
                    key={0}
                    align="left"
                  >
                    {"Energy Others ($/kWh)"}
                  </TableCell>
                  {utilityInfo?.ppaOthersEnergyChargeKwh.map((value, index) => (
                    <TableCell
                      sx={{
                        paddingX: "2px",
                        paddingY: "10px",
                        fontSize: "10px",
                      }}
                      key={index + 1}
                    >
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              )}
            {utilityInfo?.ppaGenerationDemandChargeKw &&
              utilityInfo?.ppaGenerationDemandChargeKw.length > 0 && (
                <TableRow>
                  <TableCell
                    sx={{
                      paddingX: "2px",
                      paddingY: "10px",
                      fontSize: "10px",
                    }}
                    key={0}
                    align="left"
                  >
                    {"Demand Generation ($/kW)"}
                  </TableCell>
                  {utilityInfo?.ppaGenerationDemandChargeKw.map(
                    (value, index) => (
                      <TableCell
                        sx={{
                          paddingX: "2px",
                          fontSize: "10px",
                          paddingY: "10px",
                        }}
                        key={index + 1}
                      >
                        {value}
                      </TableCell>
                    )
                  )}
                </TableRow>
              )}
            {utilityInfo?.ppaTransmissionDemandChargeKw &&
              utilityInfo?.ppaTransmissionDemandChargeKw.length > 0 && (
                <TableRow>
                  <TableCell
                    sx={{ paddingX: "2px", fontSize: "10px" }}
                    key={0}
                    align="left"
                  >
                    {"Demand Transmission ($/kW)"}
                  </TableCell>
                  {utilityInfo.ppaTransmissionDemandChargeKw.map(
                    (value, index) => (
                      <TableCell
                        sx={{
                          paddingX: "2px",
                          fontSize: "10px",
                          paddingY: "10px",
                        }}
                        key={index + 1}
                      >
                        {value}
                      </TableCell>
                    )
                  )}
                </TableRow>
              )}
            {utilityInfo?.ppaDistributionDemandChargeKw &&
              utilityInfo?.ppaDistributionDemandChargeKw.length > 0 && (
                <TableRow>
                  <TableCell
                    sx={{
                      paddingX: "2px",
                      fontSize: "10px",
                      paddingY: "10px",
                    }}
                    key={0}
                    align="left"
                  >
                    {"Demand Distribution ($/kW)"}
                  </TableCell>
                  {utilityInfo?.ppaDistributionDemandChargeKw.map(
                    (value, index) => (
                      <TableCell
                        sx={{
                          paddingX: "2px",
                          paddingY: "10px",
                          fontSize: "10px",
                        }}
                        key={index + 1}
                      >
                        {value}
                      </TableCell>
                    )
                  )}
                </TableRow>
              )}
            {utilityInfo?.ppaOthersDemandChargeKwh &&
              utilityInfo?.ppaOthersDemandChargeKwh.length > 0 && (
                <TableRow>
                  <TableCell
                    sx={{
                      paddingX: "2px",
                      fontSize: "10px",
                      paddingY: "10px",
                    }}
                    key={0}
                    align="left"
                  >
                    {"Demand Others ($/kW)"}
                  </TableCell>
                  {utilityInfo?.ppaOthersDemandChargeKwh.map((value, index) => (
                    <TableCell
                      sx={{
                        paddingX: "2px",
                        paddingY: "10px",
                        fontSize: "10px",
                      }}
                      key={index + 1}
                    >
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack width={"25%"}>
        <List dense>
          <ListItem disableGutters>
            <ListItemText
              primary="Fixed Monthly Fees"
              secondary={`$${utilityInfo?.fixedMonthlyFees ?? 0}`}
            />
          </ListItem>
          <Divider sx={{ marginTop: 2 }} />
          <ListItem disableGutters>
            <ListItemText
              primary="PPA Ratio"
              secondary={`${
                utilityInfo?.ppaRatio ? utilityInfo.ppaRatio * 100 : 0
              }%`}
            />
          </ListItem>
        </List>
      </Stack>
      <Button
        sx={{ marginTop: 2, width: "25%" }}
        color="info"
        variant={"contained"}
        size={"medium"}
        onClick={() =>
          utilityInfo && downloadUtilityRate(utilityInfo, "utility-rates.csv")
        }
      >
        Download
      </Button>
    </Box>
  );
};

export default UtilityInformation;
