import { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import {
  Box,
  Stack,
  Typography,
  FormControl,
  TextField,
  Input,
  Alert,
  AlertTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { uploadFile } from "api/s3";
import { processSitesFile } from "api/sites";
import { useAccessToken } from "utils/get-access-token";
const UPLOAD_SITE_API_URL = `${process.env.REACT_APP_API_GATEWAY_URL}/site-upload`;

/**
 * Returns a new object from the old one with the properties lowercased.
 */
function objectKeysToLowerCase(obj) {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [k.toLowerCase(), v])
  );
}

/**
 * Assigns site.lat and site.lng if similar properties are present on the object.
 * It will also convert all property names to lowercase.
 * @param {object} site the site to standardize
 * @returns a new site object with site.lat and site.lng
 */
function standardizeSiteProperties(site) {
  site = objectKeysToLowerCase(site);
  site.lng = site.lng || site.long || site.longitude;
  site.lat = site.lat || site.latitude;
  site.parking_area = site.parking_area || site.parkingarea;
  site.demand = site.demand || site.charging_demand || site.chargingdemand;
  site.nearby_amenities =
    site.nearby_amenities !== undefined
      ? JSON.parse(String(site.nearby_amenities).toLowerCase())
      : undefined;
  site.nearby_residential =
    site.nearby_residential !== undefined
      ? JSON.parse(String(site.nearby_residential).toLowerCase())
      : undefined;
  site.nearby_highway =
    site.nearby_highway !== undefined
      ? JSON.parse(String(site.nearby_highway).toLowerCase())
      : undefined;
  site.land_use_code = site.land_use_code || site.landusecode;
  return site;
}

async function validateSitesFile(file) {
  const data = await file.arrayBuffer();
  const workbook = XLSX.read(data);
  const wsJson = XLSX.utils.sheet_to_json(
    workbook.Sheets[workbook.SheetNames[0]]
  );

  // make object keys lowercase for consistency
  const sitesData = wsJson.map((obj) => standardizeSiteProperties(obj));

  // make sure we have data
  if (sitesData.length === 0) {
    return {
      success: false,
      message: `There are no data rows in the provided file`,
    };
  }

  for (let i = 0; i < sitesData.length; i++) {
    const site = sitesData[i];
    const hasName = site.name && site.name.toString().length > 0;
    if (!hasName) {
      return {
        success: false,
        message: `Error on row ${i + 1}: every site must have a name`,
      };
    }
    const isValidLat = site.lat >= -90 && site.lat <= 90;
    if (!isValidLat) {
      return {
        success: false,
        message: `Error on row ${
          i + 1
        }: lat must be between -90 and 90, inclusive`,
      };
    }
    const isValidLng = site.lng >= -180 && site.lng <= 180;
    if (!isValidLng) {
      return {
        success: false,
        message: `Error on row ${
          i + 1
        }: lng must be between -180 and 180, inclusive`,
      };
    }
    const scoreIsNumber = typeof site.score === "number";
    if (!scoreIsNumber) {
      return {
        success: false,
        message: `Error on row ${i + 1}: score must be a number`,
      };
    }
    const parkingAreaIsNumber =
      typeof site.parking_area === "number" || site.parking_area === undefined;
    if (!parkingAreaIsNumber) {
      return {
        success: false,
        message: `Error on row ${
          i + 1
        }: parking_area must be a number or left blank`,
      };
    }
    const parkingAreaLessThanZero = site.parking_area < 0;
    if (typeof site.parking_area === "number" && parkingAreaLessThanZero) {
      return {
        success: false,
        message: `Error on row ${
          i + 1
        }: parking_area must be greater than or equal to 0`,
      };
    }
    const demandIsNumber =
      typeof site.demand === "number" || site.demand === undefined;
    if (!demandIsNumber) {
      return {
        success: false,
        message: `Error on row ${i + 1}: demand must be a number or left blank`,
      };
    }
    const demandLessThanZero = site.demand < 0;
    if (typeof site.demand === "number" && demandLessThanZero) {
      return {
        success: false,
        message: `Error on row ${
          i + 1
        }: demand must be greater than or equal to 0`,
      };
    }
    const nearbyAmenitiesIsBoolean =
      site.nearby_amenities === undefined ||
      typeof site.nearby_amenities === "boolean";
    if (!nearbyAmenitiesIsBoolean) {
      return {
        success: false,
        message: `Error on row ${
          i + 1
        }: nearby_amenities must be 'true', 'false', or left blank`,
      };
    }
    const nearbyResidentialIsBoolean =
      site.nearby_residential === undefined ||
      typeof site.nearby_residential === "boolean";
    if (!nearbyResidentialIsBoolean) {
      return {
        success: false,
        message: `Error on row ${
          i + 1
        }: nearby_residential must be 'true', 'false', or left blank`,
      };
    }
    const nearbyHighwayIsBoolean =
      site.nearby_highway === undefined ||
      typeof site.nearby_highway === "boolean";
    if (!nearbyHighwayIsBoolean) {
      return {
        success: false,
        message: `Error on row ${
          i + 1
        }: nearby_highway must be 'true', 'false', or left blank`,
      };
    }
    const landUseCodeIsNumber =
      site.land_use_code === undefined ||
      typeof site.land_use_code === "number";
    if (!landUseCodeIsNumber) {
      return {
        success: false,
        message: `Error on row ${
          i + 1
        }: land_use_code must be a number or left blank`,
      };
    }
    const landUseCodeLessThanZero = site.land_use_code < 0;
    if (typeof site.land_use_code === "number" && landUseCodeLessThanZero) {
      return {
        success: false,
        message: `Error on row ${
          i + 1
        }: land_use_code must be greater than or equal to 0`,
      };
    }
  }
  return {
    success: true,
  };
}

export default function UploadSiteCollectionModal(props) {
  const [newSiteCollectionName, setNewSiteCollectionName] = useState("");
  const [newSiteCollectionFile, setNewSiteCollectionFile] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [uploadingNewSiteCollection, setUploadingNewSiteCollection] =
    useState(false);
  const [formatPreviewTab, setFormatPreviewTab] = useState("csv");

  useEffect(() => {
    (async () => {
      if (!newSiteCollectionFile) return;
      const validation = await validateSitesFile(newSiteCollectionFile);
      setShowErrorMessage(validation.success);
      setErrorMessage(validation.message);
    })();
  }, [newSiteCollectionFile]);

  const { getToken } = useAccessToken();

  const handleUploadButtonClick = async () => {
    const apiToken = await getToken();
    setUploadingNewSiteCollection(true);
    try {
      const fileName = await uploadFile(
        `${UPLOAD_SITE_API_URL}/initialize`,
        `${UPLOAD_SITE_API_URL}/complete`,
        newSiteCollectionFile,
        apiToken
      );
      await processSitesFile(
        `${UPLOAD_SITE_API_URL}/process-file`,
        newSiteCollectionName,
        fileName,
        apiToken
      );
      props.refreshSiteCollections();
      props.onClose();
    } catch (error) {
      setShowErrorMessage(false);
      setErrorMessage(
        "An unexpected error occurred. If this persists, please contact support."
      );
    }
    setUploadingNewSiteCollection(false);
  };

  const [showExtraData, setShowExtraData] = useState(false);
  const exampleFileTitle = (
    <Stack direction={"row"} alignItems={"center"} spacing={2}>
      <Typography fontWeight={"bold"}>Example File</Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={showExtraData}
              onChange={(event) => setShowExtraData(event.target.checked)}
            />
          }
          label="Show optional data"
        />
      </FormGroup>
    </Stack>
  );

  const exampleData = [
    {
      // first row of data might as well be the key
      name: "name",
      lat: "lat",
      lng: "lng",
      score: "score",
      parking_area: "parking_area",
      demand: "demand",
      nearby_amenities: "nearby_amenities",
      nearby_residential: "nearby_residential",
      nearby_highway: "nearby_highway",
      land_use_code: "land_use_code",
      address: "address",
    },
    {
      name: "Parking Lot",
      lat: 30,
      lng: -100,
      score: 3,
      parking_area: 200000,
      demand: 1500,
      nearby_amenities: true,
      nearby_residential: false,
      nearby_highway: true,
      land_use_code: 950,
      address: "123 Main St.",
    },
    {
      name: "Transit Station",
      lat: 60,
      lng: 10,
      score: 2.5,
      parking_area: null,
      demand: null,
      nearby_amenities: null,
      nearby_residential: null,
      nearby_highway: null,
      land_use_code: null,
      address: "1600 Pennsylvania Ave.",
    },
    {
      name: "Library",
      lat: -15,
      lng: -120,
      score: 10,
      parking_area: 50000,
      demand: 500,
      nearby_amenities: true,
      nearby_residential: true,
      nearby_highway: false,
      land_use_code: 11,
      address: "25 Grenfell St.",
    },
  ];

  return (
    <Modal {...props}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "8px",
          maxHeight: "80%",
          minWidth: "30%",
          maxWidth: "90%",
          overflowY: "scroll",
        }}
      >
        <Stack sx={{ height: "100%", padding: "1em" }}>
          <Typography variant="controlTitle">Add a new site list</Typography>
          <Stack spacing={2}>
            <TextField
              label={"Site List Name"}
              value={newSiteCollectionName}
              onChange={(event) => setNewSiteCollectionName(event.target.value)}
              required
              helperText={"* required"}
            />
            <Stack>
              <FormControl>
                <Input
                  id="site-collection-file"
                  type="file"
                  inputProps={{ accept: ".csv,.geojson,.xlsx" }}
                  onChange={(event) =>
                    setNewSiteCollectionFile(Array.from(event.target.files)[0])
                  }
                />
              </FormControl>
              <Accordion disableGutters={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>See File Format Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={1}>
                    <Alert severity="warning">
                      Any sites outside the charging region will not be
                      displayed.
                    </Alert>
                    <List>
                      <Typography fontWeight={"bold"}>
                        For all sites and file formats:
                      </Typography>
                      <ListItem sx={{ display: "list-item" }}>
                        <Typography>
                          Required fields:
                          <List sx={{ listStyleType: "disc", pl: 4 }}>
                            <ListItem sx={{ display: "list-item" }}>
                              <Typography inline variant="inlineCode">
                                name
                              </Typography>
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                              <Typography inline variant="inlineCode">
                                lat
                              </Typography>
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                              <Typography inline variant="inlineCode">
                                lng
                              </Typography>
                            </ListItem>
                          </List>
                          Fields used in site evaluation:
                          <List sx={{ listStyleType: "disc", pl: 4 }}>
                            <ListItem sx={{ display: "list-item" }}>
                              <Typography inline variant="inlineCode">
                                score
                              </Typography>
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                              <Typography inline variant="inlineCode">
                                parking_area
                              </Typography>
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                              <Typography inline variant="inlineCode">
                                demand
                              </Typography>
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                              <Typography inline variant="inlineCode">
                                nearby_amenities
                              </Typography>
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                              <Typography inline variant="inlineCode">
                                nearby_residential
                              </Typography>
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                              <Typography inline variant="inlineCode">
                                nearby_highway
                              </Typography>
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                              <Typography inline variant="inlineCode">
                                land_use_code
                              </Typography>
                            </ListItem>
                          </List>
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        <Typography>
                          <Typography inline variant="inlineCode">
                            lat
                          </Typography>
                          must be between -90 and 90, inclusive.
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        <Typography>
                          <Typography inline variant="inlineCode">
                            lng
                          </Typography>
                          must be between -180 and 180, inclusive.
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        <Typography>
                          <Typography inline variant="inlineCode">
                            score
                          </Typography>
                          ,
                          <Typography inline variant="inlineCode">
                            parking_area
                          </Typography>
                          ,
                          <Typography inline variant="inlineCode">
                            demand
                          </Typography>
                          , and
                          <Typography inline variant="inlineCode">
                            land_use_code
                          </Typography>
                          must be a number.
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        <Typography>
                          <Typography inline variant="inlineCode">
                            nearby_amenities
                          </Typography>
                          ,
                          <Typography inline variant="inlineCode">
                            nearby_residential
                          </Typography>
                          , and
                          <Typography inline variant="inlineCode">
                            nearby_highway
                          </Typography>
                          must be 'true' or 'false'.
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        <Typography>
                          <Typography inline variant="inlineCode">
                            parking_area
                          </Typography>
                          ,
                          <Typography inline variant="inlineCode">
                            demand
                          </Typography>
                          ,
                          <Typography inline variant="inlineCode">
                            nearby_amenities
                          </Typography>
                          ,
                          <Typography inline variant="inlineCode">
                            nearby_residential
                          </Typography>
                          ,
                          <Typography inline variant="inlineCode">
                            nearby_highway
                          </Typography>
                          , and
                          <Typography inline variant="inlineCode">
                            land_use_code
                          </Typography>
                          can be left blank.
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        <Typography>
                          Multiple additional columns are optional. The values
                          will be viewable in site pop-ups, but are not
                          considered in site evaluation.
                        </Typography>
                      </ListItem>
                    </List>
                  </Stack>
                  <TabContext value={formatPreviewTab}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={(event, newValue) =>
                          setFormatPreviewTab(newValue)
                        }
                        centered
                        aria-label="lab API tabs example"
                      >
                        <Tab label="CSV" value="csv" />
                        <Tab label="Excel" value="excel" />
                      </TabList>
                    </Box>
                    <TabPanel value="csv">
                      {exampleFileTitle}
                      <Box
                        sx={{
                          backgroundColor: "#eee",
                          fontFamily: "monospace",
                          margin: "1em",
                          padding: "1em",
                        }}
                      >
                        <pre>
                          {exampleData.map((site) => {
                            const values = [];
                            values.push(site.name);
                            values.push(site.lat);
                            values.push(site.lng);
                            values.push(site.score);
                            values.push(site.parking_area);
                            values.push(site.demand);
                            values.push(site.nearby_amenities);
                            values.push(site.nearby_residential);
                            values.push(site.nearby_highway);
                            values.push(site.land_use_code);
                            if (showExtraData) values.push(site.address);
                            return values.toString() + "\n";
                          })}
                        </pre>
                      </Box>
                    </TabPanel>
                    <TabPanel value="excel">
                      <List>
                        <ListItem sx={{ display: "list-item" }}>
                          <Typography>
                            The first cell of the table must be A1.
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                          <Typography>The table must be on Sheet1.</Typography>
                        </ListItem>
                      </List>
                      {exampleFileTitle}
                      <Box
                        sx={{
                          fontFamily: "monospace",
                          margin: "1em",
                          padding: "1em",
                          backgroundColor: "#eee",
                        }}
                      >
                        <Table size="small" aria-label="a dense table">
                          <TableBody>
                            {exampleData.map((site) => {
                              return (
                                <TableRow key={site.name}>
                                  <TableCell>{site.name}</TableCell>
                                  <TableCell align="right">
                                    {site.lat}
                                  </TableCell>
                                  <TableCell align="right">
                                    {site.lng}
                                  </TableCell>
                                  <TableCell align="right">
                                    {site.score}
                                  </TableCell>
                                  <TableCell align="right">
                                    {site.parking_area}
                                  </TableCell>
                                  <TableCell align="right">
                                    {site.demand}
                                  </TableCell>
                                  <TableCell align="right">
                                    {site.nearby_amenities === null
                                      ? ""
                                      : String(site.nearby_amenities)}
                                  </TableCell>
                                  <TableCell align="right">
                                    {site.nearby_residential === null
                                      ? ""
                                      : String(site.nearby_residential)}
                                  </TableCell>
                                  <TableCell align="right">
                                    {site.nearby_highway === null
                                      ? ""
                                      : String(site.nearby_highway)}
                                  </TableCell>
                                  <TableCell align="right">
                                    {site.land_use_code}
                                  </TableCell>
                                  {showExtraData && (
                                    <TableCell align="right">
                                      {site.address}
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </Box>
                    </TabPanel>
                  </TabContext>
                </AccordionDetails>
              </Accordion>
            </Stack>
            {!showErrorMessage && newSiteCollectionFile && (
              <Alert severity="error">
                <AlertTitle>Validation Error</AlertTitle>
                {errorMessage}
              </Alert>
            )}
            <LoadingButton
              variant="contained"
              disabled={
                !showErrorMessage ||
                newSiteCollectionName.length === 0 ||
                newSiteCollectionFile === null
              }
              onClick={handleUploadButtonClick}
              loading={uploadingNewSiteCollection}
            >
              Upload
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
