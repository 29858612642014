import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  DialogProps,
  FormLabel,
  IconButton,
  RadioGroup,
  Radio,
  Alert,
  Grid,
  FormGroup,
  InputAdornment,
  Snackbar,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useEffect, useState } from "react";
import {
  FleetMix,
  PASSENGER_VEHICLES,
  TRUCK_VEHICLES,
  VOCATIONAL_VEHICLES,
  FleetMixVehicle,
} from "dashboard/fleets/fleet-mix";
import { FleetMixVehicleTypeConfiguration } from "../FleetMixVehicleTypeConfiguration";
import { LoadingButton } from "@mui/lab";
import { v4 as uuidv4 } from "uuid";
import {
  FleetCharger,
  FleetDefaultVehicle,
  FleetScenario,
  FleetScenarioChargerLevelInput,
  FleetScenarioInput,
  FleetScenarioVehicle,
  FleetScenarioVehicleInput,
} from "types/fleets";
import { createFleetScenario } from "api/fleets/create-fleet-scenario";
import { useAccessToken } from "utils/get-access-token";

export type AdvancedDialogProps = {
  DialogProps: DialogProps;
};
export function AdvancedDialog({ DialogProps }: AdvancedDialogProps) {
  const steps = [
    "Provide Fleet Details",
    "Review Vehicle and Financial Assumptions",
  ];
  return (
    <Dialog {...DialogProps} maxWidth="md" fullWidth>
      <DialogTitle>
        <Stack direction="row" justifyContent={"space-between"}>
          Create a New Scenario
          <Stepper activeStep={0} sx={{ width: "600px" }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ marginTop: "0.5em" }}>
          <Stack spacing={2}>
            <Stack direction={"row"} spacing={2}>
              <TextField
                required
                label="SMUD Customer ID"
                sx={{ width: "32%" }}
                value={"12345678"}
                size="small"
              />
              <TextField
                required
                label="Planning Horizon (years)"
                type="number"
                sx={{ width: "32%" }}
                value={15}
                size="small"
              />
            </Stack>
            <Stack spacing={2}>
              <Stack direction="row" spacing={2}>
                <Typography variant="h2">Fleet Information</Typography>
                <Button size="small" variant="outlined">
                  <UploadFileIcon sx={{ marginRight: "6px" }} /> Upload Fleet
                  Information
                </Button>
              </Stack>
              <Stack spacing={2}>
                <Stack spacing={2}>
                  <Stack spacing={2}>
                    <Typography variant="h3">Vehicle 1</Typography>
                    <Stack spacing={2} direction="row">
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">Make</InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Make"
                          size="small"
                        >
                          <MenuItem value="1">Ford</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">Model</InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Model"
                          size="small"
                        >
                          <MenuItem value="1">F-150</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">Year</InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Year"
                          size="small"
                        >
                          <MenuItem value="1">2019</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">Trim</InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Trim"
                          size="small"
                        >
                          <MenuItem value="1">XLT</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">
                          Engine Size
                        </InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Engine Size"
                          size="small"
                        >
                          <MenuItem value="1">3.5L</MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <TextField
                        type="number"
                        label="Annual Operating Costs"
                        InputProps={{
                          startAdornment: <Typography>$</Typography>,
                        }}
                        size="small"
                        value={1977.38}
                        sx={{
                          width: "22%",
                        }}
                      />
                      <TextField
                        type="number"
                        label="Depreciation Rate"
                        InputProps={{
                          endAdornment: <Typography>%</Typography>,
                        }}
                        size="small"
                        value={15.1}
                        sx={{
                          width: "20%",
                        }}
                      />
                      <Button
                        variant="outlined"
                        sx={{
                          border: "1px solid #BBB",
                          color: "rgba(0, 0, 0, 0.87)",
                        }}
                      >
                        Edit Purchase Information &gt;
                      </Button>
                      <Button
                        variant="outlined"
                        sx={{
                          border: "1px solid #BBB",
                          color: "rgba(0, 0, 0, 0.87)",
                          flex: 1,
                        }}
                      >
                        Edit Daily Schedule &gt;
                      </Button>
                    </Stack>
                  </Stack>
                  <Stack spacing={2}>
                    <Typography variant="h3">Vehicle 2</Typography>
                    <Stack spacing={2} direction="row">
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">Make</InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Make"
                          size="small"
                        >
                          <MenuItem value="1">Ford</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">Model</InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Model"
                          size="small"
                        >
                          <MenuItem value="1">F-250</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">Year</InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Year"
                          size="small"
                        >
                          <MenuItem value="1">2012</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">Trim</InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Trim"
                          size="small"
                        >
                          <MenuItem value="1">XL</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">
                          Engine Size
                        </InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Engine Size"
                          size="small"
                        >
                          <MenuItem value="1">7.3L</MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <TextField
                        type="number"
                        label="Annual Operating Costs"
                        InputProps={{
                          startAdornment: <Typography>$</Typography>,
                        }}
                        size="small"
                        value={2395.03}
                        sx={{
                          width: "22%",
                        }}
                      />
                      <TextField
                        type="number"
                        label="Depreciation Rate"
                        InputProps={{
                          endAdornment: <Typography>%</Typography>,
                        }}
                        size="small"
                        value={8.1}
                        sx={{
                          width: "20%",
                        }}
                      />
                      <Button
                        variant="outlined"
                        sx={{
                          border: "1px solid #BBB",
                          color: "rgba(0, 0, 0, 0.87)",
                        }}
                      >
                        Edit Purchase Information &gt;
                      </Button>
                      <Button
                        variant="outlined"
                        sx={{
                          border: "1px solid #BBB",
                          color: "rgba(0, 0, 0, 0.87)",
                          flex: 1,
                        }}
                      >
                        Edit Daily Schedule &gt;
                      </Button>
                    </Stack>
                  </Stack>
                  <Button size="small" sx={{ width: "250px", height: "20px" }}>
                    <AddIcon />
                    Add another vehicle
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h2">Viable Chargers</Typography>
            <Stack spacing={4} direction="row">
              <Typography sx={{ width: "50%" }}>
                Is there a maximum number of chargers you are willing to
                purchase/have space for?
              </Typography>
              <TextField
                label={"Maximum Number of Chargers"}
                value={32}
                type={"number"}
                sx={{ width: "50%" }}
              />
            </Stack>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <FormControlLabel
                  control={<Checkbox />}
                  label={"Level 2 Only"}
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={<Checkbox />}
                  label={"DC Fast Only"}
                  checked
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={<Checkbox />}
                  label={"DC Fast + Level 2"}
                />
              </Box>
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"}>Cancel</Button>
        <Button variant="contained">Next</Button>
      </DialogActions>
    </Dialog>
  );
}

type FleetScenarioForm = {
  name: string;
  planningHorizon: number;
  application: string;
  maxNumChargers: number;
  attitude: string;
  ownership: string;
  multipleLocation: string;
  depotRoute: string;
  fleetMix: FleetMix;
  costOfICE: number;
  annualICEMaintenance: number;
  annualEVMaintenance: number;
  avergeEVEnergyCharge: number;
  averageEVDemandCharge: number;
  scope: string[];
  chargingLocation: string[];
  chargerValues: FleetScenarioChargerLevelInput[];
  chargingStrategy: string;
};

export type BasicDialogProps = {
  fleetScenarios: FleetScenario[];
  fleetDefaultVehicles: FleetDefaultVehicle[];
  setSelectedScenarioId: (id: number) => void;
  DialogProps: DialogProps;
  onSubmit: () => void;
  fleetChargers: FleetCharger[];
  setReferenceScenarioId: (id: number) => void;
};

const getChargerLevels = (
  chargers: FleetCharger[]
): FleetScenarioChargerLevelInput[] => {
  return chargers.map((charger) => {
    return {
      capacity: charger.capacity,
      charger_type: charger.chargerType,
      checked: false,
    };
  });
};

export type FleetError = {
  scenarioName: boolean;
  planningHorizon: boolean;
  costOfICE: boolean;
  annualICEMaintenance: boolean;
  annualEVMaintenance: boolean;
  avergeEVEnergyCharge: boolean;
  averageEVDemandCharge: boolean;
  isError: boolean;
  vehicleError: boolean;
  scopeError: boolean;
  vehicleClassError: boolean;
  chargerError: boolean;
  message?: string;
};

const describeCompany = {
  "1": "Company bought in on fleet electrification",
  "2": "Single champion for electrification in company",
  "3": "Reluctance towards electrification",
};

interface BasicFleetScenarioProps {
  fleetScenario: FleetScenarioForm;
  fleetScenarios: FleetScenario[];
  setFleetScenario: React.Dispatch<React.SetStateAction<FleetScenarioForm>>;
  fleetError: FleetError;
  setFleetError: (fleetError: FleetError) => void;
  stepper: string[];
  setStepper: (stepper: string[]) => void;
  fleetChargers: FleetCharger[];
}

const findKeyByValue = (
  obj: Record<string, string>,
  value: string
): string | undefined => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

const BasicFleetScenario = ({
  fleetScenario,
  fleetScenarios,
  setFleetScenario,
  fleetError,
  setFleetError,
  stepper,
  setStepper,
  fleetChargers,
}: BasicFleetScenarioProps) => {
  const [config, setConfig] = useState<String>("new");
  const [copyScenarioId, setCopyScenarioId] = useState<number | undefined>();

  useEffect(() => {
    if (!copyScenarioId) return;

    const copyScenario = fleetScenarios.find((sc) => copyScenarioId === sc.id);
    if (!copyScenario) return;

    // Initialize Fleet Scenario Data
    const newFleetScenarioData: FleetScenarioForm = {
      name: `Copy of ${copyScenario.name}`,
      planningHorizon: copyScenario.planning_horizon,
      application: copyScenario.application,
      maxNumChargers: copyScenario.max_no_of_chargers,
      attitude: findKeyByValue(describeCompany, copyScenario.attitude) || "1",
      ownership: copyScenario.ownership_status,
      multipleLocation: copyScenario.multiple_locations ? "true" : "false",
      depotRoute: copyScenario.depot_route,
      fleetMix: {
        passengerVehicles: { operatingHours: [9, 17], vehicles: [] },
        truckVehicles: { operatingHours: [9, 17], vehicles: [] },
        vocationalVehicles: { operatingHours: [9, 17], vehicles: [] },
      },
      costOfICE: copyScenario.fuel_cost,
      annualEVMaintenance: copyScenario.annual_ev_maintenance,
      annualICEMaintenance: copyScenario.annual_ice_maintenance,
      averageEVDemandCharge: copyScenario.avg_ev_demand_charge,
      avergeEVEnergyCharge: copyScenario.avg_ev_energy_charge,
      scope: copyScenario.scope,
      chargingLocation: copyScenario.charge_location,
      chargerValues: getChargerLevels(fleetChargers),
      chargingStrategy: copyScenario.chargingStrategy,
    };

    // Helper function to map fleet vehicles
    const mapFleetVehicles = (
      fleetVehicle: FleetScenarioVehicle,
      typeKey: string
    ) => ({
      type: fleetVehicle.vehicle_type,
      id: uuidv4(),
      numEv: fleetVehicle.num_electric_vehicles,
      numIce: fleetVehicle.num_ice_vehicles,
      annualMileage: fleetVehicle.total_annual_mileage,
      typeError: false,
      annualMileageError: false,
      errorMessage: "",
      numEvError: false,
      numIceError: false,
      unitCostPerEV: fleetVehicle.unitCostPerEV,
      unitCostPerICE: fleetVehicle.unitCostPerICE,
    });

    // Process vehicle types and add to fleet mix
    const vehicleTypes: Array<{
      type: string;
      label: string;
      mixKey: keyof FleetMix;
    }> = [
      { type: "PASSENGER", label: "passenger", mixKey: "passengerVehicles" },
      { type: "TRUCK", label: "truck", mixKey: "truckVehicles" },
      { type: "VOCATIONAL", label: "vocational", mixKey: "vocationalVehicles" },
    ];

    const stepper = vehicleTypes.reduce<string[]>(
      (acc, { type, label, mixKey }) => {
        const fleetVehicles = copyScenario.fleetVehicles.filter(
          (vehicle) => vehicle.fleet_type === type
        );
        if (fleetVehicles.length > 0) {
          fleetVehicles.forEach((vehicle) => {
            newFleetScenarioData.fleetMix[mixKey].operatingHours =
              vehicle.operatingHours;
            newFleetScenarioData.fleetMix[mixKey].vehicles.push(
              mapFleetVehicles(vehicle, mixKey)
            );
          });
          acc.push(label);
        }
        return acc;
      },
      []
    );

    // Set stepper stages
    setStepper(["basic", ...stepper, "last"]);

    // Process charger values and update checked status
    const chargerTypes = ["Level 2", "DCFC Option 1", "DCFC Option 2"];

    newFleetScenarioData.chargerValues.forEach((charger) => {
      if (
        chargerTypes.includes(charger.charger_type) &&
        copyScenario.fleetChargerLevels.some(
          (copyCharger) => copyCharger.charger_type === charger.charger_type
        )
      ) {
        charger.checked = true;
      }
    });

    // Update fleet scenario data
    setFleetScenario(newFleetScenarioData);
    setFleetError({ ...fleetError, isError: false });
  }, [copyScenarioId, fleetScenarios, setFleetScenario]);

  const handleChargerValues = (
    index: number,
    value: FleetScenarioChargerLevelInput
  ) => {
    const updatedValues = [...fleetScenario.chargerValues];
    updatedValues[index] = value;
    setFleetScenario({ ...fleetScenario, chargerValues: updatedValues });
  };

  return (
    <Stack spacing={2} sx={{ marginTop: "0.5em" }}>
      <Stack spacing={2} direction={"row"}>
        <RadioGroup
          sx={{ width: "60%" }}
          row
          value={config}
          onChange={(event) => setConfig(event.target.value)}
        >
          <FormControlLabel
            value="new"
            control={<Radio />}
            label="Create a new scenario"
          />
          <FormControlLabel
            value="copy"
            control={<Radio />}
            label="Copy an existing scenario"
          />
        </RadioGroup>
        {config === "copy" && (
          <FormControl
            variant="outlined"
            size="small"
            sx={{
              width: fleetScenarios.length !== 0 ? "40%" : "26%",
            }}
          >
            {fleetScenarios.length !== 0 ? (
              <>
                <InputLabel>List of scenarios</InputLabel>
                <Select
                  label="List of scenarios"
                  value={copyScenarioId}
                  onChange={(event) =>
                    setCopyScenarioId(Number(event.target.value))
                  }
                >
                  <MenuItem value={""}>None</MenuItem>
                  {fleetScenarios.map((scenario) => (
                    <MenuItem key={scenario.id} value={scenario.id}>
                      {scenario.name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            ) : (
              <TextField
                label="List of scenarios"
                value={"No scenario available"}
                size="small"
                disabled
                sx={{ input: { cursor: "not-allowed" } }}
                InputProps={{ style: { fontSize: "13px" } }}
                InputLabelProps={{
                  style: { fontSize: "12px" },
                }}
              />
            )}
          </FormControl>
        )}
      </Stack>
      <Stack spacing={2}>
        <Stack direction={"row"} spacing={2}>
          <TextField
            required
            label="Scenario Name"
            sx={{ width: "68%" }}
            value={fleetScenario.name}
            onChange={(e) => {
              setFleetScenario({
                ...fleetScenario,
                name: e.target.value,
              });
              if (
                fleetScenarios.some(
                  (fleet) =>
                    fleet.name.toLowerCase() === e.target.value.toLowerCase()
                )
              ) {
                setFleetError({
                  ...fleetError,
                  scenarioName: true,
                  isError: true,
                });
                return;
              }
              setFleetError({
                ...fleetError,
                scenarioName: false,
                isError: false,
              });
            }}
            error={fleetError.scenarioName}
            helperText={
              fleetError.scenarioName ? "Please Enter Valid Scenario Name." : ""
            }
          />
          <TextField
            required
            label="Planning Horizon (years)"
            type="number"
            sx={{ width: "32%" }}
            value={fleetScenario.planningHorizon}
            onChange={(e) => {
              setFleetError({
                ...fleetError,
                planningHorizon: false,
                isError: false,
              });
              setFleetScenario({
                ...fleetScenario,
                planningHorizon: Number(e.target.value),
              });
            }}
            error={fleetError.planningHorizon}
            helperText={
              fleetError.planningHorizon
                ? "Please Enter Planning Horizon (Years)."
                : ""
            }
          />
        </Stack>
        <Stack spacing={2}>
          <Stack spacing={2}>
            <Typography variant="h2">Vehicle Classes For Analysis</Typography>
            <FormGroup row>
              <Stack direction="row" spacing={2}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Passenger"
                  checked={stepper.includes("passenger")}
                  onChange={(e, checked) => {
                    if (!checked) {
                      const newStepper = stepper.filter(
                        (step) => step !== "passenger"
                      );
                      setStepper(newStepper);
                      return;
                    }
                    setFleetError({
                      ...fleetError,
                      vehicleClassError: false,
                      isError: false,
                    });
                    const newStepper = [...stepper];
                    newStepper.splice(1, 0, "passenger");
                    setStepper(newStepper);
                  }}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Truck"
                  checked={stepper.includes("truck")}
                  onChange={(e, checked) => {
                    if (!checked) {
                      const newStepper = stepper.filter(
                        (step) => step !== "truck"
                      );
                      setStepper(newStepper);
                      return;
                    }
                    const newStepper = [...stepper];
                    const position = newStepper[1] !== "passenger" ? 1 : 2;
                    setFleetError({
                      ...fleetError,
                      vehicleClassError: false,
                      isError: false,
                    });
                    newStepper.splice(position, 0, "truck");
                    setStepper(newStepper);
                  }}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Vocational"
                  checked={stepper.includes("vocational")}
                  onChange={(e, checked) => {
                    if (!checked) {
                      const newStepper = stepper.filter(
                        (step) => step !== "vocational"
                      );
                      setStepper(newStepper);
                      return;
                    }
                    const newStepper = [...stepper];
                    setFleetError({
                      ...fleetError,
                      vehicleClassError: false,
                      isError: false,
                    });
                    newStepper.splice(-1, 0, "vocational");
                    setStepper(newStepper);
                  }}
                />
              </Stack>
            </FormGroup>
            {fleetError.vehicleClassError && (
              <Stack>
                <Typography
                  sx={{ marginTop: 0 }}
                  variant="body1"
                  style={{ color: "#d32f2f" }}
                >
                  Please select at least one vehicle class before continuing.
                </Typography>
              </Stack>
            )}
            <FormControl component="fieldset" className="application-control">
              <FormLabel>Application</FormLabel>
              <RadioGroup
                name="application"
                value={fleetScenario.application}
                onChange={(e, value) =>
                  setFleetScenario({
                    ...fleetScenario,
                    application: e.target.value,
                  })
                }
              >
                <Stack direction="row" spacing={2}>
                  <FormControlLabel
                    value="city"
                    control={<Radio />}
                    label="City"
                  />
                  <FormControlLabel
                    value="highway"
                    control={<Radio />}
                    label="Highway"
                  />
                  <FormControlLabel
                    value="off-road"
                    control={<Radio />}
                    label="Off-road"
                  />
                </Stack>
              </RadioGroup>
            </FormControl>
          </Stack>
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h2">Viable Chargers</Typography>
        <Stack maxWidth={"75%"} direction="row">
          <Typography>
            Is there a maximum number of chargers you are willing to
            purchase/have space for?
          </Typography>
          <TextField
            label={"Maximum Number of Chargers"}
            value={fleetScenario.maxNumChargers.toString()}
            onKeyDown={(event) => {
              if (event.key === "-" || event.key === ".") {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              const chargerCount = parseInt(e.target.value);
              if (chargerCount < 0) {
                return;
              }
              setFleetScenario({
                ...fleetScenario,
                maxNumChargers: chargerCount,
              });
            }}
            inputProps={{ min: 0 }}
            type={"number"}
            sx={{ width: "400px" }}
          />
        </Stack>
        <FormControl component="fieldset" className="application-control">
          <FormLabel>Charging Strategy</FormLabel>
          <RadioGroup
            name="chargingStrategy"
            defaultValue={"spread-over"}
            value={fleetScenario.chargingStrategy}
            onChange={(e, value) =>
              setFleetScenario({
                ...fleetScenario,
                chargingStrategy: e.target.value,
              })
            }
          >
            <Stack direction="row" spacing={2}>
              <FormControlLabel
                value="maximum-rating"
                control={<Radio />}
                label="Charge to full ASAP (higher cost)"
              />
              <FormControlLabel
                value="spread-over"
                control={<Radio />}
                label="Charge slowly over idle hours (lower cost)"
              />
            </Stack>
          </RadioGroup>
        </FormControl>

        <FormControl error={fleetError.chargerError}>
          <FormLabel>Charger Levels to Consider</FormLabel>
          <Grid container direction={"row"} spacing={2}>
            {fleetScenario.chargerValues.map((charger, index) => (
              <Grid item md={2.6}>
                <Grid container direction={"column"}>
                  <Grid item>
                    <FormControlLabel
                      control={<Checkbox />}
                      label={charger.charger_type}
                      checked={charger.checked}
                      onChange={(e, checked) => {
                        if (checked) {
                          setFleetError({ ...fleetError, chargerError: false });
                        }
                        handleChargerValues(index, {
                          ...charger,
                          checked,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      value={charger.capacity}
                      inputProps={{ min: 0 }}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">kW</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        handleChargerValues(index, {
                          ...charger,
                          capacity: Number(e.target.value),
                        });
                      }}
                      type="number"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
          {fleetError.chargerError && (
            <Stack>
              <Typography
                sx={{ marginTop: 1 }}
                variant="body1"
                style={{ color: "#d32f2f" }}
              >
                Please select at least one charger level before continuing.
              </Typography>
            </Stack>
          )}
        </FormControl>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h2">Fuel and Maintenance Information</Typography>
        <Stack spacing={2} direction="row">
          <TextField
            label="Cost of ICE/Diesel Fuel ($/gal)"
            value={fleetScenario.costOfICE}
            inputProps={{ step: "0.01", min: 0 }}
            sx={{ width: "33%" }}
            onChange={(e) => {
              const value = e.target.value;
              if (value.includes(".") && value.split(".")[1].length > 2) {
                return;
              }
              setFleetError({
                ...fleetError,
                costOfICE: false,
                isError: false,
              });
              setFleetScenario({
                ...fleetScenario,
                costOfICE: Number(value),
              });
            }}
            size="small"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            error={fleetError.costOfICE}
            helperText={
              fleetError.costOfICE
                ? "Please enter Cost of ICE/Diesel Fuel."
                : ""
            }
            FormHelperTextProps={{ sx: { marginLeft: "-2px" } }}
          />
          <TextField
            label="Annual ICE maintenance (% of price)"
            inputProps={{ min: 0 }}
            value={fleetScenario.annualICEMaintenance}
            onChange={(e) => {
              const value = e.target.value;
              if (value.includes(".") && value.split(".")[1].length > 2) {
                return;
              }
              setFleetError({
                ...fleetError,
                annualICEMaintenance: false,
                isError: false,
              });
              setFleetScenario({
                ...fleetScenario,
                annualICEMaintenance: Number(value),
              });
            }}
            sx={{ width: "33%" }}
            size="small"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            error={fleetError.annualICEMaintenance}
            helperText={
              fleetError.annualICEMaintenance
                ? "Please enter annual ICE maintenance."
                : ""
            }
            FormHelperTextProps={{ sx: { marginLeft: "-2px" } }}
          />
          <TextField
            label="Annual EV maintenance (% of price)"
            inputProps={{ min: 0 }}
            value={fleetScenario.annualEVMaintenance}
            sx={{ width: "33%" }}
            onChange={(e) => {
              const value = e.target.value;
              if (value.includes(".") && value.split(".")[1].length > 2) {
                return;
              }
              setFleetError({
                ...fleetError,
                annualEVMaintenance: false,
                isError: false,
              });
              setFleetScenario({
                ...fleetScenario,
                annualEVMaintenance: Number(value),
              });
            }}
            size="small"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            error={fleetError.annualEVMaintenance}
            helperText={
              fleetError.annualEVMaintenance
                ? "Please enter annual EV maintenance."
                : ""
            }
            FormHelperTextProps={{ sx: { marginLeft: "-2px" } }}
          />
        </Stack>
        <Stack spacing={2} direction="row">
          <Tooltip
            title="Cost of the amount of electricity (kWh) consumed during the analysis period"
            placement="top"
          >
            <TextField
              label="Average EV Energy Charge ($/kWh)"
              value={fleetScenario.avergeEVEnergyCharge}
              inputProps={{ step: "0.01", min: 0 }}
              sx={{ width: "32%" }}
              size="small"
              type="number"
              onChange={(e) => {
                const value = e.target.value;
                if (value.includes(".") && value.split(".")[1].length > 2) {
                  return;
                }
                setFleetError({
                  ...fleetError,
                  avergeEVEnergyCharge: false,
                  isError: false,
                });
                setFleetScenario({
                  ...fleetScenario,
                  avergeEVEnergyCharge: Number(value),
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
              error={fleetError.avergeEVEnergyCharge}
              helperText={
                fleetError.avergeEVEnergyCharge
                  ? "Please enter average EV energy charge."
                  : ""
              }
              FormHelperTextProps={{ sx: { marginLeft: "-2px" } }}
            />
          </Tooltip>
          <Tooltip
            title="Cost based on the highest level of electricity the fleet demands (kW) at one time during the analysis period"
            placement="top"
          >
            <TextField
              label="Average EV Demand Charge ($/kW)"
              value={fleetScenario.averageEVDemandCharge}
              onChange={(e) => {
                const value = e.target.value;
                if (value.includes(".") && value.split(".")[1].length > 5) {
                  return;
                }
                setFleetError({
                  ...fleetError,
                  averageEVDemandCharge: false,
                  isError: false,
                });
                setFleetScenario({
                  ...fleetScenario,
                  averageEVDemandCharge: Number(value),
                });
              }}
              sx={{ width: "32%" }}
              inputProps={{ step: "0.01", min: 0 }}
              size="small"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              error={fleetError.averageEVDemandCharge}
              helperText={
                fleetError.averageEVDemandCharge
                  ? "Please enter average EV demand charge."
                  : ""
              }
              FormHelperTextProps={{ sx: { marginLeft: "-2px" } }}
            />
          </Tooltip>
          <div style={{ flexGrow: 1 }} />
        </Stack>
      </Stack>
    </Stack>
  );
};

const HourInputField = ({
  label,
  hour,
  setHour,
  period = "AM",
  disabled = false,
}: {
  label: string;
  hour: number;
  period: string;
  disabled: boolean;
  setHour: (value: { hour: number; period: string }) => void;
}) => {
  return (
    <FormControl>
      <Tooltip title="Enter hour (1 - 12)">
        <TextField
          disabled={disabled}
          sx={{ width: "70%" }}
          type="number"
          label={label}
          value={hour}
          onChange={(e) => {
            const value = e.target.value;
            // Restrict input to numbers between 1 and 12
            if (/^(1[0-2]|[1-9])$/.test(value)) {
              setHour({ hour: Number(e.target.value), period });
            }
          }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*", maxLength: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ToggleButtonGroup
                  value={period}
                  exclusive
                  onChange={(event, newPeriod) => {
                    if (newPeriod) {
                      setHour({ hour, period: newPeriod });
                    }
                  }}
                  aria-label="AM/PM selector"
                  size="small"
                >
                  <ToggleButton value="AM" aria-label="AM">
                    AM
                  </ToggleButton>
                  <ToggleButton value="PM" aria-label="PM">
                    PM
                  </ToggleButton>
                </ToggleButtonGroup>
              </InputAdornment>
            ),
          }}
        />
      </Tooltip>
    </FormControl>
  );
};

function get24HourFormat({
  hour,
  period,
}: {
  hour: number;
  period: string;
}): number {
  if (hour < 1 || hour > 12) {
    throw new Error("Hour must be between 1 and 12 in 12-hour format.");
  }

  if (period !== "AM" && period !== "PM") {
    throw new Error("Period must be either 'AM' or 'PM'.");
  }

  if (period === "AM") {
    return hour === 12 ? 0 : hour;
  } else {
    return hour === 12 ? 12 : hour + 12;
  }
}

function getHourLabel(hour: number): { hour: number; period: string } {
  if (hour < 0 || hour > 24) {
    throw new Error("Hour must be between 0 and 24");
  }

  if (hour === 0 || hour === 24) {
    return {
      hour: 12,
      period: "AM",
    };
  }

  const period = hour >= 12 ? "PM" : "AM";
  const hourIn12HrFormat = hour % 12 === 0 ? 12 : hour % 12;

  return {
    hour: hourIn12HrFormat,
    period,
  };
}

const OperatingHoursComponent = ({
  operatingHours,
  setOperatingHours,
}: {
  operatingHours: number[];
  setOperatingHours: (value: number[]) => void;
}) => {
  const [allDay, setAllDay] = useState<boolean>(false);
  const startingHour = getHourLabel(operatingHours[0]);
  const endingHour = getHourLabel(operatingHours[1]);

  return (
    <>
      <Typography variant="h2">Fleet Characteristics</Typography>
      <Stack
        spacing={2}
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack>
          <Typography sx={{ width: "100%" }}>
            What are the typical operating hours of this fleet?
          </Typography>
          <Typography sx={{ width: "100%" }}>(Midnight is at 12 AM)</Typography>
        </Stack>
        <Stack spacing={2} direction={"row"} sx={{ width: "60%" }}>
          <FormControlLabel
            sx={{ width: "30%" }}
            control={<Checkbox />}
            label="All Day"
            checked={allDay}
            onChange={(e, checked) => {
              if (checked) {
                setOperatingHours([0, 24]);
              }
              setAllDay(checked);
            }}
          />
          <HourInputField
            disabled={allDay}
            label="Starting Hour"
            hour={startingHour.hour}
            setHour={(val) =>
              setOperatingHours([get24HourFormat(val), operatingHours[1]])
            }
            period={startingHour.period}
          />
          <HourInputField
            disabled={allDay}
            label={"Ending Hour"}
            hour={endingHour.hour}
            setHour={(val) =>
              setOperatingHours([operatingHours[0], get24HourFormat(val)])
            }
            period={endingHour.period}
          />
        </Stack>
      </Stack>
    </>
  );
};

const PassengerComponent = ({
  fleetScenario,
  fleetError,
  handleRemovePassengerVehicle,
  setFleetScenario,
  handleAddPassengerVehicle,
  fleetDefaultVehicles,
  setFleetError,
}: {
  fleetScenario: FleetScenarioForm;
  fleetError: FleetError;
  fleetDefaultVehicles: FleetDefaultVehicle[];
  setFleetScenario: (fleetScenario: FleetScenarioForm) => void;
  handleRemovePassengerVehicle: (id: string) => void;
  handleAddPassengerVehicle: () => void;
  setFleetError: (fleetError: FleetError) => void;
}) => {
  return (
    <>
      <Typography variant="h3">Fleet Mix</Typography>
      <Stack sx={{ marginTop: 2 }} spacing={2}>
        <Stack spacing={2}>
          <Typography variant="h4">Passenger Vehicles</Typography>
          {fleetScenario.fleetMix.passengerVehicles.vehicles.map(
            (vehicle, index) => (
              <FleetMixVehicleTypeConfiguration
                key={vehicle.type}
                vehicle={vehicle}
                vehicleTypeOptions={PASSENGER_VEHICLES}
                onRemove={() => handleRemovePassengerVehicle(vehicle.id)}
                onChange={(vehicle) => {
                  const newFleetMix = { ...fleetScenario.fleetMix };
                  newFleetMix.passengerVehicles.vehicles[index] = vehicle;
                  setFleetScenario({ ...fleetScenario, fleetMix: newFleetMix });
                }}
                fleetDefaultVehicles={fleetDefaultVehicles}
                fleetChargers={fleetScenario.chargerValues}
                fleetError={fleetError}
                setFleetError={setFleetError}
              />
            )
          )}
          <Stack direction="row" alignItems="center">
            <IconButton onClick={handleAddPassengerVehicle}>
              <AddCircleOutlineIcon color="primary" />
            </IconButton>
            <Button sx={{ height: "30px" }} onClick={handleAddPassengerVehicle}>
              Add a vehicle type
            </Button>
          </Stack>
          {fleetError.vehicleError && (
            <Stack>
              <Typography
                sx={{ marginTop: -1 }}
                variant="body1"
                style={{ color: "#d32f2f" }}
              >
                Please add at least one vehicle before continuing.
              </Typography>
            </Stack>
          )}
        </Stack>
        <OperatingHoursComponent
          operatingHours={
            fleetScenario.fleetMix.passengerVehicles.operatingHours
          }
          setOperatingHours={(value) => {
            const newFleetMix = { ...fleetScenario.fleetMix };
            newFleetMix.passengerVehicles.operatingHours = value;
            setFleetScenario({ ...fleetScenario, fleetMix: newFleetMix });
          }}
        />
      </Stack>
    </>
  );
};

const TruckVehicleComponent = ({
  fleetScenario,
  fleetError,
  handleRemoveTruckVehicle,
  setFleetScenario,
  handleAddTruckVehicle,
  fleetDefaultVehicles,
  setFleetError,
}: {
  fleetScenario: FleetScenarioForm;
  fleetError: FleetError;
  fleetDefaultVehicles: FleetDefaultVehicle[];
  setFleetScenario: (fleetScenario: FleetScenarioForm) => void;
  handleRemoveTruckVehicle: (id: string) => void;
  handleAddTruckVehicle: () => void;
  setFleetError: (fleetError: FleetError) => void;
}) => {
  return (
    <>
      <Typography variant="h3">Fleet Mix</Typography>
      <Stack sx={{ marginTop: 2 }} spacing={2}>
        <Stack spacing={2}>
          <Typography variant="h4">Truck Vehicles</Typography>
          {fleetScenario.fleetMix.truckVehicles.vehicles.map(
            (vehicle, index) => (
              <FleetMixVehicleTypeConfiguration
                key={vehicle.type}
                vehicle={vehicle}
                vehicleTypeOptions={TRUCK_VEHICLES}
                onRemove={() => handleRemoveTruckVehicle(vehicle.id)}
                onChange={(vehicle) => {
                  const newFleetMix = { ...fleetScenario.fleetMix };
                  newFleetMix.truckVehicles.vehicles[index] = vehicle;
                  setFleetScenario({ ...fleetScenario, fleetMix: newFleetMix });
                }}
                fleetDefaultVehicles={fleetDefaultVehicles}
                fleetChargers={fleetScenario.chargerValues}
                fleetError={fleetError}
                setFleetError={setFleetError}
              />
            )
          )}
          <Stack direction="row" alignItems="center">
            <IconButton onClick={handleAddTruckVehicle}>
              <AddCircleOutlineIcon color="primary" />
            </IconButton>
            <Button sx={{ height: "30px" }} onClick={handleAddTruckVehicle}>
              Add a vehicle type
            </Button>
          </Stack>
          {fleetError.vehicleError && (
            <Stack>
              <Typography
                sx={{ marginTop: -1 }}
                variant="body1"
                style={{ color: "#d32f2f" }}
              >
                Please add at least one vehicle before continuing.
              </Typography>
            </Stack>
          )}
        </Stack>
        <OperatingHoursComponent
          operatingHours={fleetScenario.fleetMix.truckVehicles.operatingHours}
          setOperatingHours={(value) => {
            const newFleetMix = { ...fleetScenario.fleetMix };
            newFleetMix.truckVehicles.operatingHours = value;
            setFleetScenario({ ...fleetScenario, fleetMix: newFleetMix });
          }}
        />
      </Stack>
    </>
  );
};

const VocationalVehicleComponent = ({
  fleetScenario,
  fleetError,
  handleRemoveVocationalVehicle,
  setFleetScenario,
  handleAddVocationalVehicle,
  fleetDefaultVehicles,
  setFleetError,
}: {
  fleetScenario: FleetScenarioForm;
  fleetError: FleetError;
  setFleetScenario: (fleetScenario: FleetScenarioForm) => void;
  handleRemoveVocationalVehicle: (id: string) => void;
  handleAddVocationalVehicle: () => void;
  fleetDefaultVehicles: FleetDefaultVehicle[];
  setFleetError: (fleetError: FleetError) => void;
}) => {
  return (
    <>
      <Typography variant="h3">Fleet Mix</Typography>
      <Stack sx={{ marginTop: 2 }} spacing={2}>
        <Stack spacing={2}>
          <Typography variant="h4">Vocational Vehicles</Typography>
          {fleetScenario.fleetMix.vocationalVehicles.vehicles.map(
            (vehicle, index) => (
              <FleetMixVehicleTypeConfiguration
                key={vehicle.type}
                vehicle={vehicle}
                vehicleTypeOptions={VOCATIONAL_VEHICLES}
                onRemove={() => handleRemoveVocationalVehicle(vehicle.id)}
                onChange={(vehicle) => {
                  const newFleetMix = { ...fleetScenario.fleetMix };
                  newFleetMix.vocationalVehicles.vehicles[index] = vehicle;
                  setFleetScenario({ ...fleetScenario, fleetMix: newFleetMix });
                }}
                fleetDefaultVehicles={fleetDefaultVehicles}
                fleetChargers={fleetScenario.chargerValues}
                fleetError={fleetError}
                setFleetError={setFleetError}
              />
            )
          )}
          <Stack direction="row" alignItems="center">
            <IconButton onClick={handleAddVocationalVehicle}>
              <AddCircleOutlineIcon color="primary" />
            </IconButton>
            <Button
              sx={{ height: "30px" }}
              onClick={handleAddVocationalVehicle}
            >
              Add a vehicle type
            </Button>
          </Stack>
          {fleetError.vehicleError && (
            <Stack>
              <Typography
                sx={{ marginTop: -1 }}
                variant="body1"
                style={{ color: "#d32f2f" }}
              >
                Please add at least one vehicle before continuing.
              </Typography>
            </Stack>
          )}
        </Stack>
        <OperatingHoursComponent
          operatingHours={
            fleetScenario.fleetMix.vocationalVehicles.operatingHours
          }
          setOperatingHours={(value) => {
            const newFleetMix = { ...fleetScenario.fleetMix };
            newFleetMix.vocationalVehicles.operatingHours = value;
            setFleetScenario({ ...fleetScenario, fleetMix: newFleetMix });
          }}
        />
      </Stack>
    </>
  );
};

const TellUsAboutComponent = ({
  fleetScenario,
  setFleetScenario,
  fleetError,
  setFleetError,
}: {
  fleetScenario: FleetScenarioForm;
  setFleetScenario: (fleetScenario: FleetScenarioForm) => void;
  fleetError: FleetError;
  setFleetError: (fleetError: FleetError) => void;
}) => {
  return (
    <Stack spacing={2} sx={{ marginTop: "0.5em" }}>
      <Stack>
        <Typography variant="h2">Depot Details</Typography>
        <Stack direction="row" useFlexGap flexWrap="wrap" alignItems="center">
          <Typography variant="h4" minWidth="60%" sx={{ marginTop: "10px" }}>
            Where is your fleet going to charge?
          </Typography>
          <FormGroup sx={{ minWidth: "40%" }} row>
            <FormControlLabel
              sx={{ minWidth: "40%" }}
              control={<Checkbox />}
              label="At Facility"
              checked={fleetScenario.chargingLocation.includes("At Facility")}
              onChange={(e, checked) => {
                if (!checked) {
                  setFleetScenario({
                    ...fleetScenario,
                    chargingLocation: fleetScenario.chargingLocation.filter(
                      (cs) => cs !== "At Facility"
                    ),
                  });
                } else {
                  setFleetScenario({
                    ...fleetScenario,
                    chargingLocation: [
                      ...fleetScenario.chargingLocation,
                      "At Facility",
                    ],
                  });
                }
              }}
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Off-site"
              checked={fleetScenario.chargingLocation.includes("Off-site")}
              onChange={(e, checked) => {
                if (!checked) {
                  setFleetScenario({
                    ...fleetScenario,
                    chargingLocation: fleetScenario.chargingLocation.filter(
                      (cs) => cs !== "Off-site"
                    ),
                  });
                } else {
                  setFleetScenario({
                    ...fleetScenario,
                    chargingLocation: [
                      ...fleetScenario.chargingLocation,
                      "Off-site",
                    ],
                  });
                }
              }}
            />
          </FormGroup>
        </Stack>
        <Stack direction="row" useFlexGap flexWrap="wrap" alignItems="center">
          <Typography variant="h4" minWidth="60%" sx={{ marginTop: "10px" }}>
            Is your fleet owned or leased?
          </Typography>
          <RadioGroup
            value={fleetScenario.ownership}
            defaultValue={"Owned"}
            onChange={(e, value) =>
              setFleetScenario({ ...fleetScenario, ownership: value })
            }
            sx={{ minWidth: "40%" }}
            row
          >
            <FormControlLabel
              value="Owned"
              sx={{ minWidth: "40%" }}
              control={<Radio />}
              label="Owned"
            />
            <FormControlLabel
              value="Leased"
              control={<Radio />}
              label="Leased"
            />
          </RadioGroup>
        </Stack>
        <Stack direction="row" useFlexGap flexWrap="wrap" alignItems="center">
          <Typography variant="h4" minWidth="60%" sx={{ marginTop: "10px" }}>
            Do you have multiple depot locations?
          </Typography>
          <RadioGroup
            value={fleetScenario.multipleLocation}
            defaultValue={"true"}
            onChange={(e, value) =>
              setFleetScenario({ ...fleetScenario, multipleLocation: value })
            }
            sx={{ minWidth: "40%" }}
            row
          >
            <FormControlLabel
              value="true"
              sx={{ minWidth: "40%" }}
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
        </Stack>
        <Stack direction="row" useFlexGap flexWrap="wrap" alignItems="center">
          <Typography variant="h4" minWidth="60%" sx={{ marginTop: "10px" }}>
            What best describes your common daily routes?
          </Typography>
          <RadioGroup
            value={fleetScenario.depotRoute}
            defaultValue={"recurring"}
            onChange={(e, value) =>
              setFleetScenario({ ...fleetScenario, depotRoute: value })
            }
            sx={{ minWidth: "40%" }}
            row
          >
            <FormControlLabel
              value="recurring"
              sx={{ minWidth: "40%" }}
              control={<Radio />}
              label="Recurring"
            />
            <FormControlLabel
              value="uncommon"
              control={<Radio />}
              label="Uncommon"
            />
          </RadioGroup>
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h2">Attitude towards EVs</Typography>
        <Stack>
          <Typography variant="h4">
            What statement best describes your company?
          </Typography>
          <RadioGroup
            value={fleetScenario.attitude}
            defaultValue={"1"}
            onChange={(e, value) =>
              setFleetScenario({ ...fleetScenario, attitude: value })
            }
          >
            <FormControlLabel
              value="1"
              control={<Radio />}
              label={describeCompany[1]}
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              label={describeCompany[2]}
            />
            <FormControlLabel
              value="3"
              control={<Radio />}
              label={describeCompany[3]}
            />
          </RadioGroup>
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h2">Scope</Typography>
        <Stack
          spacing={2}
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <FormControl error={fleetError.scopeError}>
            <FormLabel>Suggest Purchases For</FormLabel>
            <Stack direction={"row"} spacing={4}>
              <FormControlLabel
                control={<Checkbox />}
                label="Vehicles"
                sx={{ marginRight: "-0.2em" }}
                checked={fleetScenario.scope.includes("Vehicles")}
                onChange={(e, checked) => {
                  setFleetError({
                    ...fleetError,
                    scopeError: false,
                    isError: false,
                  });
                  if (!checked) {
                    setFleetScenario({
                      ...fleetScenario,
                      scope: fleetScenario.scope.filter(
                        (sc) => sc !== "Vehicles"
                      ),
                    });
                  } else {
                    setFleetScenario({
                      ...fleetScenario,
                      scope: [...fleetScenario.scope, "Vehicles"],
                    });
                  }
                }}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Chargers/EVSE"
                sx={{ marginRight: "-0.2em" }}
                checked={fleetScenario.scope.includes("Chargers/EVSE")}
                onChange={(e, checked) => {
                  setFleetError({
                    ...fleetError,
                    scopeError: false,
                    isError: false,
                  });
                  if (!checked) {
                    setFleetScenario({
                      ...fleetScenario,
                      scope: fleetScenario.scope.filter(
                        (sc) => sc !== "Chargers/EVSE"
                      ),
                    });
                  } else {
                    setFleetScenario({
                      ...fleetScenario,
                      scope: [...fleetScenario.scope, "Chargers/EVSE"],
                    });
                  }
                }}
              />
            </Stack>
          </FormControl>
        </Stack>
        {fleetError.scopeError && (
          <Stack>
            <Typography
              sx={{ marginTop: -2 }}
              variant="body1"
              style={{ color: "#d32f2f" }}
            >
              Please add at least one purchase scope to continue.
            </Typography>
          </Stack>
        )}
      </Stack>
      {fleetError.isError && fleetError.message && (
        <Stack>
          <Typography
            sx={{ marginTop: -1 }}
            variant="body1"
            style={{ color: "#d32f2f" }}
          >
            {fleetError.message}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export function BasicDialog({
  DialogProps,
  onSubmit,
  fleetScenarios,
  setSelectedScenarioId,
  fleetDefaultVehicles,
  fleetChargers,
  setReferenceScenarioId,
}: BasicDialogProps) {
  const { getToken } = useAccessToken();
  const [fleetScenario, setFleetScenario] = useState<FleetScenarioForm>({
    name: "",
    planningHorizon: 12,
    application: "city",
    maxNumChargers: 32,
    attitude: "1",
    ownership: "Owned",
    multipleLocation: "true",
    depotRoute: "recurring",
    fleetMix: {
      passengerVehicles: {
        operatingHours: [9, 17],
        vehicles: [],
      },
      truckVehicles: {
        operatingHours: [9, 17],
        vehicles: [],
      },
      vocationalVehicles: {
        operatingHours: [9, 17],
        vehicles: [],
      },
    },
    costOfICE: 4,
    annualEVMaintenance: 3,
    annualICEMaintenance: 5,
    averageEVDemandCharge: 2.38,
    avergeEVEnergyCharge: 0.05,
    scope: [],
    chargingLocation: ["At Facility"],
    chargerValues: getChargerLevels(fleetChargers),
    chargingStrategy: "spread-over",
  });

  const [loading, setLoading] = useState(false);

  const [fleetError, setFleetError] = useState<FleetError>({
    scenarioName: false,
    planningHorizon: false,
    costOfICE: false,
    annualICEMaintenance: false,
    annualEVMaintenance: false,
    avergeEVEnergyCharge: false,
    averageEVDemandCharge: false,
    isError: true,
    vehicleError: false,
    vehicleClassError: false,
    scopeError: false,
    chargerError: false,
    message: "",
  });

  const [step, setStep] = useState<number>(1);
  const [stepper, setStepper] = useState<string[]>(["basic", "last"]);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const delayOnSubmit = async () => {
    if (fleetScenario.scope.length === 0) {
      setFleetError({ ...fleetError, scopeError: true, isError: true });
      return;
    }
    const apiToken = await getToken();

    const vehicles: FleetScenarioVehicleInput[] = [];
    const chargers: FleetScenarioChargerLevelInput[] = [];
    if (stepper.includes("passenger")) {
      for (const veh of fleetScenario.fleetMix.passengerVehicles.vehicles) {
        vehicles.push({
          fleet_type: "PASSENGER",
          vehicle_type: veh.type,
          num_electric_vehicles: veh.numEv,
          num_ice_vehicles: veh.numIce,
          total_annual_mileage: veh.annualMileage,
          operatingHours:
            fleetScenario.fleetMix.passengerVehicles.operatingHours,
          unitCostPerEV:
            veh.unitCostPerEV !== 0 ? veh.unitCostPerEV : undefined,
          unitCostPerICE:
            veh.unitCostPerICE !== 0 ? veh.unitCostPerICE : undefined,
        });
      }
    }
    if (stepper.includes("truck")) {
      for (const veh of fleetScenario.fleetMix.truckVehicles.vehicles) {
        vehicles.push({
          fleet_type: "TRUCK",
          vehicle_type: veh.type,
          num_electric_vehicles: veh.numEv,
          num_ice_vehicles: veh.numIce,
          total_annual_mileage: veh.annualMileage,
          operatingHours: fleetScenario.fleetMix.truckVehicles.operatingHours,
          unitCostPerEV:
            veh.unitCostPerEV !== 0 ? veh.unitCostPerEV : undefined,
          unitCostPerICE:
            veh.unitCostPerICE !== 0 ? veh.unitCostPerICE : undefined,
        });
      }
    }
    if (stepper.includes("vocational")) {
      for (const veh of fleetScenario.fleetMix.vocationalVehicles.vehicles) {
        vehicles.push({
          fleet_type: "VOCATIONAL",
          vehicle_type: veh.type,
          num_electric_vehicles: veh.numEv,
          num_ice_vehicles: veh.numIce,
          total_annual_mileage: veh.annualMileage,
          operatingHours:
            fleetScenario.fleetMix.vocationalVehicles.operatingHours,
          unitCostPerEV:
            veh.unitCostPerEV !== 0 ? veh.unitCostPerEV : undefined,
          unitCostPerICE:
            veh.unitCostPerICE !== 0 ? veh.unitCostPerICE : undefined,
        });
      }
    }
    for (const charger of fleetScenario.chargerValues) {
      if (charger.checked) {
        chargers.push({
          capacity: charger.capacity,
          charger_type: charger.charger_type,
        });
      }
    }

    const newFleetScenario: FleetScenarioInput = {
      name: fleetScenario.name,
      application: fleetScenario.application,
      max_no_of_chargers: fleetScenario.maxNumChargers,
      fuel_cost: fleetScenario.costOfICE,
      annual_ev_maintenance: fleetScenario.annualEVMaintenance,
      annual_ice_maintenance: fleetScenario.annualICEMaintenance,
      avg_ev_demand_charge: fleetScenario.averageEVDemandCharge,
      avg_ev_energy_charge: fleetScenario.avergeEVEnergyCharge,
      attitude:
        describeCompany[fleetScenario.attitude as keyof typeof describeCompany],
      charge_location: fleetScenario.chargingLocation,
      multiple_locations:
        fleetScenario.multipleLocation === "true" ? true : false,
      ownership_status: fleetScenario.ownership,
      scope: fleetScenario.scope,
      planning_horizon: fleetScenario.planningHorizon,
      fleetChargerLevels: chargers,
      fleetVehicles: vehicles,
      depot_route: fleetScenario.depotRoute,
      chargingStrategy: fleetScenario.chargingStrategy,
    };

    try {
      const createdScenarios = await createFleetScenario(
        newFleetScenario,
        apiToken
      );
      setReferenceScenarioId(createdScenarios[0].id);
      setSelectedScenarioId(createdScenarios[createdScenarios.length - 1].id);
    } catch (error) {
      setSnackbarMessage("Error while creating the fleet scenario.");
      setFleetError({
        ...fleetError,
        isError: true,
        message: error instanceof Error ? error.message : String(error),
      });
      setShowSnackbar(true);
      return;
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      onSubmit();
    }, 2000);
  };

  const validateVehicles = (vehicles: FleetMixVehicle[]) => {
    for (const vehicle of vehicles) {
      if (!vehicle.type) {
        vehicle.typeError = true;
        vehicle.errorMessage = "Please select Vehicle Types.";
        return { isValid: false, vehicles };
      }

      if (!vehicle.numIce || vehicle.numIce <= 0) {
        vehicle.numEvError = true;
        vehicle.numIceError = true;
        vehicle.errorMessage =
          "Please Enter Number of ICE vehicles to be replaced.";
        return { isValid: false, vehicles };
      }

      if (!vehicle.annualMileage) {
        vehicle.annualMileageError = true;
        vehicle.errorMessage = "Please Enter Total Annual Mileage.";
        return { isValid: false, vehicles };
      }
    }
    return { isValid: true, vehicles };
  };

  const handleNext = () => {
    // Validate scenario values
    const validateScenarioFields = () => {
      const scenarioFields = [
        { field: fleetScenario.name, errorKey: "scenarioName", isString: true },
        {
          field: fleetScenario.planningHorizon,
          errorKey: "planningHorizon",
          isString: false,
        },
        {
          field: fleetScenario.costOfICE,
          errorKey: "costOfICE",
          isString: false,
        },
        {
          field: fleetScenario.annualICEMaintenance,
          errorKey: "annualICEMaintenance",
          isString: false,
        },
        {
          field: fleetScenario.annualEVMaintenance,
          errorKey: "annualEVMaintenance",
          isString: false,
        },
        {
          field: fleetScenario.avergeEVEnergyCharge,
          errorKey: "avergeEVEnergyCharge",
          isString: false,
        },
        {
          field: fleetScenario.averageEVDemandCharge,
          errorKey: "averageEVDemandCharge",
          isString: false,
        },
      ];

      for (const { field, errorKey, isString } of scenarioFields) {
        if (isString) {
          // Check for empty strings
          if (!field || typeof field !== "string" || field.trim() === "") {
            setFleetError({ ...fleetError, [errorKey]: true, isError: true });
            return false;
          }
        } else {
          // Check for invalid numbers
          if (typeof field !== "number" || field < 0) {
            setFleetError({ ...fleetError, [errorKey]: true, isError: true });
            return false;
          }
        }
      }
      return true;
    };

    const vehicleTypeMap: { [key: string]: keyof FleetMix } = {
      passenger: "passengerVehicles",
      truck: "truckVehicles",
      vocational: "vocationalVehicles",
    };
    // Validate vehicles for a specific vehicle type
    const validateVehicleType = (vehicleType: string) => {
      const fleetMixKey = vehicleTypeMap[vehicleType];

      const vehicles = fleetScenario.fleetMix[fleetMixKey]?.vehicles || [];

      if (vehicles.length === 0) {
        setFleetError({ ...fleetError, vehicleError: true, isError: true });
        return false;
      }

      const { isValid, vehicles: validatedVehicles } =
        validateVehicles(vehicles);
      if (!isValid) {
        setFleetScenario({
          ...fleetScenario,
          fleetMix: {
            ...fleetScenario.fleetMix,
            [fleetMixKey]: {
              operatingHours:
                fleetScenario.fleetMix[fleetMixKey].operatingHours,
              vehicles: validatedVehicles,
            },
          },
        });
        return false;
      }

      return true;
    };

    // Step 1 validations
    if (step === 1) {
      if (!validateScenarioFields()) return;
    }

    const vehicleTypes = ["passenger", "truck", "vocational"];

    if (!vehicleTypes.some((type) => stepper.includes(type))) {
      setFleetError({ ...fleetError, vehicleClassError: true, isError: true });
      return;
    }

    // Vehicle type validations based on the current step
    const currentVehicleType = stepper[step - 1];

    if (vehicleTypes.includes(currentVehicleType)) {
      if (!validateVehicleType(currentVehicleType)) return;
    }

    if (!fleetScenario.chargerValues.some((charger) => charger.checked)) {
      setFleetError({ ...fleetError, chargerError: true });
      return;
    }

    setStep(step + 1);
  };

  const handleAddPassengerVehicle = () => {
    setFleetError({ ...fleetError, vehicleError: false, isError: false });
    const fleetMix = { ...fleetScenario.fleetMix };
    fleetMix.passengerVehicles.vehicles = [
      ...fleetScenario.fleetMix.passengerVehicles.vehicles,
      {
        id: uuidv4(),
        type: "",
        numEv: 0,
        numIce: 0,
        annualMileage: 0,
        typeError: false,
        numEvError: false,
        numIceError: false,
        annualMileageError: false,
        errorMessage: "",
        unitCostPerEV: 0,
        unitCostPerICE: 0,
      },
    ];

    setFleetScenario({
      ...fleetScenario,
      fleetMix: fleetMix,
    });
  };

  const handleRemovePassengerVehicle = (id?: string) => {
    const fleetMix = { ...fleetScenario.fleetMix };
    fleetMix.passengerVehicles.vehicles =
      fleetMix.passengerVehicles.vehicles.filter(
        (vehicle) => vehicle.id !== id
      );

    setFleetScenario({
      ...fleetScenario,
      fleetMix: fleetMix,
    });
  };

  const handleAddTruckVehicle = () => {
    setFleetError({ ...fleetError, vehicleError: false, isError: false });
    const fleetMix = { ...fleetScenario.fleetMix };
    fleetMix.truckVehicles.vehicles = [
      ...fleetScenario.fleetMix.truckVehicles.vehicles,
      {
        id: uuidv4(),
        type: "",
        numEv: 0,
        numIce: 0,
        annualMileage: 0,
        typeError: false,
        numEvError: false,
        numIceError: false,
        annualMileageError: false,
        errorMessage: "",
        unitCostPerEV: 0,
        unitCostPerICE: 0,
      },
    ];

    setFleetScenario({
      ...fleetScenario,
      fleetMix: fleetMix,
    });
  };

  const handleRemoveTruckVehicle = (id?: string) => {
    const fleetMix = { ...fleetScenario.fleetMix };
    fleetMix.truckVehicles.vehicles = fleetMix.truckVehicles.vehicles.filter(
      (vehicle) => vehicle.id !== id
    );

    setFleetScenario({
      ...fleetScenario,
      fleetMix: fleetMix,
    });
  };

  const handleAddVocationalVehicle = () => {
    setFleetError({ ...fleetError, vehicleError: false, isError: false });
    const fleetMix = { ...fleetScenario.fleetMix };
    fleetMix.vocationalVehicles.vehicles = [
      ...fleetScenario.fleetMix.vocationalVehicles.vehicles,
      {
        id: uuidv4(),
        type: "",
        numEv: 0,
        numIce: 0,
        annualMileage: 0,
        typeError: false,
        numEvError: false,
        numIceError: false,
        annualMileageError: false,
        errorMessage: "",
        unitCostPerEV: 0,
        unitCostPerICE: 0,
      },
    ];

    setFleetScenario({
      ...fleetScenario,
      fleetMix: fleetMix,
    });
  };

  const handleRemoveVocationalVehicle = (id?: string) => {
    const fleetMix = { ...fleetScenario.fleetMix };
    fleetMix.vocationalVehicles.vehicles =
      fleetMix.vocationalVehicles.vehicles.filter(
        (vehicle) => vehicle.id !== id
      );

    setFleetScenario({
      ...fleetScenario,
      fleetMix: fleetMix,
    });
  };

  const renderStep = () => {
    switch (stepper[step - 1]) {
      case "basic":
        return (
          <BasicFleetScenario
            setFleetError={setFleetError}
            setFleetScenario={setFleetScenario}
            fleetError={fleetError}
            fleetScenario={fleetScenario}
            fleetScenarios={fleetScenarios}
            stepper={stepper}
            setStepper={setStepper}
            fleetChargers={fleetChargers}
          />
        );
      case "passenger":
        return (
          <PassengerComponent
            fleetScenario={fleetScenario}
            fleetError={fleetError}
            setFleetScenario={setFleetScenario}
            handleAddPassengerVehicle={handleAddPassengerVehicle}
            handleRemovePassengerVehicle={handleRemovePassengerVehicle}
            fleetDefaultVehicles={fleetDefaultVehicles}
            setFleetError={setFleetError}
          />
        );
      case "truck":
        return (
          <TruckVehicleComponent
            fleetScenario={fleetScenario}
            fleetError={fleetError}
            setFleetScenario={setFleetScenario}
            handleAddTruckVehicle={handleAddTruckVehicle}
            handleRemoveTruckVehicle={handleRemoveTruckVehicle}
            fleetDefaultVehicles={fleetDefaultVehicles}
            setFleetError={setFleetError}
          />
        );
      case "vocational":
        return (
          <VocationalVehicleComponent
            fleetScenario={fleetScenario}
            fleetError={fleetError}
            setFleetScenario={setFleetScenario}
            handleAddVocationalVehicle={handleAddVocationalVehicle}
            handleRemoveVocationalVehicle={handleRemoveVocationalVehicle}
            fleetDefaultVehicles={fleetDefaultVehicles}
            setFleetError={setFleetError}
          />
        );
      case "last":
        return (
          <TellUsAboutComponent
            fleetScenario={fleetScenario}
            setFleetError={setFleetError}
            setFleetScenario={setFleetScenario}
            fleetError={fleetError}
          />
        );
      default:
        return null;
    }
  };

  const hasTeslaSemi = fleetScenario.fleetMix.truckVehicles.vehicles.some(
    (vehicle) => vehicle.type?.includes("Tesla Semi")
  );
  const hasError = fleetScenario.application === "off-road" && hasTeslaSemi;

  return (
    <Dialog {...DialogProps} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Stack direction="row" justifyContent={"space-between"}>
          <Typography variant="h2">
            {step === 1 ? "Create a New Scenario" : fleetScenario.name}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>{renderStep()}</DialogContent>
      <DialogActions sx={{ marginTop: 3 }}>
        {hasError && (
          <Alert severity={"error"} sx={{ marginRight: "8px" }}>
            Application "Off-road" and "Tesla Semi" are not compatible. Please
            choose another application or vehicle type.
          </Alert>
        )}

        <Button
          variant="outlined"
          disabled={step === 1}
          onClick={() => {
            setFleetError({
              ...fleetError,
              vehicleError: false,
              isError: false,
            });
            setStep(step - 1);
          }}
        >
          Back
        </Button>
        {step !== stepper.length ? (
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={handleNext}
            disabled={fleetError.isError}
          >
            Next
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="contained"
            onClick={delayOnSubmit}
            loading={loading}
            disabled={hasError || fleetError.isError}
          >
            Submit
          </LoadingButton>
        )}
      </DialogActions>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert severity="error">{snackbarMessage}</Alert>
      </Snackbar>
    </Dialog>
  );
}
