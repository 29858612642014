import { ContentCopy } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { fetchGrowthScenarioDetails } from "api/timeline/fetch-growth-scenario-query";
import { useState } from "react";
import { GrowthScenario } from "types/growth-scenario";
import { useAccessToken } from "utils/get-access-token";

type UploadGrowthScenarioProps = {
  impExpPopup: boolean;
  growthScenarios: GrowthScenario[];
  setImpExpPopup: (data: boolean) => void;
};

const UploadGrowthScenario = ({
  impExpPopup,
  growthScenarios,
  setImpExpPopup,
}: UploadGrowthScenarioProps) => {
  const { getToken } = useAccessToken();
  const [query, setQuery] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedGrowthScenario, setSelectedGrowthScenario] =
    useState<string>("");

  const handleCopy = () => {
    navigator.clipboard.writeText(query).catch((err) => {
      console.error("Failed to copy text: ", err);
    });
  };

  const getGrowthScenarioDetails = async () => {
    setLoading(true);
    try {
      const apiToken = await getToken();
      const result = await fetchGrowthScenarioDetails(
        apiToken,
        selectedGrowthScenario
      );
      setQuery(result.message);
      setLoading(false);
    } catch (error) {
      console.log("Error:", error);
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={impExpPopup}
      onClose={() => setImpExpPopup(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <IconButton
        aria-label="close"
        onClick={() => setImpExpPopup(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <GridCloseIcon />
      </IconButton>
      <DialogTitle id="alert-dialog-title">Import Export Scenarios</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          spacing={2}
        >
          <Grid item md={6}>
            <FormControl
              className="growth-scenario-control"
              sx={{ width: "100%" }}
            >
              <InputLabel id="demo-simple-select-label">
                Growth Scenario
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedGrowthScenario}
                label="Growth Scenario"
                onChange={(event) =>
                  setSelectedGrowthScenario(event.target.value)
                }
              >
                {growthScenarios?.map((growthScenario) => {
                  return (
                    <MenuItem value={growthScenario.id} key={growthScenario.id}>
                      {growthScenario.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => getGrowthScenarioDetails()}
            >
              {isLoading ? "Loading..." : "Get Details"}
            </Button>
          </Grid>
        </Grid>
        <Box
          marginTop={2}
          padding={2}
          bgcolor="#f5f5f5"
          borderRadius={1}
          border={1}
          borderColor="#ddd"
        >
          <Grid
            container
            direction={"column"}
            alignItems={"self-end"}
            spacing={2}
          >
            <Grid item>
              <IconButton onClick={handleCopy}>
                <ContentCopy />
              </IconButton>
            </Grid>
            <Grid item>
              <Box
                sx={{ height: query ? "350px" : "auto", overflowY: "scroll" }}
              >
                <Typography
                  component="pre"
                  variant="body1"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {query}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UploadGrowthScenario;
