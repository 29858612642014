import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";

import { Section } from "components/Section";
import { TerminalScenarioVehicle } from "types/terminal-scenario-vehicle";
import AssumptionsPanel from "./components/AssumptionsPanel";
import ParametersPanel from "./components/ParametersPanel";
import ShiftScheduleChart from "./charts/ShiftScheduleChart";
import ShiftScheduleInformation from "./components/ShiftScheduleInformation";
import { AssumptionParameters } from "types/terminal-financial";
import UtilityInformation from "./components/UtilityInformation";
import AllowedChargingHoursChart from "./charts/AllowedChargingHoursChart";
import { UtilityRateStructure } from "types/terminal-scenario";

type ParametersProps = {
  terminalScenarioVehicle: TerminalScenarioVehicle | null;
  financialAssumptions: AssumptionParameters | undefined;
  baseVehicleEngineType: string;
  utilityInfo: UtilityRateStructure | undefined;
};

const Parameters = ({
  terminalScenarioVehicle,
  financialAssumptions,
  baseVehicleEngineType,
  utilityInfo,
}: ParametersProps) => {
  return (
    <Box sx={{ padding: 2 }}>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <Section>
            <Box sx={{ marginBottom: 3 }}>
              <Typography variant="controlTitle" sx={{ fontWeight: 600 }}>
                Shift Schedule
              </Typography>
            </Box>
            <ShiftScheduleChart
              shiftSchedule={terminalScenarioVehicle?.shiftSchedule30}
            />
          </Section>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <Section>
            <Box sx={{ marginBottom: 3 }}>
              <Typography variant="controlTitle" sx={{ fontWeight: 600 }}>
                Allowed Charging Hours
              </Typography>
            </Box>
            <AllowedChargingHoursChart
              allowedChargingHours={
                terminalScenarioVehicle?.allowedChargingHours30
              }
            />
          </Section>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <ShiftScheduleInformation
            shiftSchedule={terminalScenarioVehicle?.shiftSchedule30}
            vehiclePerShift={terminalScenarioVehicle?.vehiclesPerShift}
          />
          <AssumptionsPanel
            financialAssumptions={financialAssumptions}
            baseVehicleEngineType={baseVehicleEngineType}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <ParametersPanel
            financialAssumptions={financialAssumptions}
            terminalScenarioVehicle={terminalScenarioVehicle}
            baseVehicleEngineType={baseVehicleEngineType}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
        <UtilityInformation utilityInfo={utilityInfo} />
      </Grid>
    </Box>
  );
};

export default Parameters;
