export const getDepotCategoryName = (labelSource: string) => {
  const lowerLabelSource = labelSource.toLowerCase();

  if (lowerLabelSource.includes("fmcsa")) {
    return "Registered Longhaul Fleets";
  }

  switch (lowerLabelSource) {
    case "wdt":
      return "Warehouse, Trucking and Distribution Center";
    case "highway-corridor":
      return "Highway Corridor";
    case "municipal-fleets":
      return "Municipal Fleets";
    case "non-wdt rush":
    case "non-wdt general":
    case "non_wdt_1":
    case "non_wdt_2":
    case "non_wdt_3":
    case "other":
      return "Others";
    default:
      return labelSource;
  }
};
