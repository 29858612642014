export const fetchGrowthScenarioDetails = async (
  apiToken: string,
  id: string
) => {
  if (!id) return;
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/growth-scenarios/export/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch depots.");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return Promise.reject(error); // Return the error for handling
  }
};
