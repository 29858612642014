export type FleetMix = {
  passengerVehicles: {
    operatingHours: number[];
    vehicles: FleetMixVehicle[];
  };
  truckVehicles: {
    operatingHours: number[];
    vehicles: FleetMixVehicle[];
  };
  vocationalVehicles: {
    operatingHours: number[];
    vehicles: FleetMixVehicle[];
  };
};

export type FleetMixVehicle = {
  id: string;
  type?: VehicleType | string;
  numEv: number;
  numIce: number;
  annualMileage?: number;
  typeError: boolean;
  numEvError: boolean;
  numIceError: boolean;
  annualMileageError: boolean;
  errorMessage: string;
  unitCostPerEV?: number;
  unitCostPerICE?: number;
};

export type VehicleType =
  | "Sedan"
  | "SUV"
  | "Pickup"
  | "Van"
  | "Minivan"
  | "Box Truck (150 mile range)"
  | "Box Truck (200 mile range)"
  | "Box Truck (230 mile range)"
  | "Tractor Trailer (250 mile range)"
  | "Tractor Trailer (300 mile range)"
  | "Tractor Trailer (500 mile range)"
  | "Transit Bus"
  | "School Bus"
  | "Refuse Truck"
  | "Street Sweeper"
  | "Dump Truck"
  | "Fire Truck"
  | "Ambulance"
  | "Police Car";

export const PASSENGER_VEHICLES: VehicleType[] = [
  "Sedan",
  "SUV",
  "Pickup",
  "Van",
  "Minivan",
];

export const TRUCK_VEHICLES: VehicleType[] = [
  "Box Truck (150 mile range)",
  "Box Truck (200 mile range)",
  "Box Truck (230 mile range)",
  "Tractor Trailer (250 mile range)",
  "Tractor Trailer (300 mile range)",
  "Tractor Trailer (500 mile range)",
];

export const VOCATIONAL_VEHICLES: VehicleType[] = [
  "Transit Bus",
  "School Bus",
  "Refuse Truck",
  "Street Sweeper",
  "Dump Truck",
  "Fire Truck",
  "Ambulance",
  "Police Car",
];

interface RiskAssessment {
  assessment: string;
  recommend: string;
}

export const riskAssessment: { [key: string]: RiskAssessment } = {
  HIGH: {
    assessment:
      "Transitioning to EV at this time may bring implementation complexity and/or high costs.",
    recommend:
      "Downsizing electrification plans to specific routes or vehicles that have a higher potential ROI or simpler operational complexity.",
  },
  LOW: {
    assessment:
      "You can realize the advantages of fleet electrification cost effectively with relatively few challenges.",
    recommend:
      "Contact your utility about participation in their fleet electrification incentive programs.",
  },
  MEDIUM: {
    assessment:
      "You may want to re-assess portions of your plan to optimize your EV potential.",
    recommend: "Obtaining a detailed electrification optimization plan.",
  },
  NO_EV: {
    assessment: "You have selected a non-EV Scenario",
    recommend: "N/A",
  },
};
