import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Stack,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";

type FilterControlsDialogProps = {
  open: boolean;
  onClose: () => void;
  props: any;
  activeFilters: string[];
  siteTypes: string[];
  handleFilterChange: (filter: string) => () => void;
  selectedSiteType: string | undefined;
  handleSiteTypeChange: (event: SelectChangeEvent) => void;
};

export default function FilterControlsDialog({
  open,
  onClose,
  props,
  activeFilters,
  siteTypes,
  handleFilterChange,
  selectedSiteType,
  handleSiteTypeChange,
}: FilterControlsDialogProps) {
  // TODO:
  // - Have proximity filters (nearby-amenities, nearby-residential, nearby-highway) work correctly
  // - Should filter what sites are displayed on the map and in the rankings table
  // - Have site type filter work correctly
  // - Add default "all" option to site type filter
  // - Selecting a site type should filter the sites displayed on the map and in the rankings table
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        <GridCloseIcon />
      </IconButton>
      <DialogContent sx={{ padding: 5, paddingBottom: 3 }}>
        <Box className={"filter-controls"}>
          <Stack spacing={1} className={"filter-controls"}>
            <Typography variant="h3" align="center">
              Filter Controls
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={activeFilters.includes("nearby-amenities")}
                  onChange={handleFilterChange("nearby-amenities")}
                  //name="block-groups"
                />
              }
              label="Nearby Amenities"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={activeFilters.includes("nearby-residential")}
                  onChange={handleFilterChange("nearby-residential")}
                  //name="block-groups"
                />
              }
              label="Nearby Residential"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={activeFilters.includes("nearby-highway")}
                  onChange={handleFilterChange("nearby-highway")}
                />
              }
              label="Nearby Highway"
            />
            <Typography variant="h3" align="center">
              Site Type
            </Typography>
            <FormControl className="site-type-filter">
              <InputLabel id="demo-simple-select-label">Site Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedSiteType ?? "All"}
                label="Site Type"
                onChange={handleSiteTypeChange}
              >
                <MenuItem value={"All"}>All</MenuItem>
                {siteTypes.map((siteType) => (
                  <MenuItem value={siteType} key={siteType}>
                    {siteType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
