import { UtilityRateStructure } from "types/terminal-scenario";

export const SAMPLE_UTILITY_RATE: UtilityRateStructure = {
  id: 0,
  name: "",
  fixedMonthlyFees: 0,
  ppaRatio: 0,
  generationEnergyChargeKwh: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  transmissionEnergyChargeKwh: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  distributionEnergyChargeKwh: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  othersEnergyChargeKwh: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  generationDemandChargeKw: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  transmissionDemandChargeKw: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  distributionDemandChargeKw: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  othersDemandChargeKwh: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  ppaGenerationEnergyChargeKwh: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  ppaTransmissionEnergyChargeKwh: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  ppaDistributionEnergyChargeKwh: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  ppaOthersEnergyChargeKwh: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  ppaGenerationDemandChargeKw: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  ppaTransmissionDemandChargeKw: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  ppaDistributionDemandChargeKw: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  ppaOthersDemandChargeKwh: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  isDefault: false,
};

export const PG_AND_E_UTILITY_RATE: UtilityRateStructure = {
  id: 0,
  name: "",
  fixedMonthlyFees: 0,
  ppaRatio: 0,
  generationEnergyChargeKwh: [
    0.1278, 0.1278, 0.1278, 0.1278, 0.1278, 0.1278, 0.1278, 0.1278, 0.1278,
    0.1278, 0.10241, 0.10241, 0.10241, 0.10241, 0.1278, 0.1278, 0.31036,
    0.31036, 0.31036, 0.31036, 0.31036, 0.31036, 0.1278, 0.1278,
  ],
  transmissionEnergyChargeKwh: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  distributionEnergyChargeKwh: [
    0.00542, 0.00542, 0.00542, 0.00542, 0.00542, 0.00542, 0.00542, 0.00542,
    0.00542, 0.00542, 0.00415, 0.00415, 0.00415, 0.00415, 0.00542, 0.00542,
    0.01487, 0.01487, 0.01487, 0.01487, 0.01487, 0.01487, 0.00542, 0.00542,
  ],
  othersEnergyChargeKwh: [
    0.07517, 0.07517, 0.07517, 0.07517, 0.07517, 0.07517, 0.07517, 0.07517,
    0.07517, 0.07517, 0.07517, 0.07517, 0.07517, 0.07517, 0.07517, 0.07517,
    0.07517, 0.07517, 0.07517, 0.07517, 0.07517, 0.07517, 0.07517, 0.07517,
  ],
  generationDemandChargeKw: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  transmissionDemandChargeKw: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  distributionDemandChargeKw: [
    1.24, 1.24, 1.24, 1.24, 1.24, 1.24, 1.24, 1.24, 1.24, 1.24, 1.24, 1.24,
    1.24, 1.24, 1.24, 1.24, 1.24, 1.24, 1.24, 1.24, 1.24, 1.24, 1.24, 1.24,
  ],
  othersDemandChargeKwh: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  ppaGenerationEnergyChargeKwh: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  ppaTransmissionEnergyChargeKwh: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  ppaDistributionEnergyChargeKwh: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  ppaOthersEnergyChargeKwh: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  ppaGenerationDemandChargeKw: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  ppaTransmissionDemandChargeKw: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  ppaDistributionDemandChargeKw: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  ppaOthersDemandChargeKwh: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  isDefault: false,
};
