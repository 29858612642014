import { useEffect, useState } from "react";
import { FleetCharger } from "types/fleets";
import { useAccessToken } from "utils/get-access-token";

type UseFleetChargerData = {
  fleetChargersData: FleetCharger[];
  loadingFleetChargersData: boolean;
  errorLoadingFleetChargersData: Error | null;
  refetch: () => void;
};
export function useFleetChargerData(): UseFleetChargerData {
  const { getToken } = useAccessToken();
  const [fleetChargers, setFleetChargers] = useState<FleetCharger[]>([]);
  const [loadingFleetChargersData, setLoadingFleetChargersData] =
    useState<boolean>(false);
  const [errorLoadingFleetChargersData, setErrorLoadingFleetChargersData] =
    useState<Error | null>(null);

  const fetchData = async () => {
    const apiToken = await getToken();
    setLoadingFleetChargersData(true);
    setErrorLoadingFleetChargersData(null);

    try {
      const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/fleets/chargers`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const result: any = await response.json();
        setFleetChargers(result);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorLoadingFleetChargersData(error);
      }
    }

    setLoadingFleetChargersData(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const refetch = () => {
    fetchData();
  };

  return {
    fleetChargersData: fleetChargers,
    loadingFleetChargersData,
    errorLoadingFleetChargersData,
    refetch,
  };
}
