import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import TcoBarChart, { TcoLegend } from "./charts/TcoBarChart";
import { useEffect, useMemo, useState } from "react";
import { FleetScenario } from "types/fleets";

function getTotalCost(scenario: FleetScenario) {
  scenario.labor_cost = 0;
  return (
    scenario.total_fuel_cost +
    scenario.insurance_cost +
    scenario.downtime_cost +
    scenario.maintenance_cost +
    scenario.other_cost +
    scenario.labor_cost +
    scenario.fleetVehicles.reduce((acc, vehicle) => {
      return acc + vehicle.vehicle_costs;
    }, 0) +
    scenario.fleetChargerLevels.reduce((acc, charger) => {
      return acc + charger.charger_cost;
    }, 0)
  );
}

export type TotalCostOfOwnershipProps = {
  scenario?: FleetScenario;
  referenceScenarios: FleetScenario[] | undefined;
  referenceScenarioId?: number;
};

function TotalCostOfOwnership({
  scenario,
  referenceScenarios,
  referenceScenarioId,
}: TotalCostOfOwnershipProps) {
  const [selectedReferenceScenarioId, setSelectedReferenceScenarioId] =
    useState<number | undefined>(referenceScenarioId);

  useEffect(() => {
    if (
      referenceScenarioId &&
      referenceScenarios &&
      referenceScenarios.length > 0
    ) {
      setSelectedReferenceScenarioId(referenceScenarioId);
    }
  }, [referenceScenarioId, referenceScenarios]);

  const referenceScenario = referenceScenarios?.find(
    (scenario) => scenario.id === selectedReferenceScenarioId
  );

  const isSameScenario = useMemo(() => {
    const getBaseName = (name?: string) => name?.split(" - ")[0];
    return getBaseName(scenario?.name) === getBaseName(referenceScenario?.name);
  }, [scenario, referenceScenario]);

  let suggestedMax = 0;
  let differenceInCost = 0;

  if (isSameScenario && scenario && referenceScenario) {
    const [evScenario, iceScenario] = scenario.name.includes("EV")
      ? [scenario, referenceScenario]
      : [referenceScenario, scenario];

    const evCost = getTotalCost(evScenario);
    const iceCost = getTotalCost(iceScenario);

    suggestedMax = Math.max(evCost, iceCost);
    differenceInCost = Math.max(0, evCost - iceCost);
  }

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="controlTitle" sx={{ fontWeight: "bold" }}>
          Total Cost of Ownership{" "}
          <Typography component="span">
            ({scenario?.planning_horizon || 10} year amortization)
          </Typography>
        </Typography>
      </Box>

      {referenceScenarios && (
        <FormControl fullWidth>
          <InputLabel id="reference-scenario-select-label">
            Select Reference Scenario
          </InputLabel>
          <Select
            labelId="reference-scenario-select-label"
            value={referenceScenario ? referenceScenario.id : ""}
            onChange={(e) =>
              setSelectedReferenceScenarioId(
                e.target.value === "" ? undefined : (e.target.value as number)
              )
            }
            label="Select Reference Scenario"
          >
            <MenuItem value={""}>None</MenuItem>
            {referenceScenarios.map((reference) => {
              if (reference.id !== scenario?.id) {
                return (
                  <MenuItem key={reference.id} value={reference.id}>
                    {reference.name}
                  </MenuItem>
                );
              }
              return null; // Return null if the condition is not met
            })}
          </Select>
        </FormControl>
      )}

      <Stack>
        <Stack>
          {scenario && (
            <TcoBarChart
              fleetElectificationScenario={scenario}
              disableXaxis={true}
              title={scenario?.name || "This Scenario"}
              suggestedMax={suggestedMax}
            />
          )}
          {selectedReferenceScenarioId !== undefined && referenceScenario && (
            <TcoBarChart
              fleetElectificationScenario={referenceScenario}
              disableXaxis={true}
              title={referenceScenario?.name || "Reference Scenario"}
              suggestedMax={suggestedMax}
            />
          )}
        </Stack>
        <TcoLegend />
        {selectedReferenceScenarioId && isSameScenario && (
          <Typography sx={{ textAlign: "center" }} variant="h6">
            Incentive for breakeven:
            <span>{` $${Math.ceil(differenceInCost).toLocaleString()}`}</span>
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

export default TotalCostOfOwnership;
