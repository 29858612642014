import {
  Alert,
  AlertColor,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Snackbar,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { deleteFleetScenario } from "api/fleets/delete-scenario";
import { useState } from "react";
import { useAccessToken } from "utils/get-access-token";

export interface DeleteDialogContent {
  isOpen: boolean;
  scenarioName: String | undefined;
  scenarioId: Number | undefined;
}

interface DeleteScenarioDialogProps {
  content: DeleteDialogContent;
  refetchData: () => void;
  handleClose: () => void;
}

export default function DeleteScenarioDialog({
  content,
  handleClose,
  refetchData,
}: DeleteScenarioDialogProps) {
  const { getToken } = useAccessToken();
  const [snackbar, setSnackbar] = useState<
    { type: AlertColor; message: string; duration: number } | undefined
  >();
  const handleDeleteScenario = async (scenarioId: Number) => {
    const apiToken = await getToken();
    try {
      await deleteFleetScenario(scenarioId, apiToken);
      refetchData();
      setSnackbar({
        type: "success",
        message: `Fleet Scenario deleted Successfully!`,
        duration: 1000,
      });
    } catch (error: any) {
      setSnackbar({
        type: "error",
        message: error.message,
        duration: 6000,
      });
    }
  };

  const handleSnackbarClose = () => {
    if (snackbar!.type === "success") {
      handleClose();
    }
    setSnackbar(undefined);
  };

  return (
    <>
      {/* Delete Fleet Scenario Popup */}
      <Dialog
        open={content.isOpen}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
        <DialogTitle id="alert-dialog-title">
          {`Do you want to delete fleet scenario (${
            content.scenarioName ?? ""
          })?`}
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              if (content.scenarioId) handleDeleteScenario(content.scenarioId);
            }}
          >
            Confirm
          </Button>
          <Button
            color="inherit"
            sx={{ color: "black" }}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {snackbar && (
        <Snackbar
          open={!!snackbar}
          autoHideDuration={snackbar.duration}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
          onClose={() => handleSnackbarClose()}
        >
          <Alert severity={snackbar.type}>{snackbar.message}</Alert>
        </Snackbar>
      )}
    </>
  );
}
