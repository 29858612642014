export const editDepotInformation = async (
  apiToken: string,
  depotId: string,
  stateAbbr: string,
  depotInformation: any
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/depots/edit/${stateAbbr}/${depotId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(depotInformation),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch depots.");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return Promise.reject(error); // Return the error for handling
  }
};
