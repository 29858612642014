import { Box, Button, DialogContent, Stack, Typography } from "@mui/material";

type SubmissionPageProps = {
  onFinishButtonClick: () => void;
};

export default function SubmissionPage(props: SubmissionPageProps) {
  const { onFinishButtonClick } = props;

  return (
    <>
      <DialogContent>
        <Box
          sx={{
            flexGrow: 1,
            display: "grid",
            placeItems: "center",
            overflowY: "hidden",
          }}
        >
          <Stack spacing={2} alignItems="center">
            <Typography variant="h2">
              Growth Scenario Simulation In Progress.
            </Typography>
            <Typography variant="caption">
              You will receive a notification once the scenario creation is
              complete.
            </Typography>
            <Button variant="contained" onClick={onFinishButtonClick}>
              OKAY
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </>
  );
}
