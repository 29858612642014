const Colors = [
  "#05C2CC",
  "#FDBE02",
  "#7DC422",
  "#DB3AE8",
  "#128B2A",
  "#D3881C",

  // new colors for map
  "#004847",
  "#006262",
  "#017A7C",
  "#62ABAB",
  "#AAD0CF",
  "#0000bb",
];

export default Colors;
