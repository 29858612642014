import { GrowthScenario } from "types/growth-scenario";
import { ChartControlValues } from "./TimelinePage";
import { downloadCsv } from "utils/file";
import {
  getDailyTotalMhdDemandKwhForVehicleClass,
  isLightDutyVehicleClass,
} from "./charts/utils";
import { getLdvTotalDailyDemandFromNumEvs } from "./charts/LdvTotalDailyDemandChart";
import { getNumChargersNeededFromDemandData } from "./charts/NumChargersNeededChart";
import { getChargerInvestmentRequiredFromNumChargersNeeded } from "./charts/ChargerInvestmentRequiredChart";

type DataRow = {
  year: number;
  "Vehicle Class": string;
  "EV Fraction of Sales": number;
  "EV Saturation Fraction": number;
  "Number of EVs": number;
  "Total Daily Charging Demand kWh": number;
  "Daily Home Demand kWh": number;
  "Daily Workplace Demand kWh": number;
  "Daily Public Demand kWh": number;
  "Number of Home L2 Chargers Needed": number;
  "Number of Workplace L2 Chargers Needed": number;
  "Number of Workplace DC Fast Chargers Needed": number;
  "Number of Public DC Fast Chargers Needed": number;
  "Workplace L2 Charger Investment Needed (USD)": number;
  "Workplace DC Fast Charger Investment Needed (USD)": number;
  "Public DC Fast Charger Investment Needed (USD)": number;
  "Number of Remaining ICE Vehicles": number;
};

export function download(
  filename: string,
  growthScenario: GrowthScenario,
  chartControlValues: ChartControlValues
) {
  const formattedData: DataRow[] = [];
  growthScenario?.vehicleClasses.forEach((vehicleClass) => {
    vehicleClass.annualData?.forEach((annualDatum) => {
      let totalDailyChargingDemandKwh = 0;
      let dailyHomeDemandKwh = 0;
      let dailyWorkplaceDemandKwh = 0;
      let dailyPublicDemandKwh = 0;
      let numL2HomeChargersNeeded = 0;
      let numL2WorkplaceChargersNeeded = 0;
      let numDcfcWorkplaceChargersNeeded = 0;
      let numDcfcPublicChargersNeeded = 0;
      let workplaceL2Investment = 0;
      let workplaceDcfcInvestment = 0;
      let publicDcfcInvestment = 0;

      if (isLightDutyVehicleClass(vehicleClass)) {
        const demandData = getLdvTotalDailyDemandFromNumEvs(annualDatum.numEvs);
        const numChargers = getNumChargersNeededFromDemandData(
          demandData,
          chartControlValues.workplaceFractionServedByDcfc
        );
        const chargerInvestment =
          getChargerInvestmentRequiredFromNumChargersNeeded(
            numChargers,
            chartControlValues.pricePerLevel2Charger,
            chartControlValues.pricePerDcFastCharger
          );

        dailyHomeDemandKwh = demandData.dailyHomeDemandKwh;
        dailyWorkplaceDemandKwh = demandData.dailyWorkplaceDemandKwh;
        dailyPublicDemandKwh = demandData.dailyPublicDemandKwh;
        totalDailyChargingDemandKwh =
          dailyHomeDemandKwh + dailyWorkplaceDemandKwh + dailyPublicDemandKwh;

        numL2HomeChargersNeeded = numChargers.numL2HomeChargersNeeded;
        numL2WorkplaceChargersNeeded = numChargers.numL2WorkplaceChargersNeeded;
        numDcfcWorkplaceChargersNeeded =
          numChargers.numDcfcWorkplaceChargersNeeded;
        numDcfcPublicChargersNeeded = numChargers.numDcfcPublicChargersNeeded;

        workplaceL2Investment = chargerInvestment.workplaceL2Investment;
        workplaceDcfcInvestment = chargerInvestment.workplaceDcfcInvestment;
        publicDcfcInvestment = chargerInvestment.publicDcfcInvestment;
      } else {
        totalDailyChargingDemandKwh = getDailyTotalMhdDemandKwhForVehicleClass(
          annualDatum.numEvs,
          vehicleClass.name
        );
      }

      const formatted = {
        year: Number(annualDatum.year.toFixed(2)),
        "Vehicle Class": vehicleClass.name,
        "EV Fraction of Sales": Number(
          annualDatum.evFractionOfSales.toFixed(2)
        ),
        "EV Saturation Fraction": Number(
          annualDatum.evFractionOfAllVehicles.toFixed(2)
        ),
        "Number of EVs": Number(annualDatum.numEvs.toFixed(2)),
        "Total Daily Charging Demand kWh": Number(
          totalDailyChargingDemandKwh.toFixed(2)
        ),
        "Daily Home Demand kWh": Number(dailyHomeDemandKwh.toFixed(2)),
        "Daily Workplace Demand kWh": Number(
          dailyWorkplaceDemandKwh.toFixed(2)
        ),
        "Daily Public Demand kWh": Number(dailyPublicDemandKwh.toFixed(2)),
        "Number of Home L2 Chargers Needed": Number(
          numL2HomeChargersNeeded.toFixed(2)
        ),
        "Number of Workplace L2 Chargers Needed": Number(
          numL2WorkplaceChargersNeeded.toFixed(2)
        ),
        "Number of Workplace DC Fast Chargers Needed": Number(
          numDcfcWorkplaceChargersNeeded.toFixed(2)
        ),
        "Number of Public DC Fast Chargers Needed": Number(
          numDcfcPublicChargersNeeded.toFixed(2)
        ),
        "Workplace L2 Charger Investment Needed (USD)": Number(
          workplaceL2Investment.toFixed(2)
        ),
        "Workplace DC Fast Charger Investment Needed (USD)": Number(
          workplaceDcfcInvestment.toFixed(2)
        ),
        "Public DC Fast Charger Investment Needed (USD)": Number(
          publicDcfcInvestment.toFixed(2)
        ),
        "Number of Remaining ICE Vehicles": Number(
          annualDatum.numOfRemainingIce.toFixed(2)
        ),
        "NOX Emission Per Year (Grams)": Number(
          annualDatum.emissions.nox.toFixed(2)
        ),
        "VOC Emission Per Year (Grams)": Number(
          annualDatum.emissions.voc.toFixed(2)
        ),
        "PM-10 Emission Per Year (Grams)": Number(
          annualDatum.emissions.pm10.toFixed(2)
        ),
        "PM-2.5 Emission Per Year (Grams)": Number(
          annualDatum.emissions.pm25.toFixed(2)
        ),
        "SOX Emission Per Year (Grams)": Number(
          annualDatum.emissions.sox.toFixed(2)
        ),
        "CO2 Emission Per Year (Kg)": Number(
          annualDatum.emissions.co2.toFixed(2)
        ),
      };
      formattedData.push(formatted);
    });
  });
  downloadCsv(formattedData, filename);
}
