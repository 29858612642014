import { useEffect, useState } from "react";
import { LandUseType } from "types/land-use-type";
import { useAccessToken } from "utils/get-access-token";

const useLandUseTypes = (siteId: number) => {
  const { getToken } = useAccessToken();
  const [landUseTypes, setLandUseTypes] = useState<LandUseType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();

  const fetchData = async () => {
    const apiToken = await getToken();
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/hub/sites/${siteId}/land-use-types`,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );
      const data: LandUseType[] = await response.json();

      setLandUseTypes(data);
      setLoading(false);
    } catch (error) {
      setError(error as Error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { landUseTypes, loading, error };
};

export default useLandUseTypes;
