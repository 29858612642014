import { useEffect, useState } from "react";
import { FleetScenario } from "types/fleets";
import { useAccessToken } from "utils/get-access-token";

type UseFleetScenarioData = {
  fleetScenarioData: FleetScenario[];
  loadingFleetScenarioData: boolean;
  errorLoadingFleetScenarioData: Error | null;
  refetch: () => void;
};
export function useFleetScenarioData(): UseFleetScenarioData {
  const { getToken } = useAccessToken();
  const [fleetScenario, setFleetScenario] = useState<FleetScenario[]>([]);
  const [loadingFleetScenarioData, setLoadingFleetScenarioData] =
    useState<boolean>(false);
  const [errorLoadingFleetScenarioData, setErrorLoadingFleetScenarioData] =
    useState<Error | null>(null);

  const fetchData = async () => {
    const apiToken = await getToken();
    setLoadingFleetScenarioData(true);
    setErrorLoadingFleetScenarioData(null);

    try {
      const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/fleets`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const result: FleetScenario[] = await response.json();
        setFleetScenario(result);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorLoadingFleetScenarioData(error);
      }
    }

    setLoadingFleetScenarioData(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const refetch = () => {
    fetchData();
  };

  return {
    fleetScenarioData: fleetScenario,
    loadingFleetScenarioData,
    errorLoadingFleetScenarioData,
    refetch,
  };
}
