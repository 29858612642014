import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { FleetMixVehicle, VehicleType } from "../fleet-mix";
import {
  FleetDefaultVehicle,
  FleetScenarioChargerLevelInput,
} from "types/fleets";
import { useCallback, useMemo } from "react";
import { FleetError } from "./wizard/Dialog";

export type FleetMixVehicleTypeConfigurationProps = {
  vehicle: FleetMixVehicle;
  vehicleTypeOptions: VehicleType[];
  fleetDefaultVehicles: FleetDefaultVehicle[];
  fleetChargers: FleetScenarioChargerLevelInput[];
  onRemove: () => void;
  onChange: (vehicle: FleetMixVehicle) => void;
  fleetError: FleetError;
  setFleetError: (fleetError: FleetError) => void;
};

export function FleetMixVehicleTypeConfiguration({
  vehicle,
  vehicleTypeOptions,
  onRemove,
  onChange,
  fleetDefaultVehicles,
  fleetChargers,
  fleetError,
  setFleetError,
}: FleetMixVehicleTypeConfigurationProps) {
  const getVehicleByType = useCallback(
    (vehicleType: string): FleetDefaultVehicle | undefined => {
      return fleetDefaultVehicles.find(
        (vehicle) => vehicle.vehicleType === vehicleType
      );
    },
    [fleetDefaultVehicles]
  );

  const vehicleError: string | undefined = useMemo(() => {
    if (
      vehicle.type &&
      vehicle.annualMileage &&
      vehicle.numEv &&
      vehicle.numEv > 0
    ) {
      const vehicleType = getVehicleByType(vehicle.type);
      if (!vehicleType) return;
      const possibleDailyMileage =
        vehicleType?.batteryCapacity / vehicleType?.kwhPerMile;
      const possibleAnnualMileage =
        Math.round(Math.floor((possibleDailyMileage * 365) / 100)) * 100;

      if (
        (vehicle.annualMileage / 365) * vehicleType?.kwhPerMile >
        vehicleType?.batteryCapacity
      ) {
        setFleetError({
          ...fleetError,
          isError: true,
        });
        vehicle.annualMileageError = true;
        onChange(vehicle);
        return `Error: Vehicle's daily work needs exceed battery capacity. Maximum possible annual mileage per vehicle: ${Math.floor(
          possibleAnnualMileage
        )} miles.`;
      }
      setFleetError({ ...fleetError, isError: false });
    }
    return undefined;
  }, [vehicle.type, vehicle.annualMileage, vehicle.numEv, getVehicleByType]);

  return (
    <>
      <Stack spacing={2} direction="row" alignItems="center">
        <IconButton onClick={onRemove}>
          <RemoveCircleOutlineIcon color="error" />
        </IconButton>
        <FormControl sx={{ width: "25%" }}>
          <InputLabel id="light-duty-label" size="small">
            Vehicle Type
          </InputLabel>
          <Select
            defaultValue=""
            value={vehicle.type}
            labelId="light-duty-label"
            label="Vehicle Type"
            size="small"
            error={vehicle.typeError}
            onChange={(e) => {
              if (onChange !== undefined) {
                vehicle.typeError = false;
                vehicle.errorMessage = "";
                vehicle.type = e.target.value as VehicleType;
                const vehicleType = getVehicleByType(vehicle.type);
                vehicle.unitCostPerEV = vehicleType?.unitCostPerEV;
                vehicle.unitCostPerICE = vehicleType?.unitCostPerICE;
                onChange(vehicle);
              }
            }}
          >
            {vehicleTypeOptions.map((vehicleType) => (
              <MenuItem value={vehicleType}>{vehicleType}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label={"Number of ICE vehicles to be replaced"}
          type="number"
          inputProps={{ min: 0 }}
          error={vehicle.numIceError}
          value={vehicle.numIce.toString()}
          sx={{ width: "35%" }}
          onKeyDown={(event) => {
            if (event.key === "-" || event.key === ".") {
              event.preventDefault();
            }
          }}
          size="small"
          onChange={(e) => {
            const numberOfICE = parseInt(e.target.value);
            if (onChange !== undefined) {
              vehicle.numEvError = false;
              vehicle.numIceError = false;
              vehicle.errorMessage = "";
              vehicle.numIce = numberOfICE;
              vehicle.numEv = numberOfICE;
              onChange(vehicle);
            }
          }}
        />
        <TextField
          type="number"
          inputProps={{ min: 0 }}
          label={"Total Annual Mileage (per vehicle)"}
          value={vehicle.annualMileage?.toString()}
          error={vehicle.annualMileageError}
          sx={{ width: "32%" }}
          size="small"
          onKeyDown={(event) => {
            if (event.key === "-" || event.key === ".") {
              event.preventDefault();
            }
          }}
          onChange={(e) => {
            const mileage = parseInt(e.target.value);
            if (onChange !== undefined) {
              vehicle.annualMileageError = false;
              vehicle.errorMessage = "";
              vehicle.annualMileage = mileage;
              onChange(vehicle);
            }
          }}
        />
        <TextField
          type="number"
          inputProps={{ min: 0, max: 10000000 }}
          label={"EV Price (unit)"}
          value={vehicle.unitCostPerEV?.toString()}
          sx={{ width: "20%" }}
          size="small"
          onKeyDown={(event) => {
            if (event.key === "-" || event.key === ".") {
              event.preventDefault();
            }
          }}
          onChange={(e) => {
            if (onChange !== undefined) {
              const price = parseInt(e.target.value);
              if (price > 10000000 || price < 0) {
                return;
              }
              vehicle.errorMessage = "";
              vehicle.unitCostPerEV = price;
              onChange(vehicle);
            }
          }}
        />
        <TextField
          type="number"
          inputProps={{ min: 0, max: 10000000 }}
          label={"ICE Vehicle Price (unit)"}
          value={vehicle.unitCostPerICE?.toString()}
          sx={{ width: "22%" }}
          size="small"
          onKeyDown={(event) => {
            if (event.key === "-" || event.key === ".") {
              event.preventDefault();
            }
          }}
          onChange={(e) => {
            if (onChange !== undefined) {
              const price = parseInt(e.target.value);
              if (price > 10000000 || price < 0) {
                return;
              }
              vehicle.errorMessage = "";
              vehicle.unitCostPerICE = price;
              onChange(vehicle);
            }
          }}
        />
      </Stack>
      {vehicle.errorMessage && (
        <Stack>
          <Typography
            sx={{ marginLeft: 1, marginTop: -1 }}
            variant="caption"
            style={{ color: "#d32f2f" }}
          >
            {vehicle.errorMessage}
          </Typography>
        </Stack>
      )}
      {vehicleError && fleetError.isError && (
        <Stack>
          <Typography
            sx={{ marginLeft: 1, marginTop: -1 }}
            variant="caption"
            style={{ color: "#d32f2f" }}
          >
            {vehicleError}
          </Typography>
        </Stack>
      )}
    </>
  );
}
