import { downloadFile } from "../../utils/file";
import { TerminalDownloadData } from "types/terminal-download";
import { FinancialControls, FinancialData } from "types/terminal-financial";
import { UtilityRateStructure } from "types/terminal-scenario";
import { TerminalScenarioVehicle } from "types/terminal-scenario-vehicle";
import { convertToDateTimeFormat, generateTimeLabels } from "utils/time";

const formatCost = (cost: number) => `"$${Math.round(cost).toLocaleString()}"`;

export const TOOLTIP_DETAILS = {
  fleetSize: {
    title: "Fleet Size",
    description:
      "Vehicles on shift are assumed to charge immediately after their shift ends. The fleet size is determined based on their charging behavior, downtime, and other factors.",
  },
  vehicleSelection: {
    title: "Vehicle Selection",
    description:
      "Select vehicles with the largest available battery capacity and highest charging rate.",
  },
  chargingInfrastructure: {
    title: "Charging Infrastructure",
    description:
      "The number of chargers will match the number of vehicles required on shift.",
  },
  chargingStrategy: {
    title: "Charging Strategy",
    description:
      "Vehicles will be charged immediately after each shift ends, without considering utility rates or peak demand periods.",
  },
};

const UTILITY_RATE_DETAILS = {
  utilityEnergyGeneration: {
    title: "Utility-Energy-generation ($/kWh)",
    description: "Cost of producing the electricity.",
  },
  utilityEnergyTransmission: {
    title: "Utility-Energy-transmission ($/kWh)",
    description:
      "Cost of transporting electricity from power plants to local substations.",
  },
  utilityEnergyDistribution: {
    title: "Utility-Energy-distribution ($/kWh)",
    description:
      "Cost of delivering electricity from substations to homes and businesses.",
  },
  utilityEnergyOthers: {
    title: "Utility-Energy-others ($/kWh)",
    description:
      "Other activities and services related to energy usage not covered by generation, transmission, and distribution (i.e. vegetation management).",
  },
  utilityDemandGeneration: {
    title: "Utility-Demand-generation ($/kW)",
    description:
      "Cost to maintain electric generation capacity which is determined by a customer's peak demand.",
  },
  utilityDemandTransmission: {
    title: "Utility-Demand-transmission ($/kW)",
    description:
      "Cost to maintain transmission of electricity from power plants to substations determined by a customer's peak demand.",
  },
  utilityDemandDistribution: {
    title: "Utility-Demand-distribution ($/kW)",
    description:
      "Cost to maintain distribution of electricity from substations to end-users (homes, businesses) determined by a customer's peak demand.",
  },
  utilityDemandOthers: {
    title: "Utility-Demand-others ($/kW)",
    description:
      "Set based on max hourly usage over a designated period of time, or max usage during a peak window of the day. May also include nuclear decommissioning components in certain utility territories.",
  },
  ppa: {
    title: "PPA",
    description:
      "An arrangement in which a third-party developer installs, owns, and operates an energy system on a customer's property. PPAs facilitate the financing of distributed generation assets such as photovoltaic, microturbines, reciprocating engines, and fuel cells.",
  },
  fixedMonthlyRates: {
    title: "Fixed-Monthly-Rates",
    description:
      "The price at which a property owner (customer) buys electricity produced by a renewable energy project for a set period of time. The price can be fixed or escalated, meaning it increases over time. The contract can also specify a pre-defined amount of electricity or a portion of the seller's total generation. The price per kilowatt-hour can be fixed or fluctuate based on market rates.",
  },
  ppaRates: {
    title: "PPA-Generation/Distribution/Transmission ($/kWh)",
    description:
      "Cost to generate, transport and deliver electricity from wherever the PPA generation assets are located (on or off-site) to the end-user (customer).",
  },
};

export const downloadConfigurationCSV = (
  terminalScenarioVehicle: TerminalScenarioVehicle,
  financialControls: FinancialControls,
  financialData: FinancialData
) => {
  let csvData = "";
  const appendData = (data: string = "") => {
    csvData += data;
    csvData += "\n";
  };

  const configurationDownloadData = {
    Terminal: terminalScenarioVehicle.scenario.property.name,
    "Cost Center": terminalScenarioVehicle.scenario.facility.name,
    "Configuration Name": terminalScenarioVehicle.scenario.name,
    "Vehicle Type": terminalScenarioVehicle.vehicleType.name,
    "ICE Vehicle Fuel Consumption (diesel-gallons per hour)":
      terminalScenarioVehicle.iceFuelConsumption,
    "Existing ICE Fleet Size": terminalScenarioVehicle.numICEVehicles,
    "Vehicles Per Shift": terminalScenarioVehicle.vehiclesPerShift,
    "ICE Reference Vehicle": terminalScenarioVehicle.iceVehicle?.name,
    "ICE Vehicle Downtime (%)": terminalScenarioVehicle.iceDowntimePct * 100,
    "EV Expected Downtime (%)": terminalScenarioVehicle.evDowntimePct * 100,
    "Vehicle Replacement (Years)":
      terminalScenarioVehicle.vehicleReplacementLifecycleYears,
    "Vehicle Maintenance (%)":
      terminalScenarioVehicle.vehicleMaintenanceCostPct * 100,
    "Charger Replacement (Years)":
      terminalScenarioVehicle.scenario.chargerReplacementLifecycleYears,
    "Charger Maintenance (%)":
      terminalScenarioVehicle.scenario.chargerMaintenanceCostPct * 100,
    "Battery Replacement (Years)":
      terminalScenarioVehicle.batteryReplacementLifecycleYears,
    "Optimal EV Count": terminalScenarioVehicle.fleetSize,
    "Vehicle Name": terminalScenarioVehicle.evVehicle?.name,
    "Vehicle Make": terminalScenarioVehicle.evVehicle?.make,
    "Vehicle Model": terminalScenarioVehicle.evVehicle?.model,
    "Battery Capacity (kWh)":
      terminalScenarioVehicle.evVehicle?.batteryCapacityKwh,
    "Battery Max Charge Rate (kW)":
      terminalScenarioVehicle.evVehicle?.batteryMaxChargeRateKw,
    "Current Charger Count":
      terminalScenarioVehicle.scenario.numExistingChargers,
    "Optimal Charger Count": terminalScenarioVehicle.scenario.optNumChargers,
    "Charger Name": terminalScenarioVehicle.scenario.charger?.name,
    "Charger Make": terminalScenarioVehicle.scenario.charger?.make,
    "Charger Model": terminalScenarioVehicle.scenario.charger?.model,
    "Charge Rate (kW)": terminalScenarioVehicle.scenario.charger?.chargeRateKw,
    Voltage: terminalScenarioVehicle.scenario.charger?.voltage,
    Amperage: terminalScenarioVehicle.scenario.charger?.amperage,
    "Planning Horizon Years": financialControls.planningHorizonYears,
    "Fuel Cost Growth Rate (%)": financialControls.fuelCostGrowthRate * 100,
    "Power Purchase Agreement (%)": financialControls.ppaRate * 100,
    "Charger Cost ($ Per Charger)": financialControls.chargerCost,
    "Vehicle Cost ($ Per Vehicle)": financialControls.vehicleCost,
    "Installation Cost ($ Per Charger)": financialControls.installationCost,
    "Total Optimized Capital Expenses ($)":
      financialData.financialInformation.totalCapitalExpenses
        .totalCapitalExpenses,
    "Total Optimized Operational Expense ($)":
      financialData.financialInformation.totalOperationalExpenses
        .totalOperationalExpenses,
    "Total Annual Energy Usage (kWh)":
      financialData.maxEnergyDemand.energyUsageKwh,
    "Max Annual Hourly Peak Power Demand (kW)":
      financialData.maxEnergyDemand.peakDemandKw,
  };

  appendData(Object.keys(configurationDownloadData).join(","));
  appendData(Object.values(configurationDownloadData).join(","));

  downloadFile(csvData, "configuration.csv");
};

const TERMINAL_DOWNLOAD_HEADERS = [
  "Terminal",
  "Cost Center",
  "Configuration Name",
  "Vehicle Type",
  "Utility Rate Structure",
  "ICE Fleet Size",
  "Vehicles Per Shift",
  "Shift Start Hour",
  "Shift End Hour",
  "Optimal EV Count",
  "Vehicle Name",
  "Vehicle Make",
  "Vehicle Model",
  "Vehicle Battery Capacity (kWh)",
  "Vehicle Battery Max Charge Rate (kW)",
  "Current Charger Count",
  "Optimal Charger Count",
  "Charger Name",
  "Charger Make",
  "Charger Model",
  "Charger Charge Rate (kW)",
  "Charger Voltage",
  "Charger Amperage",
  "Planning Start Year",
  "Planning Horizon Years",
  "Fuel Cost Growth Rate (%)",
  "Discount Rate (%)",
  "Investment Strategy",
  "Power Purchase Agreement (%)",
  "Vehicle Price ($ Per Unit)",
  "Charger Price ($ Per Unit)",
  "Installation Cost ($)",
  "Total Optimized Capital Expenses ($)",
  "Total Optimized Operational Expense ($)",
  "Total Annual Energy Usage (kWh)",
  "Max Annual Hourly Peak Power Demand (kW)",
];

export const downloadTerminalScenarios = async (
  scenarioIds: number[],
  apiToken: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}:${
        process.env.REACT_APP_API_PORT
      }/terminals/scenarios/download?ids=${scenarioIds.join(",")}`,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );

    if (response.ok) {
      const result: TerminalDownloadData[] = await response.json();
      let csvData = "";
      const appendData = (data: string = "") => {
        csvData += data;
        csvData += "\n";
      };

      appendData(TERMINAL_DOWNLOAD_HEADERS.join(","));

      const energyData: string[] = [];
      const energyHeaders = ["Electrical Parameters", ...generateTimeLabels()];
      energyData.push(energyHeaders.join(","));

      result.forEach((downloadData) => {
        const data = [
          downloadData.terminalName,
          downloadData.facilityName,
          downloadData.scenarioName,
          downloadData.vehicleType,
          downloadData.utilityRateStructure,
          downloadData.numICEVehicles,
          downloadData.vehiclesPerShift,
          downloadData.shiftSchedule.indexOf(1),
          downloadData.shiftSchedule.lastIndexOf(1) + 1,
          downloadData.optNumEvs,
          downloadData.evVehicle.name,
          downloadData.evVehicle.make,
          downloadData.evVehicle.model,
          downloadData.evVehicle.batteryCapacityKwh,
          downloadData.evVehicle.batteryMaxChargeRateKw,
          downloadData.numExistingChargers,
          downloadData.optNumChargers,
          downloadData.charger.name,
          downloadData.charger.make,
          downloadData.charger.model,
          downloadData.charger.chargeRateKw,
          downloadData.charger.voltage,
          downloadData.charger.amperage,
          downloadData.financial.financialControls.planningStartYear,
          downloadData.financial.financialControls.planningHorizonYears,
          downloadData.financial.financialControls.fuelCostGrowthRate * 100,
          downloadData.financial.financialControls.discountRate * 100,
          downloadData.financial.financialControls.investmentStrategy.toUpperCase(),
          downloadData.financial.financialControls.ppaRate * 100,
          downloadData.financial.financialControls.vehicleCost,
          downloadData.financial.financialControls.chargerCost,
          downloadData.financial.financialControls.initInstallationCost,
          downloadData.financial.totalCapitalExpenses,
          downloadData.financial.totalOperationalExpenses,
          downloadData.financial.maxAnnualKwhUsage,
          downloadData.financial.maxAnnualPeakDemand,
        ];

        energyData.push(
          [
            `${downloadData.terminalName} - ${downloadData.scenarioName} - Energy (kWh)`,
            ...downloadData.energyUsageKwhDatapoints,
          ].join(",")
        );

        energyData.push(
          [
            `${downloadData.terminalName} - ${downloadData.scenarioName} - Demand (kW)`,
            ...downloadData.demandUsageKwDatapoints,
          ].join(",")
        );

        appendData(data.join(","));
      });

      appendData();
      appendData(energyData.join("\n"));
      downloadFile(csvData, "terminals.csv");
    } else {
      //Handle error response
      throw new Error("Failed to download terminals");
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(
        `An error occurred while downloading terminals: ${error.message}`
      );
    } else {
      throw new Error("An unknown error occurred while downloading terminals");
    }
  }
};

const FINANCIAL_HEADERS = [
  "Configuration Name",
  "Optimal EV Count",
  "Optimal Charger Count",
  "Vehicle Price ($ Per Unit)",
  "Charger Price ($ Per Unit)",
  "Installation Cost ($ Per Charger)",
  "Vehicle Capital Expense ($)",
  "Charger Capital Expense ($)",
  "Installation Cost ($)",
  "Total Capital Expense ($)",
  "Charging Cost ($)",
  "Maintenance Costs ($)",
  "Total Annual Operational Expense ($)",
  "Net Present Value (NPV)",
];

const FINANCIAL_TABLE_HEADERS = [
  "Year",
  "Discount Rate (%)",
  "Vehicle Capital Expense (Initial + Replacement + Battery Replacement)",
  "Charger Capital Expense (Initial + Replacement)",
  "Installation Capital Expense",
  "Fuel and Maintenance",
  "TCO",
  "NPV",
];
export const downloadFinancialCSV = (
  terminalScenarioVehicle: TerminalScenarioVehicle,
  financialControls: FinancialControls,
  financialData: FinancialData
) => {
  let csvData = "";
  const appendData = (data: string = "") => {
    csvData += data;
    csvData += "\n";
  };

  appendData(FINANCIAL_HEADERS.join(","));

  const installationCostPerCharger =
    financialData.financialInformation.totalCapitalExpenses
      .installationExpenses / terminalScenarioVehicle.scenario.optNumChargers;
  const data = [
    terminalScenarioVehicle.scenario.name, // Configuration Name
    terminalScenarioVehicle.fleetSize, // Optimal EV Count
    terminalScenarioVehicle.scenario.optNumChargers, // Optimal Charger Count
    formatCost(financialData.financialControls.vehicleCost), // Vehicle Price ($ Per Unit)
    formatCost(financialData.financialControls.chargerCost), // Charger Price ($ Per Unit)
    formatCost(installationCostPerCharger), // Installation Cost ($ Per Charger)
    formatCost(
      financialData.financialInformation.totalCapitalExpenses.vehicleExpenses // Vehicle Capital Expense ($)
    ),
    formatCost(
      financialData.financialInformation.totalCapitalExpenses.chargerExpenses // Charger Capital Expense ($)
    ),
    formatCost(
      financialData.financialInformation.totalCapitalExpenses
        .installationExpenses // Installation Cost ($)
    ),
    formatCost(
      financialData.financialInformation.totalCapitalExpenses
        .totalCapitalExpenses // Total Capital Expense ($)
    ),
    formatCost(
      financialData.financialInformation.totalOperationalExpenses.fuelCosts // Charging Cost ($)
    ),
    formatCost(
      financialData.financialInformation.totalOperationalExpenses
        .maintenanceCosts // Maintenance Costs ($)
    ),
    formatCost(
      financialData.financialInformation.totalOperationalExpenses
        .totalOperationalExpenses // Total Annual Operational Expense ($)
    ),
    formatCost(
      financialData.financialInformation.totalCapitalExpenses
        .totalCapitalExpensesNPV +
        financialData.financialInformation.totalOperationalExpenses
          .totalOperationalExpensesNPV // Net Present Value (NPV)
    ),
  ];

  appendData(data.join(","));
  appendData();

  appendData(FINANCIAL_TABLE_HEADERS.join(","));
  for (let i = 0; i < financialControls.planningHorizonYears; i++) {
    const vehicleCost =
      financialData.capitalExpenses.vehicleExpenses[i].total +
      financialData.capitalExpenses.batteryExpenses[i].total;
    const vehicleCostNPV =
      financialData.capitalExpenses.vehicleExpenses[i].totalNPV +
      financialData.capitalExpenses.batteryExpenses[i].totalNPV;

    const chargerCost = financialData.capitalExpenses.chargerExpenses[i].total;
    const chargerCostNPV =
      financialData.capitalExpenses.chargerExpenses[i].totalNPV;

    const installationCost =
      financialData.capitalExpenses.installationExpenses[i].total;
    const installationCostNPV =
      financialData.capitalExpenses.installationExpenses[i].totalNPV;

    const fuelAndMaintenanceCost =
      financialData.operationalExpenses.fuelCosts[i].costUsd +
      financialData.operationalExpenses.maintenanceCosts[i].costUsd;
    const fuelAndMaintenanceCostNPV =
      financialData.operationalExpenses.fuelCosts[i].costNPV +
      financialData.operationalExpenses.maintenanceCosts[i].costNPV;

    const tcoValue =
      vehicleCost + chargerCost + installationCost + fuelAndMaintenanceCost;
    const npv =
      vehicleCostNPV +
      chargerCostNPV +
      installationCostNPV +
      fuelAndMaintenanceCostNPV;

    const rowData = [
      i + 1, // Year
      financialControls.discountRate * 100 + "%", // Discount Rate (%)
      formatCost(vehicleCost), // Vehicle Capital Expense (Initial + Replacement + Battery Replacement)
      formatCost(chargerCost), // Charger Capital Expense (Initial + Replacement)
      formatCost(installationCost), // Installation Capital Expense
      formatCost(fuelAndMaintenanceCost), // Fuel and Maintenance
      formatCost(tcoValue), // TCO
      formatCost(npv), // NPV
    ];
    appendData(rowData.join(","));
  }
  downloadFile(csvData, "financial.csv");
};

export const downloadUtilityRate = (
  utilityRate: UtilityRateStructure,
  filename: string
) => {
  let csvData = "";
  const {
    generationEnergyChargeKwh,
    transmissionEnergyChargeKwh,
    distributionEnergyChargeKwh,
    othersEnergyChargeKwh,
    generationDemandChargeKw,
    transmissionDemandChargeKw,
    distributionDemandChargeKw,
    othersDemandChargeKwh,
    ppaGenerationEnergyChargeKwh,
    ppaTransmissionEnergyChargeKwh,
    ppaDistributionEnergyChargeKwh,
    ppaOthersEnergyChargeKwh,
    ppaGenerationDemandChargeKw,
    ppaTransmissionDemandChargeKw,
    ppaDistributionDemandChargeKw,
    ppaOthersDemandChargeKwh,
    fixedMonthlyFees,
    ppaRatio,
  } = utilityRate;
  const appendData = (data: string = "") => {
    csvData += data;
    csvData += "\n";
  };
  const csvHeaders = ["Hour", ...Array.from({ length: 24 }, (x, i) => i)];
  appendData(csvHeaders.join(","));
  appendData(
    ["Utility-Energy-generation ($/kWh)", ...generationEnergyChargeKwh].join(
      ","
    )
  );
  appendData(
    [
      "Utility-Energy-transmission ($/kWh)",
      ...transmissionEnergyChargeKwh,
    ].join(",")
  );
  appendData(
    [
      "Utility-Energy-distribution ($/kWh)",
      ...distributionEnergyChargeKwh,
    ].join(",")
  );
  appendData(
    ["Utility-Energy-others ($/kWh)", ...othersEnergyChargeKwh].join(",")
  );
  appendData(
    ["Utility-Demand-generation ($/kW)", ...generationDemandChargeKw].join(",")
  );
  appendData(
    ["Utility-Demand-transmission ($/kW)", ...transmissionDemandChargeKw].join(
      ","
    )
  );
  appendData(
    ["Utility-Demand-distribution ($/kW)", ...distributionDemandChargeKw].join(
      ","
    )
  );
  appendData(
    ["Utility-Demand-others ($/kW)", ...othersDemandChargeKwh].join(",")
  );
  appendData(
    ["PPA-Energy-generation ($/kWh)", ...ppaGenerationEnergyChargeKwh].join(",")
  );
  appendData(
    ["PPA-Energy-transmission ($/kWh)", ...ppaTransmissionEnergyChargeKwh].join(
      ","
    )
  );
  appendData(
    ["PPA-Energy-distribution ($/kWh)", ...ppaDistributionEnergyChargeKwh].join(
      ","
    )
  );
  appendData(
    ["PPA-Energy-others ($/kWh)", ...ppaOthersEnergyChargeKwh].join(",")
  );
  appendData(
    ["PPA-Demand-generation ($/kW)", ...ppaGenerationDemandChargeKw].join(",")
  );
  appendData(
    ["PPA-Demand-transmission ($/kW)", ...ppaTransmissionDemandChargeKw].join(
      ","
    )
  );
  appendData(
    ["PPA-Demand-distribution ($/kW)", ...ppaDistributionDemandChargeKw].join(
      ","
    )
  );
  appendData(
    ["PPA-Demand-others ($/kW)", ...ppaOthersDemandChargeKwh].join(",")
  );
  appendData(["Fixed-Monthly-Fees ($)", fixedMonthlyFees].join(","));
  appendData(["PPA-Ratio (%)", ppaRatio * 100].join(","));

  appendData(["", "", "", ""].join("\n"));
  Object.values(UTILITY_RATE_DETAILS).forEach((detail) => {
    appendData(`${detail.title},"${detail.description}"`);
  });

  downloadFile(csvData, filename);
};

export const downloadEvReferenceData = (
  terminalScenarioVehicle: TerminalScenarioVehicle,
  financialData: FinancialData,
  baseVehicleEngineType: string
) => {
  let csvData = "";
  const appendData = (data: string = "") => {
    csvData += data;
    csvData += "\n";
  };

  const createRow = (...values: any[]) => values.join(",");

  const formatRow = (
    title: string,
    evData: number,
    iceData: number,
    optimizedData: number
  ) => {
    return createRow(
      title,
      formatCost(evData),
      formatCost(iceData),
      formatCost(optimizedData)
    );
  };

  const { evOpportunityChargingData, iceOnlyData, evOptimizedChargingData } =
    financialData.costComparisonTableData;

  appendData("Configuration Name");
  appendData(terminalScenarioVehicle.scenario.name);

  appendData();
  appendData();

  appendData(
    createRow(
      "Configuration Type",
      "EV - Reference Case",
      `${baseVehicleEngineType} Only`,
      "EV - Optimized Charging"
    )
  );
  appendData(
    createRow(
      "Vehicle Count (#)",
      evOpportunityChargingData.vehicleCount,
      iceOnlyData.vehicleCount,
      evOptimizedChargingData.vehicleCount
    )
  );
  appendData(
    formatRow(
      "Vehicle Price (USD)",
      evOpportunityChargingData.vehiclePrice,
      iceOnlyData.vehiclePrice,
      evOptimizedChargingData.vehiclePrice
    )
  );
  appendData(
    formatRow(
      "Vehicle Replacement Cost (USD)",
      evOpportunityChargingData.vehicleReplacementCost,
      iceOnlyData.vehicleReplacementCost,
      evOptimizedChargingData.vehicleReplacementCost
    )
  );
  appendData(
    formatRow(
      "Total Vehicle Cost (USD) Over Planning Horizon",
      evOpportunityChargingData.totalVehicleCost,
      iceOnlyData.totalVehicleCost,
      evOptimizedChargingData.totalVehicleCost
    )
  );
  appendData(
    createRow(
      "Charger Count (#)",
      evOpportunityChargingData.chargerCount,
      iceOnlyData.chargerCount,
      evOptimizedChargingData.chargerCount
    )
  );
  appendData(
    formatRow(
      "Charger Price (USD)",
      evOpportunityChargingData.chargerPrice,
      iceOnlyData.chargerPrice,
      evOptimizedChargingData.chargerPrice
    )
  );
  appendData(
    formatRow(
      "Charger Installation Cost (USD)",
      evOpportunityChargingData.installationCost,
      iceOnlyData.installationCost,
      evOptimizedChargingData.installationCost
    )
  );
  appendData(
    formatRow(
      "Charger Replacement Cost (USD)",
      evOpportunityChargingData.chargerReplacementCost,
      iceOnlyData.chargerReplacementCost,
      evOptimizedChargingData.chargerReplacementCost
    )
  );
  appendData(
    formatRow(
      "Total Charger Cost (USD) Over Planning Horizon",
      evOpportunityChargingData.totalChargerCost,
      iceOnlyData.totalChargerCost,
      evOptimizedChargingData.totalChargerCost
    )
  );
  appendData(
    formatRow(
      "Battery Replacement Cost (USD)",
      evOpportunityChargingData.batteryReplacementCost,
      iceOnlyData.batteryReplacementCost,
      evOptimizedChargingData.batteryReplacementCost
    )
  );
  appendData(
    formatRow(
      "Total Capital Expenses (Vehicle + Charger Costs - USD)",
      evOpportunityChargingData.totalCapitalExpenses,
      iceOnlyData.totalCapitalExpenses,
      evOptimizedChargingData.totalCapitalExpenses
    )
  );
  appendData(
    formatRow(
      "Total Operating Expenses (Fuel + Maintenance Costs - USD)",
      evOpportunityChargingData.totalOperationalExpenses,
      iceOnlyData.totalOperationalExpenses,
      evOptimizedChargingData.totalOperationalExpenses
    )
  );
  appendData(
    formatRow(
      "Total Expenses (CapEx + OpEx Across Planning Horizon - USD)",
      evOpportunityChargingData.totalExpenses,
      iceOnlyData.totalExpenses,
      evOptimizedChargingData.totalExpenses
    )
  );
  appendData(
    formatRow(
      "Total NPV Expenses (USD)",
      evOpportunityChargingData.totalExpensesNPV,
      iceOnlyData.totalExpensesNPV,
      evOptimizedChargingData.totalExpensesNPV
    )
  );

  appendData(["", "", ""].join("\n"));
  appendData("EV Reference Case Assumptions");
  Object.values(TOOLTIP_DETAILS).forEach((detail) => {
    appendData(`${detail.title},"${detail.description}"`);
  });

  downloadFile(
    csvData,
    `configuration-comparison-data-${convertToDateTimeFormat(new Date())}.csv`
  );
};
