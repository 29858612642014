import {
  Backdrop,
  Box,
  CircularProgress,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";
import Colors from "utils/colors.js";
import { Map, Layer, NavigationControl, Source, Popup } from "react-map-gl";
import HomeButton from "dashboard/depots/HomeButton";
import GeocoderControl from "dashboard/depots/geocoder-control";
import WidgetContainer from "dashboard/depots/WidgetContainer";
import Widget from "dashboard/depots/Widget";
import SiteLegend from "./SiteLegend";

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

// naming it just `Map` overwrites the JS `Map` object
const HubMap = ({
  sites,
  navigateToScenario,
  siteList,
  loading,
  isTutorial,
  mapData = [],
}) => {
  const mapRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const [anchor, setAnchor] = useState();
  const [displayMode, setDisplayMode] = useState("dark");
  const [popupInfo, setPopupInfo] = useState(undefined);

  const [cursor, setCursor] = useState("auto");

  const mapCenter = useMemo(() => {
    let maxLat = -Infinity,
      minLat = Infinity,
      maxLon = -Infinity,
      minLon = Infinity;
    if (sites.length > 0) {
      sites.forEach((site) => {
        site.lat && (maxLat = Math.max(site.lat, maxLat));
        site.lat && (minLat = Math.min(site.lat, minLat));
        site.lon && (maxLon = Math.max(site.lon, maxLon));
        site.lon && (minLon = Math.min(site.lon, minLon));
      });
      return {
        latitude: (maxLat + minLat) / 2,
        longitude: (maxLon + minLon) / 2,
        zoom: isTutorial ? 2 : 3,
      };
    }
    return {
      latitude: 37.0902,
      longitude: -95.7129,
      zoom: isTutorial ? 2 : 3,
    };
  }, [sites, isTutorial]);

  const [viewport, setViewport] = useState(mapCenter);

  const handleRecenterClick = () => {
    setViewport(mapCenter);
  };

  const onClick = useCallback(
    (event) => {
      const map = mapRef.current?.getMap();
      if (!map) {
        return;
      }
      const { features } = event;

      const hoveredFeature = features && features[0];
      if (hoveredFeature) {
        let siteListArray;
        if (typeof siteList === "object") {
          siteListArray = Object.values(siteList);
        } else {
          siteListArray = siteList;
        }

        navigateToScenario(
          hoveredFeature.properties.id,
          siteListArray.find((site) => site.id === hoveredFeature.properties.id)
            .currentEvGrowthScenarioId
        );
      }
    },
    [navigateToScenario, siteList]
  );

  const onMouseEnter = useCallback((event) => {
    setCursor("pointer");
    const map = mapRef.current?.getMap();
    if (!map) {
      return;
    }
    const {
      features,
      point: { x, y },
      lngLat: { lng, lat },
    } = event;

    const hoveredFeature = features && features[0];
    if (hoveredFeature) {
      let position = "top";
      if (y > 400) {
        position = "bottom";
      }
      setAnchor(position);
      setPopupInfo(
        hoveredFeature && { feature: hoveredFeature, x, y, lng, lat }
      );
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    setPopupInfo(undefined);
    setCursor("auto");
  }, []);

  const handleDisplayModeChange = () => {
    setLoading(true);
    setDisplayMode(displayMode === "light" ? "dark" : "light");
    setLoading(false);
  };

  const sitesGeoJsonData = useMemo(
    () => ({
      type: "FeatureCollection",
      features: sites.map((site, index) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [site.lon, site.lat],
        },
        properties: {
          ...site,
          fleetArrival: mapData.length > 0 ? mapData[index] : undefined,
        },
      })),
    }),
    [sites, mapData]
  );

  const location = useMemo(() => {
    return {
      center: mapCenter,
      zoom: mapCenter.zoom,
    };
  }, [mapCenter]);

  const siteFleetLegend = [
    "Less than 5",
    Colors[10],
    "5 to 10",
    Colors[9],
    "10 to 20",
    Colors[8],
    "20 to 30",
    Colors[7],
    "Greater than 30",
    Colors[6],
  ];

  const siteLegend = [
    "Less than 5K",
    Colors[10],
    "5K to 10K",
    Colors[9],
    "10K to 20K",
    Colors[8],
    "20K to 30K",
    Colors[7],
    "Greater than 30K",
    Colors[6],
  ];

  const siteLayerStyle = {
    id: "sites-layer",
    type: "circle",
    paint: {
      "circle-radius": 10,
      "circle-color": [
        "case",
        ["boolean", ["get", "fleetArrival"], false],
        [
          "case",
          [">", ["get", "fleetArrival"], 30],
          Colors[6],
          [">", ["get", "fleetArrival"], 20],
          Colors[7],
          [">", ["get", "fleetArrival"], 10],
          Colors[8],
          [">", ["get", "fleetArrival"], 5],
          Colors[9],
          Colors[10],
        ],
        ["boolean", ["get", "highway_flow"], false],
        [
          "case",
          [">", ["get", "highway_flow"], 30000],
          Colors[6],
          [">", ["get", "highway_flow"], 20000],
          Colors[7],
          [">", ["get", "highway_flow"], 10000],
          Colors[8],
          [">", ["get", "highway_flow"], 5000],
          Colors[9],
          Colors[10],
        ],
        Colors[10],
      ],
      "circle-stroke-width": 2,
      "circle-stroke-color": "#000000",
    },
  };

  return (
    <Box sx={{ flex: 1, position: "relative" }}>
      <Backdrop
        sx={{
          color: "#FFFFFF",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: "absolute",
        }}
        open={loading || isLoading || false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack
        spacing={2}
        sx={{
          position: "absolute",
          margin: "8px",
          zIndex: "1000",
          top: 0,
          right: 0,
          height: `calc(100% - 20px - 8px * 2)`,
          pointerEvents: "none",
        }}
        alignItems={"flex-end"}
        direction={"column"}
      >
        <Box
          sx={{
            borderRadius: "10px",
            backgroundColor:
              displayMode === "dark"
                ? "hsla(0,0%,100%,.8)"
                : "hsla(0,0%,0%,.8)",
            pointerEvents: "auto",
            paddingRight: "10px",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={displayMode === "dark"}
                onChange={handleDisplayModeChange}
              />
            }
            label="Dark Mode"
            labelPlacement="start"
            componentsProps={{
              typography: {
                color: displayMode === "dark" ? "black" : "white",
              },
            }}
          />
        </Box>
      </Stack>
      <WidgetContainer>
        <Widget sx={{ width: "270px" }}>
          <SiteLegend
            title={
              mapData.length > 0 ? "Total Fleet Size" : "Highway Flow Coverage"
            }
            colors={mapData.length > 0 ? siteFleetLegend : siteLegend}
            units={
              mapData.length > 0 ? "# of Vehicles" : "# of Vehicles or Traffic"
            }
            className={"coverage-legend"}
          />
        </Widget>
      </WidgetContainer>
      <Map
        id="map"
        {...viewport}
        ref={mapRef}
        onMove={(evt) => setViewport(evt.viewState)}
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        cursor={cursor}
        mapStyle={
          displayMode === "dark"
            ? "mapbox://styles/mapbox/dark-v11"
            : "mapbox://styles/mapbox/light-v11"
        }
        interactiveLayerIds={[siteLayerStyle.id || ""]}
      >
        <GeocoderControl
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN ?? ""}
          position="top-left"
        />
        <NavigationControl position={"top-left"} />
        <HomeButton
          // when the location changes, we need to render a new HomeButton
          // because otherwise the listeners do not get updated with the new
          // value of location. The HomeButton's onRemove() will remove the
          // previous element from the map
          key={location?.name}
          onClick={handleRecenterClick}
        />
        <Source id="sites" type="geojson" data={sitesGeoJsonData}>
          <Layer beforeId="waterway-label" {...siteLayerStyle} />
        </Source>
        {popupInfo && (
          <Popup
            style={{ zIndex: 1100 }}
            anchor={anchor}
            longitude={popupInfo.lng}
            latitude={popupInfo.lat}
            onClose={() => setPopupInfo(undefined)}
            maxWidth={"none"}
            closeButton={false}
          >
            <Typography variant="caption">{`Site: ${popupInfo.feature.properties.name}`}</Typography>
          </Popup>
        )}
      </Map>
    </Box>
  );
};

export default HubMap;
