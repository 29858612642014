import { useEffect, useState } from "react";
import { FleetDefaultVehicle } from "types/fleets";
import { useAccessToken } from "utils/get-access-token";

type UseDefaultFleetScenarioData = {
  fleetVehiclesData: FleetDefaultVehicle[];
  loadingFleetVehiclesData: boolean;
  errorLoadingFleetVehiclesData: Error | null;
  refetch: () => void;
};
export function useDefaultFleetScenarioData(): UseDefaultFleetScenarioData {
  const { getToken } = useAccessToken();
  const [fleetVehicles, setFleetVehicles] = useState<FleetDefaultVehicle[]>([]);
  const [loadingFleetVehiclesData, setLoadingFleetVehiclesData] =
    useState<boolean>(false);
  const [errorLoadingFleetVehiclesData, setErrorLoadingFleetVehiclesData] =
    useState<Error | null>(null);

  const fetchData = async () => {
    const apiToken = await getToken();
    setLoadingFleetVehiclesData(true);
    setErrorLoadingFleetVehiclesData(null);

    try {
      const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/fleets/vehicles`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const result: any = await response.json();
        setFleetVehicles(result);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorLoadingFleetVehiclesData(error);
      }
    }

    setLoadingFleetVehiclesData(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const refetch = () => {
    fetchData();
  };

  return {
    fleetVehiclesData: fleetVehicles,
    loadingFleetVehiclesData,
    errorLoadingFleetVehiclesData,
    refetch,
  };
}
