import { useAuth0 } from "@auth0/auth0-react";
import { Delete, Edit } from "@mui/icons-material";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  AlertColor,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  Tab,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { loadDepotDemand } from "api/depot-demand";
import { useDepotFleetByState } from "api/depot-fleet";
import { editDepotInformation } from "api/depot/update-depot-information";
import { disableDepotInformation, useDepotInState } from "api/depots";
import { loadFeederLineDemand } from "api/feeder-line-demand";
import { loadFeederLine } from "api/feeder-lines";
import { Feature } from "geojson";
import { ChangeEvent, useEffect, useState } from "react";
import { DepotDemand } from "types/electric-demand";
import { getDepotCategoryName } from "utils/depot-category";
import { extractFeederNumbers } from "utils/depots";
import ChartContainer from "./ChartContainer";
import DemandChart from "./DemandChart";
import { useAccessToken } from "utils/get-access-token";
import { Location } from "types/location";

type DepotInformation = {
  businessName: string;
  feedback: string;
  environment: string;
  address: string;
  googleLink: string;
  username: string;
};

function DepotProperties({
  depotId,
  year,
  state,
  isTxPPC,
  loading,
  isOnEdit,
  updateDepot,
}: {
  depotId: number;
  year: number;
  state: string;
  isTxPPC: boolean;
  loading: boolean;
  isOnEdit: boolean;
  updateDepot: (data: DepotInformation | undefined) => void;
}) {
  const { user } = useAuth0();
  const { depot } = useDepotInState(depotId, state);

  const [depotInformation, setDepotInformation] = useState<
    DepotInformation | undefined
  >(undefined);

  const googlePlacesLink = depot?.properties?.google_places_list
    ? `https://www.google.com/maps/place/?q=place_id:${
        depot?.properties?.google_places_list?.split(",")[0]
      }`
    : depot?.geometry
    ? `https://www.google.com/maps/place/${(
        depot?.geometry as any
      ).coordinates[1].toString()}, ${(
        depot?.geometry as any
      ).coordinates[0].toString()}`
    : "unknown";

  const { depotFleet } = useDepotFleetByState(depotId, year, state, isTxPPC);

  useEffect(() => {
    setDepotInformation((prev) => ({
      ...prev,
      businessName: depot?.properties?.business_name || "unknown",
      feedback: "",
      environment: process.env.REACT_APP_HOME_URL || "unknown",
      googleLink: googlePlacesLink.replaceAll(" ", "") || "unknown",
      address: depot?.properties?.address || "unknown",
      username: user?.email || "unknown",
    }));
  }, [depot]);

  const totalFleetSize =
    depotFleet?.reduce((accumulator, actFleet) => {
      return accumulator + actFleet.totalVehicles;
    }, 0) ?? 0;

  const totalEvFleetSize =
    depotFleet?.reduce((accumulator, actFleet) => {
      return accumulator + actFleet.totalEvs;
    }, 0) ?? 0;

  // sort fleet data by act category
  depotFleet?.sort((a, b) => {
    if (a.actCategory < b.actCategory) {
      return -1;
    }
    if (a.actCategory > b.actCategory) {
      return 1;
    }
    return 0;
  });

  const handleFormChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setDepotInformation((prevState: DepotInformation | undefined) => ({
      ...prevState!,
      [name]: value,
    }));
  };

  return (
    <>
      {depotInformation?.businessName !== "unknown" && (
        <>
          <ListItem disableGutters divider>
            <ListItemText
              primary="Business Name"
              secondary={
                depot ? (
                  depotInformation?.businessName
                ) : (
                  <Skeleton variant={"text"} />
                )
              }
            />
          </ListItem>
          <Divider component="li" />
        </>
      )}

      {depot?.properties?.owner &&
        depotInformation?.businessName !== depot?.properties?.owner && (
          <>
            <ListItem disableGutters>
              <ListItemText
                primary="Owner"
                secondary={
                  depot ? (
                    depot?.properties?.owner ?? "unknown"
                  ) : (
                    <Skeleton variant={"text"} height={"80px"} />
                  )
                }
              />
            </ListItem>
            <Divider component="li" />
          </>
        )}

      {isTxPPC === false && (
        <ListItem disableGutters>
          <ListItemText
            primary="Total Fleet Size"
            secondary={
              depotFleet ? (
                `${Math.floor(totalFleetSize)}-${Math.ceil(
                  totalFleetSize
                )} vehicles (${totalEvFleetSize.toFixed(0)} EV${
                  totalEvFleetSize > 1 ? "s" : ""
                })`
              ) : (
                <Skeleton variant={"text"} height={"80px"} />
              )
            }
          />
        </ListItem>
      )}

      {depotFleet?.map((actFleet) => (
        <ListItem disableGutters>
          <ListItemText
            inset
            primary={actFleet.vehicleClassName}
            secondary={`${Math.floor(actFleet.totalVehicles)}-${Math.ceil(
              actFleet.totalVehicles
            )} vehicles (${actFleet.totalEvs.toFixed(0)} EV${
              actFleet.totalEvs > 1 ? "s" : ""
            })`}
          />
        </ListItem>
      ))}

      {isTxPPC && depot && depot?.properties?.state === "TXPPC" && (
        <ListItem disableGutters divider>
          <ListItemText
            primary="Address"
            secondary={
              depot ? (
                depot?.properties?.address ?? "unknown"
              ) : (
                <Skeleton variant={"text"} height={"80px"} />
              )
            }
          />
        </ListItem>
      )}

      {isTxPPC && googlePlacesLink !== "unknown" && (
        <ListItem disableGutters>
          <Link href={googlePlacesLink} marginTop={1} target="_blank">
            Google Maps
          </Link>
        </ListItem>
      )}

      {isOnEdit && (
        <ListItem disableGutters>
          <TextField
            variant="outlined"
            type="text"
            name="feedback"
            helperText="Enter your feedback about the depot"
            label="Feedback"
            multiline
            rows={3}
            sx={{ width: "100%", marginTop: 1 }}
            onChange={(event) => handleFormChange(event)}
          />
        </ListItem>
      )}

      {isOnEdit && (
        <Grid container justifyContent={"end"} marginTop={2}>
          <Grid item>
            <LoadingButton
              variant="outlined"
              size="small"
              onClick={() => updateDepot(depotInformation)}
              loading={loading}
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      )}
    </>
  );
}

function FeederLineProperties({
  fullFeederLine,
  isNextGenCompany,
}: {
  fullFeederLine?: Feature;
  isNextGenCompany: boolean;
}) {
  return (
    <>
      {fullFeederLine ? (
        <>
          <ListItem disableGutters>
            <ListItemText
              primary="Feeder Line ID"
              secondary={
                isNextGenCompany
                  ? extractFeederNumbers(fullFeederLine?.properties?.substation)
                  : fullFeederLine?.properties?.id
              }
            />
          </ListItem>
          <Divider component="li" />
        </>
      ) : (
        <>
          <Skeleton variant={"text"} />
        </>
      )}
      {fullFeederLine ? (
        <ListItem disableGutters>
          <ListItemText
            primary="Substation"
            secondary={fullFeederLine?.properties?.substation ?? "unknown"}
          />
        </ListItem>
      ) : (
        <>
          <Skeleton variant={"text"} />
        </>
      )}
    </>
  );
}

type DemandFeatureType = "Depot" | "Feeder Line";

export function getDemandFeatureType(feature: Feature): DemandFeatureType {
  const geometryType = feature.geometry.type;
  switch (geometryType) {
    case "Point":
      return "Depot";
    case "LineString":
    case "MultiLineString":
      return "Feeder Line";
    default:
      throw new Error(`Unknown geometry type: ${geometryType}`);
  }
}

export type DemandFeaturePopupContentProps = {
  feature: Feature;
  year: number;
  state: string;
  isNextGenCompany: boolean;
  isTxPPC?: boolean;
  scenarioId: string;
  location: Location;
  setSnackMessage?: (msg: string) => void;
  setOpen?: (data: boolean) => void;
  setSnackColor?: (color: AlertColor) => void;
  setPopupInfo?: () => void;
  loadDepot?: () => void;
};

export function DemandFeaturePopupContent({
  feature,
  year,
  state,
  isTxPPC,
  isNextGenCompany,
  scenarioId,
  location,
  setSnackMessage,
  setOpen,
  setSnackColor,
  setPopupInfo,
  loadDepot,
}: DemandFeaturePopupContentProps) {
  const { user, getToken } = useAccessToken();
  const [fullFeature, setFullFeature] = useState<Feature | undefined>(
    undefined
  );
  const [demand, setDemand] = useState<DepotDemand[] | undefined>(undefined);
  const [tab, setTab] = useState<"properties" | "power" | "energy">(
    "properties"
  );
  const [deleteScenarioPopup, setDeleteScenarioPopup] = useState(false);
  const [editScenarioPopup, setEditScenarioPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const featureType = getDemandFeatureType(feature);

  useEffect(() => {
    const loadData = async () => {
      const apiToken = await getToken();
      if (featureType === "Depot") {
        try {
          const depotDemand = await loadDepotDemand(
            apiToken,
            feature.properties!.id,
            state,
            year,
            undefined,
            scenarioId,
            location.id
          );
          setDemand(depotDemand);
        } catch (error) {
          setSnackColor && setSnackColor("error");
          setSnackMessage && setSnackMessage("Failed to fetch depot demand.");
          setOpen && setOpen(true);
        }
      }

      if (featureType === "Feeder Line" && !isTxPPC) {
        const feederLine = await loadFeederLine(
          feature.properties!.id,
          apiToken,
          state
        );
        const feederLineDemand = await loadFeederLineDemand(
          apiToken,
          feature.properties!.id,
          state,
          year,
          undefined,
          scenarioId
        );
        setFullFeature(feederLine);
        setDemand(feederLineDemand);
      }
    };
    if (feature) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feature, featureType, year, state, isTxPPC]);

  const disableDepot = async () => {
    try {
      setLoading(true);
      const apiToken = await getToken();
      await disableDepotInformation(apiToken, feature.properties, state, user);
      setPopupInfo && setPopupInfo();
      setSnackColor && setSnackColor("success");
      setSnackMessage && setSnackMessage("Successfully disabled the depot.");
      setOpen && setOpen(true);
      loadDepot && loadDepot();
    } catch (error) {
      setSnackColor && setSnackColor("error");
      setSnackMessage && setSnackMessage("Failed to disable depot.");
      setOpen && setOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const updateDepot = async (
    depotInformation: DepotInformation | undefined
  ) => {
    if (depotInformation?.feedback) {
      setLoading(true);
      const apiToken = await getToken();
      try {
        await editDepotInformation(
          apiToken,
          feature.properties!.id,
          state,
          depotInformation
        );
        setSnackColor && setSnackColor("success");
        setSnackMessage &&
          setSnackMessage("Successfully sent the depot feedback.");
        setOpen && setOpen(true);
      } catch (error) {
        setSnackColor && setSnackColor("error");
        setSnackMessage &&
          setSnackMessage("Failed to send the depot feedback.");
        setOpen && setOpen(true);
      } finally {
        setLoading(false);
        setEditScenarioPopup(false);
      }
    }
  };

  return (
    <Stack spacing={1} sx={{ width: "406px", padding: "10px" }}>
      <Grid container justifyContent={"space-between"}>
        <Grid item>
          <Typography variant={"h2"}>{featureType} Information</Typography>
          {isNextGenCompany && feature.properties?.datasource && (
            <Chip
              color="primary"
              variant="filled"
              label={getDepotCategoryName(feature.properties?.datasource)}
              sx={{ borderRadius: 1, marginTop: "3px", color: "white" }}
              size="small"
            />
          )}
        </Grid>
        {isNextGenCompany && (
          <Grid item>
            {editScenarioPopup ? (
              <Button
                color="error"
                size="small"
                onClick={() => setEditScenarioPopup(false)}
              >
                Cancel
              </Button>
            ) : (
              <Grid container>
                <Grid item>
                  <Tooltip title="Edit Depot">
                    <IconButton onClick={() => setEditScenarioPopup(true)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Disable Depot">
                    <IconButton onClick={() => setDeleteScenarioPopup(true)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={(e, value) => setTab(value)}>
            <Tab value={"properties"} label={`Properties`} />
            <Tab value={"power"} label="Power" />
            <Tab value={"energy"} label="Energy" />
          </TabList>
        </Box>
        <TabPanel value={"properties"}>
          <List dense>
            {featureType === "Depot" && (
              <>
                <ListItem disableGutters>
                  <ListItemText
                    primary="ID"
                    secondary={
                      feature.properties?.id
                        ? `ET-${feature.properties.id}`
                        : "unknown"
                    }
                  />
                </ListItem>
                <Divider component="li" />
                <DepotProperties
                  depotId={feature.properties?.id}
                  year={year}
                  state={state}
                  isTxPPC={isNextGenCompany ?? false}
                  loading={loading}
                  isOnEdit={editScenarioPopup}
                  updateDepot={(data: DepotInformation | undefined) =>
                    updateDepot(data)
                  }
                />
              </>
            )}
            {featureType === "Feeder Line" && (
              <FeederLineProperties
                fullFeederLine={fullFeature}
                isNextGenCompany={isNextGenCompany}
              />
            )}
          </List>
        </TabPanel>
        <TabPanel value={"power"}>
          <Stack spacing={2}>
            <ChartContainer>
              <Typography variant={"h3"}>Power Profile</Typography>
              {demand ? (
                <Box sx={{ height: "200px", width: "100%" }}>
                  <DemandChart data={demand} variant="power" />
                </Box>
              ) : (
                <Skeleton
                  width={"100%"}
                  height={"200px"}
                  sx={{ top: 0, right: 0 }}
                />
              )}
            </ChartContainer>
          </Stack>
        </TabPanel>
        <TabPanel value={"energy"}>
          <ChartContainer>
            <Typography variant="h3">Energy Profile</Typography>
            {demand ? (
              <Box sx={{ height: "200px", width: "100%" }}>
                <DemandChart data={demand} variant="energy" />
              </Box>
            ) : (
              <Skeleton
                width={"100%"}
                height={"200px"}
                sx={{ top: 0, right: 0 }}
              />
            )}
          </ChartContainer>
        </TabPanel>
      </TabContext>

      {/* Delete Growth Scenario Popup */}
      <Dialog
        open={deleteScenarioPopup}
        onClose={() => setDeleteScenarioPopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <IconButton
          aria-label="close"
          onClick={() => setDeleteScenarioPopup(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
        <DialogTitle id="alert-dialog-title">
          {`Do you want to disable the depot (${
            (feature.properties?.business_name || feature.properties?.id) ?? ""
          })?`}
        </DialogTitle>
        <DialogActions>
          <LoadingButton
            color="error"
            onClick={disableDepot}
            loading={loading}
            variant="text"
          >
            Delete
          </LoadingButton>
          <Button
            color="inherit"
            sx={{ color: "black" }}
            onClick={() => setDeleteScenarioPopup(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
