export const convertToTimeString = (num: number): string => {
  // Determine the hour
  const hour = num % 12 === 0 ? 12 : num % 12;

  // Determine the period (am or pm)
  const period = num < 12 ? "am" : "pm";

  // Return the formatted time string
  return `${hour}${period}`;
};

export const generateTimeLabels = () => {
  return Array.from({ length: 48 }, (_, i) => {
    const hour = Math.floor(i / 2);
    const minute = (i % 2) * 30;
    const period = hour < 12 ? "AM" : "PM";
    const hour12 = hour % 12 === 0 ? 12 : hour % 12;
    const minuteDisplay = minute === 0 ? "" : `:${minute.toString()}`;
    return `${hour12}${minuteDisplay} ${period}`;
  });
};

export const generate24HourLabels = () => {
  return Array.from({ length: 24 }, (_, i) => {
    const period = i < 12 ? "AM" : "PM";
    const hour12 = i % 12 === 0 ? 12 : i % 12;
    return `${hour12}${period}`;
  });
};

export const convertToDateTimeFormat = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };

  // Convert date to locale string with the given options
  const localeString = date.toLocaleString("en-US", options);

  // Replace commas and colons with hyphens
  const formattedString = localeString.replace(/[,/:]/g, "-");

  return formattedString;
};
