export async function deleteFleetScenario(
  scenarioId: Number,
  apiToken: string
) {
  const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/fleets/${scenarioId}`;

  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${apiToken}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to delete fleet scenario.");
  }
  return response.json();
}
