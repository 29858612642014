import {
  Autocomplete,
  Stack,
  Grid,
  Typography,
  Box,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tooltip,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import { AddBox, Delete } from "@mui/icons-material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import FleetOverview from "./components/FleetOverview";
import TotalCostOfOwnership from "./components/TotalCostOfOwnership";
import { useEffect, useMemo, useRef, useState } from "react";
import { AdvancedDialog, BasicDialog } from "./components/wizard/Dialog";
import { Section } from "../../components/Section";
import { PowerProfileChart } from "./components/charts/PowerProfileChart";

import {
  fullElectric,
  dieselOnly,
  users,
  hybridScenario,
  hybridScenarioModified,
  fullElectricEvOnly,
  fullElectricEvseOnly,
} from "./demo-data";
import { FleetElectrificationScenario } from "types/fleet-electrification-scenario";
import AssumptionsPropertyTable from "./components/AssumptionsPropertyTable";
import { useFleetScenarioData } from "api/fleets/get-fleet-scenario";
import { riskAssessment } from "./fleet-mix";
import DeleteScenarioDialog, {
  DeleteDialogContent,
} from "./components/wizard/DeleteScenarioDialog";
import RiskAssessmentDialog from "./components/wizard/RiskAssessmentDialog";
import generatePDF from "react-to-pdf";
import FleetDownloadPage from "./FleetDownloadPage";
import { OrganizationPreference } from "types/organization";
import { useDefaultFleetScenarioData } from "api/fleets/get-default-fleet-vehicles";
import ConfigurationParameters from "./components/ConfigurationParameters";
import { useFleetChargerData } from "api/fleets/get-fleet-chargers";

interface FleetsPageProps {
  organizationPreference: OrganizationPreference | undefined;
}

function FleetsPage({ organizationPreference }: FleetsPageProps) {
  const targetRef = useRef();
  const [showBasicDialog, setShowBasicDialog] = useState(false);
  const [dialogRenderKey, setDialogRenderKey] = useState(0); // used to force rerender of dialog
  const openBasicDialog = () => setShowBasicDialog(true);
  const closeBasicDialog = () => setShowBasicDialog(false);
  const [showAdvancedDialog, setShowAdvancedDialog] = useState(false);
  const closeAdvancedDialog = () => setShowAdvancedDialog(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [numTimesGoneThroughDialog, setNumTimesGoneThroughDialog] =
    useState<number>(0);
  const [adminMode, setAdminMode] = useState(false);
  const [selectedUserName, setSelectedUser] = useState("");

  const [selectedScenarioId, setSelectedScenarioId] = useState<
    number | undefined
  >(undefined);

  const [referenceScenarioId, setReferenceScenarioId] = useState<
    number | undefined
  >(undefined);

  const { fleetScenarioData, refetch } = useFleetScenarioData();
  const { fleetVehiclesData } = useDefaultFleetScenarioData();
  const { fleetChargersData } = useFleetChargerData();

  useEffect(() => {
    if (!selectedScenarioId && fleetScenarioData.length > 0) {
      setSelectedScenarioId(fleetScenarioData[0].id);
    }
  }, [fleetScenarioData, selectedScenarioId]);

  const [deleteScenarioContent, setDeleteScenarioContent] =
    useState<DeleteDialogContent>({
      isOpen: false,
      scenarioId: undefined,
      scenarioName: undefined,
    });

  const [riskAssessmentOpen, setRiskAssessmentOpen] = useState<boolean>(false);

  let scenarios: FleetElectrificationScenario[] = [];
  if (selectedUserName !== "" || adminMode) {
    const selectedUser = users.find((u) => u.name === selectedUserName);
    scenarios = selectedUser?.scenarios || [];
  } else {
    const demoScenarios = [
      dieselOnly,
      fullElectric,
      hybridScenario,
      hybridScenarioModified,
      fullElectricEvOnly,
      fullElectricEvseOnly,
    ];
    const numScenariosToAdd =
      Math.min(numTimesGoneThroughDialog, demoScenarios.length) + 1;
    scenarios = demoScenarios.slice(0, numScenariosToAdd);
  }

  const selectedScenario = fleetScenarioData.find(
    (s) => s.id === selectedScenarioId
  );

  const evVehicleCount = useMemo(() => {
    return selectedScenario?.fleetVehicles.reduce((total, vehicle) => {
      return total + (vehicle.num_electric_vehicles || 0);
    }, 0);
  }, [selectedScenario]);

  const toggleAdminMode = () => {
    setAdminMode(!adminMode);
    setSelectedUser("");
  };

  const handleNewScenarioSubmit = () => {
    setShowBasicDialog(false);
    refetch();
    setSnackbarMessage("New scenario created");
    setShowSnackbar(true);
    setNumTimesGoneThroughDialog(numTimesGoneThroughDialog + 1);
  };

  const isEV = useMemo(() => {
    return selectedScenario?.name.includes("EV");
  }, [selectedScenario]);

  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "scroll",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          width: "100%",
          padding: "16px 0px 16px 16px",
          boxSizing: "border-box",
        }}
      >
        <Grid item xs={12}>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            flexWrap={"wrap"}
            sx={{ width: "100%" }}
          >
            <Typography variant="h1" fontWeight={"bold"}>
              Fleet Electrification Explorer
            </Typography>
            <Stack
              direction={"row"}
              spacing={2}
              flexWrap={"wrap"}
              alignItems="center"
            >
              {adminMode && (
                <Autocomplete
                  value={selectedUserName}
                  onChange={(e, newValue) =>
                    setSelectedUser(newValue as string)
                  }
                  options={users.map((user) => user.name)}
                  sx={{ width: 250 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select a User" />
                  )}
                />
              )}
              <FormControl sx={{ minWidth: 250 }}>
                <InputLabel id="scenario-select-label">
                  Select Scenario
                </InputLabel>
                <Select
                  labelId="scenario-select-label"
                  value={selectedScenarioId || "none"}
                  onChange={(e) => {
                    if (e.target.value === "none") {
                      setSelectedScenarioId(undefined);
                    }
                    setSelectedScenarioId(e.target.value as number);
                  }}
                  label="Select Scenario"
                  renderValue={(selected) => {
                    if (selected) {
                      if (selected === "none") {
                        return "None";
                      }
                      return fleetScenarioData.find(
                        (sce) => sce.id === selected
                      )?.name;
                    }
                    return "Select Scenario";
                  }}
                >
                  <MenuItem value={"none"}>None</MenuItem>
                  {fleetScenarioData.map((scenario) => (
                    <MenuItem key={scenario.id} value={scenario.id}>
                      <Grid container alignItems="center">
                        <Grid item xs={10}>
                          <Typography variant="body1">
                            {scenario.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} container justifyContent="flex-end">
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeleteScenarioContent({
                                isOpen: true,
                                scenarioId: scenario.id,
                                scenarioName: scenario.name,
                              });
                            }}
                          >
                            <Delete sx={{ fontSize: "18px" }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box sx={{ width: 40, height: 40 }}>
                <Tooltip title="Add a new scenario">
                  <IconButton
                    onClick={() => {
                      setDialogRenderKey(dialogRenderKey + 1);
                      openBasicDialog();
                    }}
                  >
                    <AddBox fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
            <Box sx={{ width: 40, height: 40, marginLeft: "auto" }}>
              <Tooltip title="Enable admin mode">
                <IconButton
                  onClick={toggleAdminMode}
                  sx={{ color: adminMode ? "primary.main" : undefined }}
                >
                  <AdminPanelSettingsIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Box>
            {selectedScenario && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  generatePDF(targetRef, {
                    filename: `Fleet-${selectedScenario.name}.pdf`,
                  })
                }
              >
                Download Scenario evaluation
              </Button>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} lg={8}>
          {organizationPreference &&
            organizationPreference.fleetRiskEnabled &&
            selectedScenario &&
            selectedScenario.risk_factor && (
              <Section>
                <Stack spacing={1.5}>
                  <Stack spacing={2} direction="row">
                    <Typography variant="h6">
                      Risk assessment:{" "}
                      {evVehicleCount === 0 || !isEV
                        ? "N/A"
                        : selectedScenario.risk_factor}
                    </Typography>
                    <Typography variant="h6">
                      {`Electrification potential score: ${
                        evVehicleCount === 0 || !isEV
                          ? "N/A"
                          : `${selectedScenario.riskScore}/40`
                      }`}
                    </Typography>
                    <Button
                      variant="text"
                      onClick={() => setRiskAssessmentOpen(true)}
                    >
                      Click Here to see how this is calculated
                    </Button>
                  </Stack>

                  <Typography>
                    {evVehicleCount === 0 || !isEV
                      ? riskAssessment.NO_EV.assessment
                      : riskAssessment[selectedScenario.risk_factor]
                          ?.assessment}
                  </Typography>

                  <Typography variant="h6" display="inline">
                    We recommend:{" "}
                    <Typography variant="body1" display="inline">
                      {evVehicleCount === 0 || !isEV
                        ? riskAssessment.NO_EV.recommend
                        : riskAssessment[selectedScenario.risk_factor]
                            ?.recommend}
                    </Typography>
                  </Typography>
                </Stack>
              </Section>
            )}
          <Section>
            <FleetOverview
              scenario={selectedScenario}
              scope={selectedScenario?.scope}
              vehiclePurchaseSuggestions={selectedScenario?.fleetVehicles}
              chargerPurchaseSuggestions={selectedScenario?.fleetChargerLevels}
            />
          </Section>
          <Section>
            <ConfigurationParameters scenario={selectedScenario} />
          </Section>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Stack spacing={4}>
            <Section>
              <TotalCostOfOwnership
                scenario={selectedScenario}
                referenceScenarios={fleetScenarioData}
                referenceScenarioId={referenceScenarioId}
              />
            </Section>
            <Section>
              <PowerProfileChart scenario={selectedScenario} />
            </Section>
            <Section>
              <AssumptionsPropertyTable scenario={selectedScenario} />
            </Section>
          </Stack>
        </Grid>
      </Grid>
      <BasicDialog
        setSelectedScenarioId={setSelectedScenarioId}
        setReferenceScenarioId={setReferenceScenarioId}
        fleetScenarios={fleetScenarioData}
        DialogProps={{ open: showBasicDialog, onClose: closeBasicDialog }}
        onSubmit={handleNewScenarioSubmit}
        key={dialogRenderKey}
        fleetDefaultVehicles={fleetVehiclesData}
        fleetChargers={fleetChargersData}
      />
      <AdvancedDialog
        DialogProps={{ open: showAdvancedDialog, onClose: closeAdvancedDialog }}
      />
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert severity="success">{snackbarMessage}</Alert>
      </Snackbar>
      {deleteScenarioContent.isOpen && (
        <DeleteScenarioDialog
          content={deleteScenarioContent}
          refetchData={refetch}
          handleClose={() => {
            setDeleteScenarioContent({
              isOpen: false,
              scenarioId: undefined,
              scenarioName: undefined,
            });
          }}
        />
      )}
      {riskAssessmentOpen && (
        <RiskAssessmentDialog
          isOpen={riskAssessmentOpen}
          handleClose={() => setRiskAssessmentOpen(false)}
        />
      )}
      {selectedScenario && (
        <div id="fleetPdf" style={{ position: "fixed", left: "-1000rem" }}>
          <Box ref={targetRef}>
            <FleetDownloadPage
              evVehicleCount={evVehicleCount || 0}
              organizationPreference={organizationPreference}
              selectedScenario={selectedScenario}
            />
          </Box>
        </div>
      )}
    </Box>
  );
}

export default FleetsPage;
