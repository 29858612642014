import {
  AlertColor,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { deleteTerminalScenario } from "api/terminal/scenarios";
import { updateScenarioStatus } from "api/terminal/update-scenario-status";
import { useAccessToken } from "utils/get-access-token";

export interface DialogContent {
  isOpen: boolean;
  scenarioName: String | undefined;
  scenarioId: number | undefined;
  terminalId?: number | undefined;
  facilityId?: number | undefined;
  isDeleteScenario: boolean;
}

interface ScenarioDialogProps {
  content: DialogContent;
  refetchData: () => void;
  handleClose: () => void;
  setSnackbar: (snackBar: {
    type: AlertColor;
    message: string;
    duration: number;
  }) => void;
}

export default function ScenarioDialog({
  content,
  handleClose,
  refetchData,
  setSnackbar,
}: ScenarioDialogProps) {
  const { getToken } = useAccessToken();
  const handleConfirm = async (scenarioId: Number) => {
    const apiToken = await getToken();
    if (!content.isDeleteScenario) {
      try {
        await updateScenarioStatus(scenarioId, apiToken);
        refetchData();
        setSnackbar({
          type: "success",
          message: `Terminal scenario canceled successfully!`,
          duration: 2000,
        });
        handleClose();
      } catch (error: any) {
        setSnackbar({
          type: "error",
          message: error.message,
          duration: 6000,
        });
      }
    } else {
      if (content.terminalId && content.facilityId && content.scenarioId) {
        try {
          await deleteTerminalScenario(
            content.terminalId,
            content.facilityId,
            content.scenarioId,
            apiToken
          );
          refetchData();
          setSnackbar({
            type: "success",
            message: `Terminal scenario deleted successfully!`,
            duration: 2000,
          });
          handleClose();
        } catch (error: any) {
          setSnackbar({
            type: "error",
            message: error.message,
            duration: 6000,
          });
        }
      }
    }
  };

  return (
    <>
      {/* Terminal Scenario Popup */}
      <Dialog
        open={content.isOpen}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
        <DialogTitle id="alert-dialog-title">
          {`Are you sure you want to ${
            content.isDeleteScenario ? "delete" : "cancel"
          } scenario (${content.scenarioName ?? ""})?`}
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              if (content.scenarioId) handleConfirm(content.scenarioId);
            }}
          >
            Confirm
          </Button>
          <Button
            color="inherit"
            sx={{ color: "black" }}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
